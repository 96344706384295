import { css } from "@linaria/core";
import { ReactNode } from "react";

import { ErrorBoundary } from "components/ErrorBoundary";


interface IWizardContentLayoutProps {
	children: ReactNode,
}

export function WizardContentLayout({ children }: IWizardContentLayoutProps) {
	const containerStyle = css`
		height: 100%;
		display: flex;
		flex-direction: column;
		overflow: visible;
	`;

	// const headerTextStyle = css`
	// 	font-size: 20px;
	// `;

	// const descriptionTextStyle = css`
	// 	display: flex;
	// 	align-items: center;
	// 	padding: 1rem 0rem 0rem 0rem;
	// 	line-height: 1.6em;
	// `;

	return (
		<div className={containerStyle}>
			{/* <h2 className={headerTextStyle}>{headerText}</h2> */}

			<ErrorBoundary>
				{children}
			</ErrorBoundary>
		</div>
	);
}
