import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "@linaria/core";
import classNames from "classnames";
import { motion } from "framer-motion";
import { ReactNode, createContext, useContext, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "components/Button";
import { theme } from "theme";
import { invariant } from "utils/invariant";

import { entryAnimations } from "../entryAnimations";


export interface IStep {
	label: string,
	icon?: IconProp,
	href: string,
	isBlocked?: boolean,
}

interface IWizardContextState {
	navigateNextStep?: () => void,
	navigatePrevStep?: () => void,
	activeStepIndex: number,
	steps: IStep[],
}

const WizardContext = createContext<IWizardContextState | undefined>(undefined);

export function useWizard() {
	const context = useContext(WizardContext);
	invariant(context, "useWizard should only be used within the context of an WizardProvider");
	return context;
}

interface IWizardProps {
	steps: IStep[],
	children: ReactNode,
}


export function WizardProvider({ steps, children }: IWizardProps) {
	const location = useLocation();
	const pathname = location.pathname;
	const navigate = useNavigate();

	const activeStepIndex = useMemo(() => steps.findIndex(x => pathname.startsWith(x.href)), [pathname, steps]);

	if (activeStepIndex < 0)
		throw new Error("This is not a valid step!");

	useEffect(() => {
		if (activeStepIndex > 0 && steps[activeStepIndex]?.isBlocked) {
			const previousStepHref = steps[activeStepIndex - 1]?.href;
			if (previousStepHref)
				navigate(previousStepHref);
		}
	}, [activeStepIndex, navigate, steps]);

	const navigateNextStep = () => {
		const nextStep = steps[activeStepIndex + 1];

		invariant(nextStep, "nextStep must be defined");
		navigate(nextStep.href);
	};

	const navigatePrevStep = () => {
		// High redundancy, but better readability
		const prevStep = steps[Math.max(0, activeStepIndex - 1)];

		invariant(prevStep, "prevStep must be defined");
		navigate(prevStep.href);
	};

	return (
		<WizardContext.Provider
			value={{
				navigateNextStep: (activeStepIndex !== steps.length - 1) && !steps?.[activeStepIndex + 1]?.isBlocked ? navigateNextStep : undefined,
				navigatePrevStep: (activeStepIndex !== 0) ? navigatePrevStep : undefined,
				steps,
				activeStepIndex,
			}}
		>
			{children}
		</WizardContext.Provider>
	);
}


export function WizardComponent() {
	const { activeStepIndex, steps } = useWizard();

	const activeStepNumber = activeStepIndex + 1;

	const totalSteps = steps.length;

	const width = `${(100 / (totalSteps - 1)) * (activeStepNumber - 1)}%`;

	const mainContainerStyle = css`
		padding: 0px 24px;
		width: 100%;
  `;

	const iconStyle = css`
		font-size: 20px;
  	`;

	const iconCompletedStepStyle = css`
		color: ${theme.semantic.timelineIconVisited};
	`;

	const iconActiveStepStyle = css`
		color: ${theme.semantic.timelineIconActive};
	`;

	const checkmarkStyle = css`
		color: ${theme.semantic.timelineIconVisited};
  `;

	const stepContainerStyle = css`
		display: flex;
		justify-content: space-between;
  `;

	const stepNodesContainerStyle = css`
		position: relative; 
  `;

	const stepContainerBeforeStyle = css`
		content: '';
		position: absolute;
		background: ${theme.semantic.timelineBorderInactive};
		height: 3px;
		width: 100%;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
	`;

	const stepContainerAfterStyle = css`
		content: '';
		position: absolute;
		background: ${theme.semantic.timelineBorderVisited};
		height: 3px;
		top: 50%;
		transition: 0.4s ease;
		transform: translateY(-50%);
		left: 0;
	`;

	const stepWrapperStyle = css`
		position: relative;
		z-index: 1;
		border-radius: 100%;
	`;

	const stepNodeStyle = css`
		width: 48px;
		height: 48px;
		border-radius: 100%;
		background-color: ${theme.semantic.timelineInactive};
		border: 2px solid ${theme.semantic.timelineBorderInactive};
		color: ${theme.semantic.timelineIconInactive};
		transition: 0.4s ease;
		display: flex;
		justify-content: center;
		align-items: center;
	`;

	const stepNodeCurrentStyle = css`
		background-color: ${theme.semantic.timelineActive};
		border: 2px solid ${theme.semantic.timelineBorderActive};
		color: ${theme.semantic.timelineIconActive};
	`;

	const stepNodeCompleteStyle = css`
		border: 2px solid ${theme.semantic.timelineBorderVisited};
		background-color: ${theme.semantic.timelineVisited};
		color: ${theme.semantic.timelineIconVisited};
	`;

	const stepCountStyle = css`
		font-size: 19px;
		color: ${theme.semantic.timelineIconInactive};
		@media (max-width: 600px) {
			font-size: 16px;
		}
		user-select: none;
	`;

	const stepLabelContainerStyle = css`
		font-size: 12px;
		color: ${theme.semantic.timelineForegroundInactive};
		@media (max-width: 600px) {
			font-size: 16px;
		}
		text-align: center;
		margin-top: 10px;
		user-select: none;
		transition: color 0.4s ease;

		width: 48px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	`;

	const stepLabelCompletedStyle = css`
		color: ${theme.semantic.timelineForegroundVisited};
	`;

	const stepLabelActiveStyle = css`
		color: ${theme.semantic.timelineForegroundActive};
	`;

	return (
		<div className={mainContainerStyle}>
			<div className={classNames(stepContainerStyle, stepNodesContainerStyle)}>
				<motion.div className={stepContainerBeforeStyle} {...entryAnimations.fadeIn} transition={{ delay: 0.2 * steps.length }} />
				{steps.map((step, index) => {
					const stepNumber = index + 1;
					const isCompleted = activeStepNumber > stepNumber || activeStepNumber === steps.length;
					return (
						<motion.div key={stepNumber} className={stepWrapperStyle} {...entryAnimations.slideUp} transition={{ delay: 0.2 * index }}>
							<Button href={step.href} variant="normalized" isDisabled={index > activeStepIndex}>
								<div className={classNames(stepNodeStyle, { [stepNodeCompleteStyle]: isCompleted, [stepNodeCurrentStyle]: activeStepNumber === stepNumber })}>
									{isCompleted ? (
										<FontAwesomeIcon
											icon={faCheck}
											className={classNames(iconStyle, checkmarkStyle)}
										/>
									) : (
										<span
											className={stepCountStyle}
										>
											{step.icon ? (
												<FontAwesomeIcon
													icon={step.icon}
													className={classNames(
														iconStyle,
														{
															[iconCompletedStepStyle]: isCompleted,
															[iconActiveStepStyle]: activeStepNumber === stepNumber,
														}
													)}
												/>
											) : stepNumber}
										</span>
									)}
								</div>
							</Button>
						</motion.div>
					);
				})}
				<motion.div className={stepContainerAfterStyle} style={{ width }} {...entryAnimations.fadeIn} transition={{ delay: 0.2 * steps.length }} />
			</div>
			<div className={stepContainerStyle}>
				{steps.map(({ label, href }, index) => {
					const stepNumber = index + 1;
					const isCompleted = activeStepNumber > stepNumber || activeStepNumber === steps.length;
					return (
						<motion.div
							key={stepNumber}
							className={classNames(stepLabelContainerStyle, { [stepLabelCompletedStyle]: isCompleted, [stepLabelActiveStyle]: activeStepNumber === stepNumber && !isCompleted })}
							{...entryAnimations.slideUp}
							transition={{ delay: 0.2 * index }}
						>
							<Button href={href} variant="normalized" isDisabled={index > activeStepIndex}>
								{label.split(" ").map((x, i) => <div key={i}>{x}</div>)}
							</Button>
						</motion.div>
					);
				})}
			</div>
		</div>
	);
}
