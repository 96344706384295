import { css } from "@linaria/core";
import { ReactNode } from "react";
import { useFormContext } from "react-hook-form";

import { Button } from "components/Button";
import { Separator } from "components/Separator";

import { Loader } from "./Loader";


export function SubmitButton({
	children,
	isDisabled = false,
	large,
}: {
	children: ReactNode,
	isDisabled?: boolean,
	large?: boolean,
}) {
	const form = useFormContext();

	// formState is wrapped with a Proxy to improve render performance
	// and skip extra logic if specific state is not subscribed to.
	// Therefore make sure you invoke or read it before a render in order to enable the state update.
	// eslint-disable-next-line @typescript-eslint/no-unused-expressions
	form.formState.isValid;

	return (
		<Button
			variant={large ? "large" : "primary"}
			type="submit"
			isDisabled={isDisabled || form.formState.isSubmitting}
		>
			{children}
		</Button>
	);
}


export function Submit({
	children,
	isDisabled,
	large,
}: {
	children: ReactNode,
	isDisabled?: boolean,
	large?: boolean,
}) {
	const { formState: { isSubmitting } } = useFormContext();

	const actionWrapper = css`
        display: flex;
        align-items: center;
    `;

	const actionWrapperLarge = css`
		display: flex;
		width: 100%;
		align-items: stretch;
	`;

	const statusWrapper = css`
        margin-left: 10px;
    `;

	const statusWrapperLarge = css`
		width: 100%;
		display: flex;
		justify-content: center;
	`;

	const wrapperStyle = css`
		width: 100%;
	`;

	return (
		<div className={wrapperStyle}>
			<Separator />
			<span className={large ? actionWrapperLarge : actionWrapper}>
				{!isSubmitting ? (
					<SubmitButton
						isDisabled={isDisabled}
						large={large}
					>
						{children}
					</SubmitButton>
				)
					: (
						<div className={large ? statusWrapperLarge : statusWrapper}>
							<Loader size={28} />
						</div>
					)}
			</span>
		</div>
	);
}
