import { useController, useFormContext } from "react-hook-form";

import { DatePicker as DatePickerComponent, IDatePickerProps } from "components/DatePicker";

import { IFormFieldProps } from "./types";


export function DatePicker(props: IFormFieldProps<IDatePickerProps>) {
	const {
		name,
		isDisabled = false,
		...rest
	} = props;

	const { control, formState: { isSubmitting } } = useFormContext();

	const {
		field: {
			// Legacy
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			onChange, name: fieldName, value,
		},
		fieldState: { error },
	} = useController({
		name, control,
	});

	return (
		<DatePickerComponent
			{...rest}
			name={fieldName}
			// Legacy
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			value={value ?? ""}
			isDisabled={isDisabled || isSubmitting}
			errorMessage={error?.message}
			onChange={onChange}
			validationState={error ? "invalid" : "valid"}
		/>
	);
}
