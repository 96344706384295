import { css } from "@linaria/core";
import { ForwardedRef, MouseEventHandler, ReactNode, forwardRef, useCallback, useImperativeHandle, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { theme } from "theme";


interface IModalProps {
	children: ReactNode | ((isOpen: boolean, close: () => void) => ReactNode),
	closeOnBackdropClick?: boolean,
	onClose?: () => void,
}


export function PageModal({ children }: { children: ReactNode }) {
	const navigate = useNavigate();
	return (
		<Modal onClose={() => navigate("..")}>
			{children}
		</Modal>
	);
}


export const Modal = forwardRef(function Modal({ children, closeOnBackdropClick = true, onClose }: IModalProps, ref: ForwardedRef<HTMLDialogElement>) {
	const internalRef = useRef<HTMLDialogElement | null>(null);

	useImperativeHandle<HTMLDialogElement | null, HTMLDialogElement | null>(ref, () => internalRef.current);

	const close = useCallback(() => {
		if (internalRef.current?.open)
			internalRef.current?.close();
	}, []);

	const onClick: MouseEventHandler<HTMLDialogElement> = event => {
		if (closeOnBackdropClick && event.target === internalRef.current)
			internalRef.current?.close();
	};

	const dialogStyle = css`
		outline: none;
		padding: 0;
		border: none;
		background-color: transparent;
		overflow: hidden;
		margin: 0;
		position: relative;

		&::backdrop {
			background-color: transparent;
		}

		&[open]::backdrop {
			animation: show-backdrop 0.2s ${theme.timingFunctions.default};
			background-color: ${theme.semantic.underlay};
		}

		@keyframes show-backdrop {
			from {
				opacity: 0;
			}
			to {
				opacity: 1;
			}
		}

		
		&[open] {
			animation: show-foreground 0.2s ${theme.timingFunctions.default};
		}

		@keyframes show-foreground {
			from {
				transform: translateY(10px);
			}

			to {
				transform: translateY(0);
			}
		}
	`;


	return (
		<dialog ref={internalRef} onClick={onClick} onClose={onClose} className={dialogStyle}>
			{typeof children === "function" ? children(!!internalRef.current?.open, close) : children}
		</dialog>
	);
});
