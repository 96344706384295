import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { css } from "@linaria/core";
import { useQuery } from "react-query";

import { Button } from "components/Button";
import { Image } from "components/Image";
import { WeonLoadingOverlay } from "components/LoadingOverlay";
import { Separator } from "components/Separator";
import { Order, User } from "httpClient";
import { H1 } from "pages/app/(components)/H1";
import { MainContainer } from "pages/app/(components)/MainContainer";
import { OrdersList } from "pages/app/(components)/OrdersList";
import { theme } from "theme";
import { generateGetOrganizationOrdersQueryOptions } from "utils/react-query/data-fetching/order";
import { generateGetOrganizationUsersQueryOptions } from "utils/react-query/data-fetching/user";
import { ensureIsNotError } from "utils/react-query/ensureIsNotError";
import { isNotLoadingOrIdle } from "utils/react-query/isNotLoadingOrIdle";

import { useCreatePhotoshoot } from "./(components)/useCreatePhotoshoot";
import undrawDreamerSvgSrc from "../../../../../public/undraw_dreamer_re_9tua.svg";


export default function CreatePhotoshootPage() {
	const ordersQuery = useQuery({
		...generateGetOrganizationOrdersQueryOptions(),
	});

	const usersQuery = useQuery(generateGetOrganizationUsersQueryOptions());

	ensureIsNotError(ordersQuery);
	ensureIsNotError(usersQuery);

	if (!isNotLoadingOrIdle(ordersQuery) || !isNotLoadingOrIdle(usersQuery))
		return <WeonLoadingOverlay />;

	return (
		<CreatePhotoshootPageContent orders={ordersQuery.data} users={usersQuery.data} />
	);
}


interface ICreatePhotoshootPageContent {
	orders: Order[],
	users: User[],
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function CreatePhotoshootPageContent({ orders, users }: ICreatePhotoshootPageContent) {
	const { createPhotoshoot, isLoading: createPhotoshootIsLoading, isSuccess: createPhotoshootIsSuccess } = useCreatePhotoshoot();

	const illustrationContainerStyle = css`
		position: relative;
		width: 320px;
		height: 320px;
		opacity: 0.5;
		display: flex;
		flex-direction: column;
		gap: 1rem;
	`;

	const noDraftsStyle = css`
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		gap: 1rem;
	`;

	// const buttonRowStyle = css`
	// 	display: flex;
	// 	gap: 20px;
	// 	margin-top: 0.8rem;
	// `;

	// const buttonWrapperStyle = css`
	// 	color: ${theme.palette.teriary};
	// 	border-radius: 4px;
	// 	font-weight: 600;
	// 	cursor: pointer;

	// 	&:hover {
	// 		color: ${theme.palette.teriary};
	// 	}
	// `;

	const helpTextStyle = css`
		font-weight: 500;
		color: ${theme.semantic.foregroundMuted};
		font-size: 1.2rem;
		user-select: none;
		margin-bottom: 2rem;
	`;

	const headerStyle = css`
		display: flex;
		justify-content: end;
		padding: 1rem 0;
	`;

	const orderListContainerStyle = css`
		padding-top: 40px;
		display: flex;
		flex-direction: column;
	`;

	return (
		<MainContainer>
			<H1>
				Create photoshoot
			</H1>
			<Separator space={20} />
			<div className={headerStyle}>

				<Button
					variant="primary"
					leftIconProps={{ icon: faPlus, marginRight: 16 }}
					onPress={() => createPhotoshoot()}
					size="lg"
				>
					New Photoshoot
				</Button>
			</div>

			{(createPhotoshootIsSuccess || createPhotoshootIsLoading) ? <WeonLoadingOverlay /> : (
				<>
					{/* <Breadcrumbs /> */}
					{/* <Hero
						imgSrc={heroModelPngSrc}
						title="Photoshoot Creation Lobby"
						description="This is the photoshoot creation lobby. From here you can see your ongoing drafts, or create a completely new photoshoots for your new fresh garment collection."
						functionalElement={(
							<div className={buttonRowStyle}>
								{orders.filter(x => x.orderStatus === "DRAFT").length > 0 && (
									<Button variant="normalized" onPress={() => createPhotoshoot()}>
										<div className={buttonWrapperStyle}>Create a new Photoshoot</div>
									</Button>
								)}

								<Button variant="normalized" href="/app">
									<div className={buttonWrapperStyle}>Help</div>
								</Button>
							</div>
						)}
					/> */}


					{/* <h2>Drafts</h2>
					<Separator space={20} /> */}
					<div className={orderListContainerStyle}>
						{orders.filter(x => x.orderStatus === "DRAFT").length
							? <OrdersList orders={orders.filter(x => x.orderStatus === "DRAFT")} />
							: (
								<div className={noDraftsStyle}>
									<div className={illustrationContainerStyle}>
										<Image src={undrawDreamerSvgSrc} fill alt="No drafts illustration" />
									</div>
									<div className={helpTextStyle}>No drafts in progress. Get started by creating a new photoshoot.</div>
									<Button
										variant="primary"
										leftIconProps={{ icon: faPlus, marginRight: 16 }}
										onPress={() => createPhotoshoot()}
										size="lg"
									>
										New Photoshoot
									</Button>
								</div>
							)}
					</div>
				</>
			)}
		</MainContainer>
	);
}
