import { Navigate, Outlet } from "react-router-dom";

import { PagePanel } from "components/Panel";
import { ActiveCutomerGuard, AuthGuard, PermissionsGuard } from "components/ProtectedRoute";
import { IRoute } from "pages/Routes";

import AppLayout from "./(components)/AppLayout";
import { HomePage } from "./HomePage";
import EditUserPage from "./edit-user/EditUserPage";
import GarmentsPage from "./garments/GarmentsPage";
import ManageOrganizationPage from "./manage-organization/ManageOrganizationPage";
import InviteUserPage from "./manage-organization/invite-user/InviteUserPage";
import ModelsPage from "./models/ModelsPage";
import BrowsePhotoshootsPage from "./photoshoots/browse/BrowsePhotoshootsPage";
import PhotoshootPage from "./photoshoots/browse/[orderId]/PhotoshootPage";
import PhotoshootDetailsPage from "./photoshoots/browse/[orderId]/details/PhotoshootDetailsPage";
import CreatePhotoshootPage from "./photoshoots/create/CreatePhotoshootPage";
import CreatePhotoshootWizardPage from "./photoshoots/create/[orderId]/CreatePhotoshootWizardPage";
import DesignOutfitsPage from "./photoshoots/create/[orderId]/design-outfits/DesignOutfitsPage";
import DonePage from "./photoshoots/create/[orderId]/done/DonePage";
import FillMetadataPage from "./photoshoots/create/[orderId]/fill-metadata/FillMetadataPage";
import SummaryPage from "./photoshoots/create/[orderId]/summary/SummaryPage";
import UploadGarmentsPage from "./photoshoots/create/[orderId]/upload-garments/UploadGarmentsPage";


export function useAppRoutes(): IRoute {
	return ({
		path: "/app",
		element: <AuthGuard>
			<AppLayout>
				<ActiveCutomerGuard>
					<Outlet />
				</ActiveCutomerGuard>
			</AppLayout>
		</AuthGuard>,
		children: [
			{
				path: "",
				name: "Home",
				element: <HomePage />,
			},
			{
				path: "garments",
				name: "Garments",
				element: <GarmentsPage />,
			},
			{
				path: "models",
				name: "Models",
				element: <ModelsPage />,
			},
			{
				path: "manage-organization",
				name: "Manage organization",
				element: <PermissionsGuard requiredPermissions={["organization_invites:read", "organization_users:read"]}>
					<ManageOrganizationPage />
					<Outlet />
				</PermissionsGuard>,
				children: [
					{
						path: "invite-user",
						name: "Invite user",
						element: <PagePanel headerText="Invite User" size="sm">
							<InviteUserPage />
						</PagePanel>,
					},
				],
			},
			{
				path: "edit-user",
				name: "Edit user",
				element: <EditUserPage />,
			},
			{
				path: "photoshoots",
				name: "Photoshoots",
				element: <Outlet />,
				children: [
					{
						path: "",
						element: <Navigate to="browse" />,
					},
					{
						path: "browse",
						element: <Outlet />,
						children: [
							{
								path: "",
								name: "Browse Photoshoots",
								element: <BrowsePhotoshootsPage />,
							},
							{
								path: ":orderId",
								name: "Photoshoot",
								element: <PhotoshootPage />,
								children: [
									// {
									// 	path: "review",
									// 	name: "Photoshoot review",
									// 	element: <PhotoshootReviewPage />,
									// 	children: [
									// 		{
									// 			path: ":orderRowId",
									// 			name: "Photoshoot row review",
									// 			element: <PhotoshootRowReviewPage />,
									// 		},
									// 	],
									// },
									{
										path: "details",
										name: "Photoshoot details",
										element: <PhotoshootDetailsPage />,
										// children: [
										// 	{
										// 		path: ":orderRowId",
										// 		name: "Photoshoot row review",
										// 		element: <PagePanel headerText="Review" size="lg">
										// 			<PhotoshootRowReviewPage />
										// 		</PagePanel>,
										// 	},
										// ],
									},
								],
							},
						],
					},
					{
						path: "create",
						element: <Outlet />,
						children: [
							{
								path: "",
								name: "Create Photoshoots",
								element: <CreatePhotoshootPage />,
							},
							{
								path: ":orderId",
								name: "Photoshoot creation wizard",
								element: <CreatePhotoshootWizardPage />,
								children: [
									{
										path: "upload-garments",
										name: "Upload garments",
										element: <UploadGarmentsPage />,
									},
									{
										path: "fill-metadata",
										name: "Fill metadata",
										element: <FillMetadataPage />,
									},
									{
										path: "design-outfits",
										name: "Design outfits",
										element: <DesignOutfitsPage />,
									},
									{
										path: "summary",
										name: "Summary",
										element: <SummaryPage />,
									},
									{
										path: "done",
										name: "Done",
										element: <DonePage />,
									},
								],
							},
						],
					},
				],
			},

		],
	});
}
