import { ReactNode } from "react";
import { SSRProvider } from "react-aria";


interface IReactAriaSSRProviderProps {
	children: ReactNode,
}


export function ReactAriaSSRProvider({ children }: IReactAriaSSRProviderProps) {
	return <SSRProvider>{children}</SSRProvider>;
}
