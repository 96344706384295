import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "@linaria/core";
import { useTableColumnResize } from "@react-aria/table";
import { useTableColumnResizeState, TableColumnResizeState } from "@react-stately/table";
import type { GridNode } from "@react-types/grid";
import type { CollectionChildren, SortDescriptor, Node, MultipleSelection } from "@react-types/shared";
import classNames from "classnames";
import { motion } from "framer-motion";
import { Key, ReactElement, ReactNode, useMemo, useRef, useState } from "react";
import { mergeProps, useFocusRing, useTable, useTableRowGroup, useTableColumnHeader, AriaTableProps, useTableRow, useTableCell, useTableHeaderRow, useTableSelectAllCheckbox, VisuallyHidden, useTableSelectionCheckbox, useButton, AriaButtonProps } from "react-aria";
import { Cell, Column, Row, TableBody, TableHeader, SelectionBehavior, SelectionMode, TableState, useTableState } from "react-stately";

import { theme } from "theme";
import { assertUnreachable } from "utils/assertUnreachable";
import { invariant } from "utils/invariant";
import { useMeasure } from "utils/useMeasure";

import { Checkbox } from "./Checkbox";


interface IInnerTableProps<TData> extends AriaTableProps<TData> {
	onResizeEnd?: IOnResizeFn | undefined,
	onResize?: IOnResizeFn,
	onResizeStart?: IOnResizeFn,
	selectionMode?: SelectionMode,
	selectionBehavior?: SelectionBehavior,
	children?: CollectionChildren<IColumn<TData> & IRow<TData>>,
	sortDescriptor?: SortDescriptor,
	onSortChange?: (descriptor: SortDescriptor) => void,
}

function InnerTable<TData>(props: IInnerTableProps<TData> & MultipleSelection) {
	const { selectionMode, selectionBehavior } = props;
	const [setRef, { width }] = useMeasure();

	const state = useTableState({
		...props,
		showSelectionCheckboxes: selectionMode === "multiple" && selectionBehavior !== "replace",
	});

	const scrollRef = useRef<HTMLDivElement | null>(null);


	const ref = useRef<HTMLTableElement>(null);
	const { collection } = state;
	const { gridProps } = useTable({ ...props, scrollRef }, state, ref);


	const layoutState = useTableColumnResizeState({
	// Matches the width of the table itself
		tableWidth: width,
		getDefaultMinWidth: column => column.value?.minWidth,
		getDefaultWidth: column => column.value?.defaultWidth,
	}, state);


	const tableWrapperStyle = css`
		overflow: visible;
		position: relative;
	`;

	const tableStyle = css`
		border-collapse: collapse;
		table-layout: fixed;
		width: fit-content;
	`;

	return (
		<div
			ref={el => {
				scrollRef.current = el;
				setRef(el);
			}}
			className={tableWrapperStyle}
		>
			<table {...gridProps} ref={ref} className={tableStyle}>
				<TableRowGroup type="thead">
					{collection.headerRows.map(headerRow => (
						<TableHeaderRow key={headerRow.key} item={headerRow} state={state}>
							{
								// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
								Array.from(headerRow.childNodes).map(column => (column.props.isSelectionCell
									? (
										<TableSelectAllCell
											key={column.key}
											column={column}
											state={state}
										/>
									)
									: (
										<ResizableTableColumnHeader
											key={column.key}
											column={column}
											state={state}
											layoutState={layoutState}
											onResizeStart={props.onResizeStart}
											onResize={props.onResize}
											onResizeEnd={props.onResizeEnd}
										/>
									)))
							}
						</TableHeaderRow>
					))}
				</TableRowGroup>
				<TableRowGroup type="tbody">
					{Array.from(collection.body.childNodes).map(row => (
						<TableRow key={row.key} item={row} state={state}>
							{Array.from(row.childNodes).map(cell => (
							// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
								cell.props.isSelectionCell
									? <TableCheckboxCell key={cell.key} cell={cell} state={state} />
									: <TableCell key={cell.key} cell={cell} state={state} />))}
						</TableRow>
					))}
				</TableRowGroup>
			</table>
		</div>
	);
}


interface IResizableTableColumnHeaderProps<TData> {
	state: TableState<IColumn<TData>>,
	column: Node<IColumn<TData>>,
	layoutState: TableColumnResizeState<IColumn<TData>>,
	onResizeStart?: IOnResizeFn,
	onResize?: IOnResizeFn,
	onResizeEnd?: IOnResizeFn,
}

function ResizableTableColumnHeader<TData>(
	{
		column, state, layoutState, onResizeStart, onResize, onResizeEnd,
	}: IResizableTableColumnHeaderProps<TData>
) {
	const [isHovering, setIsHovering] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
	const allowsResizing: boolean = column.props.allowsResizing;
	const ref = useRef<HTMLTableHeaderCellElement>(null);

	const { columnHeaderProps } = useTableColumnHeader(
		{ node: column },
		state,
		ref
	);

	const tableHeaderCellStyle = css`
		padding: 5px 10px;
		outline: none;
		cursor: default;
		box-sizing: border-box;
		box-shadow: none;
		text-align: left;
	`;

	const iconWrapperStyle = css`
		padding: 0 8px;
		visibility: hidden;
	`;

	const iconVisibleWrapperStyle = css`
		visibility: visible;
	`;

	const iconStyle = css`
		transition: rotate 0.2s ease-in-out;
	`;

	const iconRotatedStyle = css`
		rotate: 180deg;
	`;

	return (
		<th
			{...columnHeaderProps}
			ref={ref}
			className={tableHeaderCellStyle}
			style={{ width: layoutState.getColumnWidth(column.key) }}
			onMouseEnter={() => setIsHovering(true)}
			onMouseLeave={() => setIsHovering(false)}
		>
			<div style={{ display: "flex", position: "relative" }}>
				<TableHeaderCellButton onKeyDown={e => columnHeaderProps?.onKeyDown?.(e)}>
					{column.rendered}
					{
						// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
						column.props.allowsSorting && (
							<span
								aria-hidden="true"
								className={classNames(iconWrapperStyle, { [iconVisibleWrapperStyle]: state.sortDescriptor?.column === column.key })}
							>
								<FontAwesomeIcon className={classNames(iconStyle, { [iconRotatedStyle]: state.sortDescriptor?.direction === "descending" })} icon={faChevronUp} />
							</span>
						)
					}
				</TableHeaderCellButton>
				{allowsResizing && (
					<Resizer
						showHandlebar={isHovering}
						column={column}
						layoutState={layoutState}
						onResizeStart={onResizeStart}
						onResize={onResize}
						onResizeEnd={onResizeEnd}
					/>
				)}
			</div>
		</th>
	);
}


type IOnResizeFn = ((widths: Map<Key, string | number>) => void);

interface IResizerProps<TData> {
	showHandlebar: boolean,
	column: Node<IColumn<TData>>,
	layoutState: TableColumnResizeState<IColumn<TData>>,
	onResizeStart?: IOnResizeFn,
	onResize?: IOnResizeFn,
	onResizeEnd?: IOnResizeFn,
}

function Resizer<TData>({
	showHandlebar, column, layoutState, onResizeStart, onResize, onResizeEnd,
}: IResizerProps<TData>) {
	const ref = useRef(null);

	const { resizerProps, inputProps, isResizing } = useTableColumnResize(
		{
			column,
			"aria-label": "Resizer",
			onResizeStart,
			onResize,
			onResizeEnd,
		},
		layoutState,
		ref
	);

	const { focusProps, isFocusVisible } = useFocusRing();

	const resizerStyle = css`
		width: 12px;
		background-color: ${theme.palette.black};
		cursor: col-resize;
		touch-action: none;
		flex: 0 0 auto;
		box-sizing: border-box;
		border: 4px;
		border-style: none solid;
		border-color: transparent;
		background-clip: content-box;
		display: inline-block;
		opacity: 0;
		transition: opacity 0.2s ease-in-out;
	`;

	const resizerFocusStyle = css`
		background-color: ${theme.palette.blue};
	`;

	const resizingStyle = css`
		border-color: ${theme.palette.blue};
  		background-color: transparent;
	`;

	const resizeShowHandlebarStyle = css`
		opacity: 1;
	`;

	return (
		<div
			role="presentation"
			className={classNames(
				resizerStyle, {
					[resizerFocusStyle]: isFocusVisible,
					[resizingStyle]: isResizing,
					[resizeShowHandlebarStyle]: isFocusVisible || isResizing || showHandlebar,
				}
			)}
			{...resizerProps}
		>
			<input
				ref={ref}
				{...mergeProps(inputProps, focusProps)}
			/>
		</div>
	);
}


interface ITableRowGroupProps {
	children: ReactNode,
	type: "thead" | "tbody" | "tfoot",
}

function TableRowGroup({ children, type: Element }: ITableRowGroupProps) {
	const { rowGroupProps } = useTableRowGroup();


	const theadStyle = css`
		border-bottom: 1px solid ${theme.semantic.border};
	`;

	const Component = motion[Element];

	return (
		<Component
			// eslint-disable-next-line react/forbid-component-props
			className={classNames({ [theadStyle]: Element === "thead" })}
			{...rowGroupProps as object}
			layout
			layoutRoot
		>
			{children}
		</Component>
	);
}


interface ITableRowProps<TData> {
	children: ReactNode,
	item: GridNode<IRow<TData>>,
	state: TableState<IRow<TData>>,
}

function TableRow<TData>({ item, children, state }: ITableRowProps<TData>) {
	const ref = useRef<HTMLTableRowElement>(null);
	const isSelected = state.selectionManager.isSelected(item.key);

	const { rowProps } = useTableRow(
		{
			node: item,
		},
		state,
		ref
	);

	const { isFocusVisible, focusProps } = useFocusRing();

	const tableRowStyle = css`
		box-shadow: none;
		outline: none;

		`;

	const tableRowCanSelectStyle = css`
		&:hover {
			background: ${theme.semantic.listItemHover};
		}
	`;

	const tableRowFocusStyle = css`
		box-shadow: inset 0 0 0 2px ${theme.palette.blue};
	`;

	const tableRowSelectedStyle = css`
		background: ${theme.semantic.listItemSelected};
		color: ${theme.palette.blue};

		&:hover {
			background: ${theme.semantic.listItemSelectedHover};
		}
	`;

	return item.value?.RowProvider ? (
		<item.value.RowProvider item={item.value}>
			<motion.tr
				className={classNames(tableRowStyle, { [tableRowFocusStyle]: isFocusVisible, [tableRowSelectedStyle]: isSelected, [tableRowCanSelectStyle]: state.selectionManager.selectionMode !== "none" })}
				{...mergeProps(rowProps, focusProps) as object}
				ref={ref}
				layout
			>
				{children}
			</motion.tr>
		</item.value.RowProvider>
	) : (
		<motion.tr
			className={classNames(tableRowStyle, { [tableRowFocusStyle]: isFocusVisible, [tableRowSelectedStyle]: isSelected })}
			{...mergeProps(rowProps, focusProps) as object}
			ref={ref}
			layout
		>
			{children}
		</motion.tr>
	);
}


interface ITableCellProps<TData> {
	state: TableState<IRow<TData>>,
	cell: GridNode<IColumn<IRow<TData>>>,
}

function TableCell<T>({ cell, state }: ITableCellProps<T>) {
	const ref = useRef<HTMLTableDataCellElement>(null);
	const { gridCellProps } = useTableCell({ node: cell }, state, ref);
	const { isFocusVisible, focusProps } = useFocusRing();

	const tdStyle = css`
		overflow: hidden;
		text-overflow: ellipsis;
		padding: 12px 10px;
		outline: none;
		box-shadow: none;
		cursor: default;
	`;

	const tdNotMultilineStyle = css`
		white-space: nowrap;
	`;

	const tdFocusStyle = css`
		box-shadow: inset 0 0 0 2px ${theme.palette.blue};
	`;

	return (
		<td
			{...mergeProps(gridCellProps, focusProps)}
			ref={ref}
			className={classNames(tdStyle, { [tdFocusStyle]: isFocusVisible, [tdNotMultilineStyle]: !cell.column?.value?.isMultiline })}
		>
			{cell.rendered}
		</td>
	);
}


interface ITableHeaderRowProps<T> {
	state: TableState<T>,
	children: ReactNode,
	item: GridNode<T>,
}

function TableHeaderRow<T>({ item, state, children }: ITableHeaderRowProps<T>) {
	const ref = useRef<HTMLTableRowElement>(null);
	const { rowProps } = useTableHeaderRow({ node: item }, state, ref);

	return (
		<tr {...rowProps} ref={ref}>
			{children}
		</tr>
	);
}


interface ITableSelectAllCellProps<T> {
	state: TableState<T>,
	column: GridNode<T>,
}

function TableSelectAllCell<T>({ column, state }: ITableSelectAllCellProps<T>) {
	const ref = useRef<HTMLTableHeaderCellElement>(null);

	const { columnHeaderProps } = useTableColumnHeader(
		{ node: column },
		state,
		ref
	);

	const { checkboxProps } = useTableSelectAllCheckbox(state);

	const thStyle = css`
		padding: 0 16px 0 10px;
	`;

	return (
		<th
			className={thStyle}
			{...columnHeaderProps}
			ref={ref}
		>
			{state.selectionManager.selectionMode === "single"
				? <VisuallyHidden>{checkboxProps["aria-label"]}</VisuallyHidden>
				: (
					<Checkbox {...checkboxProps} />
				)}
		</th>
	);
}


interface ITableCheckboxCellProps<T> {
	state: TableState<T>,
	cell: GridNode<T>,
}

function TableCheckboxCell<T>({ cell, state }: ITableCheckboxCellProps<T>) {
	const ref = useRef<HTMLTableDataCellElement>(null);
	const { gridCellProps } = useTableCell({ node: cell }, state, ref);

	const { checkboxProps } = useTableSelectionCheckbox(
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		{ key: cell.parentKey! },
		state
	);

	const tdStyle = css`
		padding: 0 16px 0 10px;
	`;

	return (
		<td
			className={tdStyle}
			{...gridCellProps}
			ref={ref}
		>
			<Checkbox {...checkboxProps} />
		</td>
	);
}


function TableHeaderCellButton(props: AriaButtonProps) {
	const ref = useRef(null);
	const { focusProps, isFocusVisible } = useFocusRing();
	const { buttonProps } = useButton(props, ref);

	const tableHeaderCellButtonStyle = css`
		cursor: pointer;
		pointer-events: none;
		width: 100%;
		text-align: left;
		border: none;
		background: transparent;
		flex: 1 1 auto;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		outline: none;
		font-weight: 600;
		padding: 8px 0;
		color: ${theme.semantic.foreground};
	`;

	const tableHeaderCellButtonFocusStyle = css`
		outline: 2px solid ${theme.palette.blue};
	`;

	return (
		<button
			type="button"
			{...mergeProps(buttonProps, focusProps)}
			ref={ref}
			className={classNames(tableHeaderCellButtonStyle, { [tableHeaderCellButtonFocusStyle]: isFocusVisible })}
		>
			{props.children}
		</button>
	);
}


// eslint-disable-next-line @typescript-eslint/naming-convention
type IRow<TData> = { id: Key, RowProvider?: ({ children, item }: { children: ReactNode, item: TData }) => ReactElement } & TData;


export type IColumnStaticSize = number | `${number}` | `${number}%`;
export type IColumnDynamicSize = `${number}fr`;

export type IColumn<TData> = {
	key: string,
	name: string,
	defaultWidth?: IColumnDynamicSize | IColumnStaticSize,
	minWidth: IColumnStaticSize,
	isMultiline?: boolean,
	onRender: (item: IRow<TData>, index?: number, column?: IColumn<IRow<TData>>) => ReactNode,
	onRenderFooter?: (item: IRow<TData>, index?: number, column?: IColumn<IRow<TData>>) => ReactNode,
	comparator?: (a: IRow<TData>, b: IRow<TData>) => number,
};


type ITableProps<TData> = {
	columns: IColumn<TData>[],
	items: IRow<TData>[],
} & ({
	selectionMode?: "none",
	onSelectionChanged?: undefined,
} | {
	selectionMode: "single",
	onSelectionChanged: (value: TData | null) => void,
} | {
	selectionMode: "multiple",
	onSelectionChanged: (value: TData[]) => void,
});

export function Table<TData>({ columns, items: _items, selectionMode, onSelectionChanged }: ITableProps<TData>) {
	const columnDict: { [key: string]: IColumn<TData> } = useMemo(() => Object.fromEntries(columns.map(x => ([x.key, x]))), [columns]);

	const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor | undefined>();

	const items = useMemo(() => (sortDescriptor ? _items.sort((a, b) => {
		const key = sortDescriptor.column;
		invariant(key, "sortDescriptor.column must be defined");
		const comparator = columnDict[key]?.comparator;
		invariant(comparator, "comparator must be defined");

		let cmp = comparator(a, b);

		if (sortDescriptor.direction === "descending")
			cmp *= -1;

		return cmp;
	}) : _items), [columnDict, _items, sortDescriptor]);

	return (
		<InnerTable
			aria-label="Example dynamic collection table"
			selectionMode={selectionMode}
			sortDescriptor={sortDescriptor}
			onSortChange={setSortDescriptor}
			onSelectionChange={selection => (selectionMode === "multiple" ? (
				selection === "all" ? onSelectionChanged(items)
				: onSelectionChanged(items.filter(x => selection.has(x.id)))
			) : selectionMode === "single" ? (
				onSelectionChanged(items.find(x => x.id === (selection as Set<Key>).keys().next().value) ?? null)
			) : selectionMode === "none" || selectionMode === undefined ? (
				undefined
			) : assertUnreachable(selectionMode, "unknown selectionMode"))}
		>
			<TableHeader columns={columns}>
				{column => (
					<Column allowsResizing allowsSorting={!!column.comparator}>
						<>
							{column.name}
						</>
					</Column>
				)}
			</TableHeader>
			<TableBody items={items}>
				{item => (item.RowProvider ? (
					<Row>
						{columnKey => <Cell>{columnDict[columnKey]?.onRender(item)}</Cell>}
					</Row>
				) : (
					<Row>
						{columnKey => <Cell>{columnDict[columnKey]?.onRender(item)}</Cell>}
					</Row>
				))}
			</TableBody>
		</InnerTable>
	);
}
