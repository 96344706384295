import { faCalendar, faCheck, faFingerprint, faUser, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "@linaria/core";
import classNames from "classnames";
import { Fragment, ReactNode, useMemo } from "react";
import { useQuery } from "react-query";
import { Outlet, useParams } from "react-router-dom";

import { Button } from "components/Button";
import { Image } from "components/Image";
import { LabelValuePair } from "components/LabelValuePair";
import { Loader } from "components/Loader";
import { Separator } from "components/Separator";
import { Order, OrderRow, OrderRowGarmentMetadata, User } from "httpClient";
import { theme } from "theme";
import { invariant } from "utils/invariant";
import { generateGetOrderQueryOptions } from "utils/react-query/data-fetching/order";
import { generateGetOrganizationUsersQueryOptions } from "utils/react-query/data-fetching/user";
import { ensureIsNotError } from "utils/react-query/ensureIsNotError";
import { isNotLoadingOrIdle } from "utils/react-query/isNotLoadingOrIdle";
import { backendUrl, imageUrl } from "utils/resourcePaths";

import IconCogsMuted from "../../../../../../../public/icons/icon-cogs-muted.svg";
import IconJacketMuted from "../../../../../../../public/icons/icon-jacket-muted.svg";
import IconPantsMuted from "../../../../../../../public/icons/icon-pants-muted.svg";
import IconPersonMuted from "../../../../../../../public/icons/icon-person-muted.svg";
import IconTshirtMuted from "../../../../../../../public/icons/icon-tshirt-muted.svg";


export default function PhotoshootDetailsPage() {
	const { orderId } = useParams();
	invariant(orderId, "orderId");

	const orderQuery = useQuery(generateGetOrderQueryOptions(orderId));
	const usersQuery = useQuery(generateGetOrganizationUsersQueryOptions());
	ensureIsNotError(orderQuery);
	ensureIsNotError(usersQuery);

	if (!isNotLoadingOrIdle(orderQuery) || !isNotLoadingOrIdle(usersQuery)) {
		return (
			<Loader />
		);
	}

	const order = orderQuery.data;
	const user = usersQuery.data.find(x => x.userId === order.createdByUserId);

	return (
		<>
			<OrderPageInnerContent order={order} user={user} />
			<Outlet />
		</>
	);
}


function OrderPageInnerContent({ order, user }: { order: Order, user?: User }) {
	const orderRowGroups = useMemo(() => {
		const groups: { [key: string]: { mainGarmentMetadata: OrderRowGarmentMetadata, orderRows: OrderRow[] } } = {};

		for (const orderRow of order.orderRows) {
			if (!groups[orderRow.mainGarmentMetadata.garmentId])
				groups[orderRow.mainGarmentMetadata.garmentId] = { mainGarmentMetadata: orderRow.mainGarmentMetadata, orderRows: [] };


			const group = groups[orderRow.mainGarmentMetadata.garmentId];
			if (group)
				group.orderRows.push(orderRow);
		}

		return Object.values(groups);
	}, [order]);


	const infoContainerStyle = css`
		border-radius: 6px;
		display: flex;
		flex-direction: column;
		gap: 8px;
		padding: 16px;
	`;

	const contentContainerStyle = css`
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
		overflow-y: scroll;
	`;

	const tableWrapperStyle = css`
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 30px;
		margin-top: 20px;
	`;

	return (
		<div className={contentContainerStyle}>
			<div className={infoContainerStyle}>
				<LabelValuePair icon={faFingerprint} label="Order Id" value={order.orderId} />
				{user && <LabelValuePair icon={faUser} label="Created by" value={`${user.firstName} ${user.lastName}`} />}
				<LabelValuePair icon={faCalendar} label="Created at" value={new Date(order.createdDate).toDateString()} />
				<LabelValuePair icon={faCalendar} label="Last modified at" value={new Date(order.modifiedDate).toDateString()} />
			</div>

			<div className={tableWrapperStyle}>
				{orderRowGroups.map(x => (
					<OrderRowGroupSection
						key={x.mainGarmentMetadata.garmentId}
						mainGarmentMetadata={x.mainGarmentMetadata}
						orderRows={x.orderRows}
					/>
				))}
			</div>
		</div>
	);
}

interface IOrderRowGroupSectionProps {
	mainGarmentMetadata: OrderRowGarmentMetadata,
	orderRows: OrderRow[],
}

function OrderRowGroupSection({
	mainGarmentMetadata, orderRows,
}: IOrderRowGroupSectionProps) {
	const orderRowGroupSectionContainerStyle = css`
		padding: 4px 4px;
		overflow: hidden;
	`;

	const imgWrapperStyle = css`
		position: relative;
		width: 80px;
		height: 80px;
		border-radius: 100%;
		overflow: hidden;
		
		padding: 20px;
		margin: auto 60px auto auto;
	`;

	const imgStyle = css`
		object-fit: cover;
	`;


	const mainStyle = css`
		display: grid;
		grid-template-columns: auto 1fr;
		grid-auto-rows: auto;
		row-gap: 20px;
	`;


	return (
		<div className={orderRowGroupSectionContainerStyle}>
			<div className={mainStyle}>
				<div className={imgWrapperStyle}>
					<Image src={imageUrl(mainGarmentMetadata.garmentImageMetadatas[0]?.originalGarmentImageKey ?? "", "xs")} className={imgStyle} alt={`garment: ${mainGarmentMetadata.garmentId}`} fill showImageOptions />
				</div>
				{orderRows.map((x, i) => (
					<Fragment key={x.orderRowId}>
						{i !== 0 && <div />}
						<OrderRowComponent
							orderRow={x}
						/>
					</Fragment>
				))}
			</div>
		</div>
	);
}


interface IOrderRowComponentProps {
	orderRow: OrderRow,
}

function OrderRowComponent({
	orderRow,
}: IOrderRowComponentProps) {
	const orderRowResult = orderRow.orderRowResults.find(x => x.isAccepted)
	?? orderRow.orderRowResults.find(x => x.isAccepted === null)
	?? orderRow.orderRowResults.find(x => x.isAccepted === false);

	const orderRowFormContainerStyle = css`
		background-color: ${theme.semantic.card};
		border-radius: 6px;
		box-shadow: ${theme.shadows.foregroundShadow};
		margin-right: 10px;
	`;

	const formContainerStyle = css`
		display: flex;
		align-items: center;
		padding: 16px 16px 0 16px;
	`;

	const otherOptionsContainerStyle = css`
		display: flex;
		flex-direction: column;
		margin-right: 40px;
	`;

	const buttonContainerStyle = css`
		display: flex;
		gap: 20px;
		align-items: center;
		padding: 0 16px 12px 16px;
	`;

	const overlayStyle = css`
		display: flex;
		height: 100%;
		width: 100%;
		justify-content: center;
		align-items: center;
		font-size: 28px;
		opacity: 0.5;
	`;

	const acceptedOverlay = css`
		background-color: ${theme.semantic.success};
		color: ${theme.semantic.successForeground};
	`;

	const rejectedOverlay = css`
		background-color: ${theme.semantic.danger};
		color: ${theme.semantic.dangerForeground};
	`;

	return (
		<div className={orderRowFormContainerStyle}>
			<div className={formContainerStyle}>
				<OrderRowComponentImage
					src={orderRow.outerGarmentImageMetadata && imageUrl(orderRow.outerGarmentImageMetadata.originalGarmentImageKey, "xs")}
					icon={IconJacketMuted}
				/>
				<OrderRowComponentImage
					src={orderRow.upperGarmentImageMetadata && imageUrl(orderRow.upperGarmentImageMetadata.originalGarmentImageKey, "xs")}
					icon={IconTshirtMuted}
				/>
				<OrderRowComponentImage
					src={orderRow.lowerGarmentImageMetadata && imageUrl(orderRow.lowerGarmentImageMetadata.originalGarmentImageKey, "xs")}
					icon={IconPantsMuted}
				/>
				<OrderRowComponentImage
					src={orderRow.personImageMetadata.clothedPersonImageKey && imageUrl(orderRow.personImageMetadata.clothedPersonImageKey, "xs")}
					icon={IconPersonMuted}
				/>
				<div className={otherOptionsContainerStyle}>
					{/* <Switch isReadOnly isSelected={orderRow.tuckUpper} label="Tuck 👕" /> */}
				</div>
				<OrderRowComponentImage
					src={orderRowResult ? imageUrl(orderRowResult.previewResultImageKey, "xs") : null}
					icon={IconCogsMuted}
					bigFlex
					overlay={orderRowResult?.isAccepted ? <div className={classNames(acceptedOverlay, overlayStyle)}><FontAwesomeIcon icon={faCheck} /></div>
					: orderRowResult?.isAccepted === false ? <div className={classNames(rejectedOverlay, overlayStyle)}><FontAwesomeIcon icon={faX} /></div>
					: undefined}
				/>
			</div>
			<Separator space={28} />
			<div className={buttonContainerStyle}>
				{
					orderRowResult && !orderRow.isClosed && (orderRowResult.isAccepted ? <Button variant="success" href={backendUrl(`/order-row-results/${orderRowResult.orderRowResultId}/get-result-image-file/${orderRowResult.filename}?asAttachment=true`)} target="_blank">Download</Button>
					: <Button variant="primary" href={orderRow.orderRowId}>Review</Button>)
				}
				<div>
					Status:
					{" "}
					{orderRow.isClosed ? "This row has unfortunately been closed. Contact us for more information." : !orderRowResult ? "In progress" : orderRowResult.isAccepted ? "Completed" : orderRowResult.isAccepted === null ? "Pending (waiting for your review)" : "Rejected (we will try to regenerate)"}
				</div>
			</div>
		</div>
	);
}


function OrderRowComponentImage({
	src, icon, overlay, bigFlex = false,
}: { src: string | null, icon: string, overlay?: ReactNode, bigFlex?: boolean }) {
	const overlayStyle = css`
		position: absolute;
		inset: 0;
	`;

	const outerContainerStyle = css`
		flex: 1;
		display: flex;
		flex-direction: column;
	`;

	const bigFlexStyle = css`
		flex: 3;
		display: flex;
		flex-direction: column;
		align-items: end;
	`;

	return (
		<div className={classNames(outerContainerStyle, { [bigFlexStyle]: bigFlex })}>
			<div className={classNames(imageContainerStyle)}>
				{src ? <Image className={imgStyle} src={src} alt="" fill showImageOptions /> : <ButtonIcon IconComponent={icon} />}
				{overlay && <div className={overlayStyle}>{overlay}</div>}
			</div>
		</div>
	);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function ButtonIcon({ IconComponent }: { IconComponent: any }) {
	return (
		<IconComponent width="100%" height="100%" />
	);
}

const imageContainerStyle = css`
	user-select: none;
	width: 80px;
	height: calc(80px * 4/3);
	border: 1px solid ${theme.semantic.border};
	padding: 1px;
	transition: border-color 0.5s ${theme.timingFunctions.default};
	border-radius: 4px;
	position: relative;
	overflow: hidden;
`;


const imgStyle = css`
	object-fit: contain;
	transition: transform 0.5s ${theme.timingFunctions.default};
`;
