import { ReactNode } from "react";
import { FormProvider } from "react-hook-form";

import { IFieldValues } from "./types";
import { IUseFormReturn } from "./useForm";


interface IProps<TFieldValues extends IFieldValues> {
	state: IUseFormReturn<TFieldValues, unknown>,
	children: ReactNode,
	sx?: React.CSSProperties,
}

export function Form<TFieldValues extends IFieldValues>(props: IProps<TFieldValues>) {
	const { children, state, sx } = props;

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<FormProvider {...state}>
			<form onSubmit={state.onSubmit} style={sx}>
				{children}
			</form>
		</FormProvider>
	);
}
