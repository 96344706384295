import { useMemo } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import * as yup from "yup";

import { CategoryName, ImageOrientation, Sex } from "httpClient";
import { createGetImageOrientationsQuery } from "utils/imageOrientation";
import { invariant } from "utils/invariant";
import { generateGetCategoryNamesQueryOptions } from "utils/react-query/data-fetching/categories";
import { generateGetSexesQueryOptions } from "utils/react-query/data-fetching/sex";

import { useFileSchema } from "../../../../../../../utils/useFileSchema";


export function useCreateGarmentSchema() {
	const fieldIsRequiredMessage = "This field is required";
	const fileSchema = useFileSchema(["png", "jpg", "jpeg", "webp"]);
	const queryClient = useQueryClient();
	const { orderId } = useParams();
	invariant(orderId, "orderId must be defined");

	return useMemo(() => yup.object({
		garmentImageFile: fileSchema.required("file must be provided").strict(),
		sex: yup.mixed<Sex>().test(
			"is-one-of-sexes",
			"Invalid value",
			async value => {
				try {
					const sexes = await queryClient.fetchQuery(generateGetSexesQueryOptions());
					return yup.mixed<Sex>().oneOf(sexes).isValidSync(value);
				} catch {
					return false;
				}
			}
		).required(fieldIsRequiredMessage).strict(),
		categoryName: yup.mixed<CategoryName>().test(
			"is-one-of-category-names",
			"Invalid value",
			async value => {
				try {
					const categoryNames = await queryClient.fetchQuery(generateGetCategoryNamesQueryOptions());
					return yup.mixed<CategoryName>().oneOf(categoryNames).isValidSync(value);
				} catch {
					return false;
				}
			}
		).required(fieldIsRequiredMessage).strict(),
		orientation: yup.mixed<ImageOrientation>().test(
			"is-one-of-image-orientations",
			"Invalid value",
			async value => {
				try {
					const imageOrientations = await queryClient.fetchQuery(createGetImageOrientationsQuery());
					return yup.mixed<ImageOrientation>().oneOf(imageOrientations).isValidSync(value);
				} catch {
					return false;
				}
			}
		).required(fieldIsRequiredMessage).strict(),
		createdForOrderId: yup.string().required(fieldIsRequiredMessage),
	}), [fileSchema, queryClient]);
}


export function useEditGarmentSchema() {
	const fieldIsRequiredMessage = "This field is required";
	const queryClient = useQueryClient();

	return useMemo(() => yup.object({
		sex: yup.mixed<Sex>().test(
			"is-one-of-sexes",
			"Invalid value",
			async value => {
				try {
					const sexes = await queryClient.fetchQuery(generateGetSexesQueryOptions());
					return yup.mixed<Sex>().oneOf(sexes).isValidSync(value);
				} catch {
					return false;
				}
			}
		).required(fieldIsRequiredMessage).strict(),
		categoryName: yup.mixed<CategoryName>().test(
			"is-one-of-category-names",
			"Invalid value",
			async value => {
				try {
					const categoryNames = await queryClient.fetchQuery(generateGetCategoryNamesQueryOptions());
					return yup.mixed<CategoryName>().oneOf(categoryNames).isValidSync(value);
				} catch {
					return false;
				}
			}
		).required(fieldIsRequiredMessage).strict(),
		orientation: yup.mixed<ImageOrientation>().test(
			"is-one-of-image-orientations",
			"Invalid value",
			async value => {
				try {
					const imageOrientations = await queryClient.fetchQuery(createGetImageOrientationsQuery());
					return yup.mixed<ImageOrientation>().oneOf(imageOrientations).isValidSync(value);
				} catch {
					return false;
				}
			}
		).required(fieldIsRequiredMessage).strict(),
	}), [queryClient]);
}
