import { ComponentProps, useCallback, useMemo, useRef, useState } from "react";


interface IUseFileDropzoneProps {
	onFilesDropped?: (files: File[]) => void,
}

interface IUseFileDropzoneReturnValue {
	isDragging: boolean,
	inputProps: ComponentProps<"input">,
	dropzoneProps: ComponentProps<"div">,
}

export function useFileDropzone({ onFilesDropped }: IUseFileDropzoneProps) {
	const [isDragging, setIsDragging] = useState(false);
	const fileInputRef = useRef<HTMLInputElement>(null);

	const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		setIsDragging(true);
	}, []);

	const handleDragLeave = useCallback(() => {
		setIsDragging(false);
	}, []);

	const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		setIsDragging(false);
		const { files } = event.dataTransfer;
		onFilesDropped?.(Array.from(files));
	}, [onFilesDropped]);

	const handleFileInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const { files } = event.target;

		if (files) {
			onFilesDropped?.(Array.from(files));
			event.target.value = ""; // Clear the file input value
		}
	}, [onFilesDropped]);

	const handleClickFileInput = useCallback(() => {
		if (fileInputRef.current)
			fileInputRef.current.click();
	}, []);

	const returnValue: IUseFileDropzoneReturnValue = useMemo(() => ({
		isDragging,
		inputProps: { type: "file", multiple: true, ref: fileInputRef, onChange: handleFileInputChange },
		dropzoneProps: { onDragOver: handleDragOver, onDragLeave: handleDragLeave, onDrop: handleDrop, onClick: handleClickFileInput },
	}), [handleDragOver, handleDragLeave, handleDrop, handleClickFileInput, handleFileInputChange, isDragging]);

	return returnValue;
}
