import { createContext, useContext, useMemo } from "react";
import { useRoutes, RouteObject } from "react-router-dom";

import { Modify } from "utils/modifyType";

import HomePage from "./HomePage";
import { useAppRoutes } from "./app/useAppRoutes";
import { useAuthRoutes } from "./auth/useAuthRoutes";


export type IRoute = Modify<RouteObject, { children?: IRoute[], index?: never }> & {
	name?: string,
	children?: IRoute[],
	index?: boolean,
};

const Context = createContext<IRoute[]>([]);

export const useSiteRoutes = () => useContext(Context);

export function SiteRoutes() {
	const authRoutes = useAuthRoutes();
	const appRoutes = useAppRoutes();

	const routes = useMemo(() => ([
		{
			path: "",
			name: "Index",
			element: <HomePage />,
		},
		appRoutes,
		authRoutes,
	]), [appRoutes, authRoutes]);

	const element = useRoutes(routes);
	return (
		<Context.Provider value={routes}>
			{element}
		</Context.Provider>
	);
}
