import { ReactNode } from "react";
import { Navigate } from "react-router-dom";

import { WeonLoadingOverlay } from "components/LoadingOverlay";
import { Permission, User } from "httpClient";
import { BlockedCustomerError, MissingPermissionsError, NotACustomerError } from "pages/app/(components)/userErrors";

import { useAuth } from "./providers/AuthProvider";


export function AuthGuard({ children }: { children: ReactNode | ((user: User) => ReactNode) }) {
	const { isAuthenticated, isLoading, user } = useAuth();

	if (isLoading)
		return <WeonLoadingOverlay />;

	if (!isAuthenticated)
		return <Navigate to="/auth/login" />;

	return typeof children === "function" ? <>{children(user)}</> : <>{children}</>;
}

export function ActiveCutomerGuard({ children }: { children: ReactNode }) {
	return (
		<AuthGuard>
			{user => {
				if (!user.customerInfo) throw new NotACustomerError(user);

				if (!user.isActive) throw new BlockedCustomerError(user);

				return <>{children}</>;
			}}
		</AuthGuard>
	);
}


export function PermissionsGuard({ children, requiredPermissions }: { children: ReactNode, requiredPermissions?: Permission[] }) {
	return (
		<AuthGuard>
			{
				user => {
					if (requiredPermissions && !requiredPermissions.every(x => user.permissions.includes(x)))
						throw new MissingPermissionsError(user, requiredPermissions);

					return <>{children}</>;
				}
			}
		</AuthGuard>
	);
}


export function UnAuthGuard({ children }: { children: ReactNode }) {
	const { isAuthenticated, isLoading } = useAuth();

	if (isLoading)
		return <WeonLoadingOverlay />;

	if (isAuthenticated)
		return <Navigate to="/app" />;


	return <>{children}</>;
}
