import { DatePicker } from "./DatePicker";
import { Form } from "./Form";
import { NumericField } from "./NumericField";
import { RadioGroup } from "./RadioGroup";
import { Select } from "./Select";
import { Submit } from "./Submit";
import { Switch } from "./Switch";
import { TextField } from "./TextField";


export const FormComponents = {
	TextField,
	Form,
	Submit,
	NumericField,
	Select,
	Switch,
	DatePicker,
	RadioGroup,
} as const;
