import { yupResolver } from "@hookform/resolvers/yup";
import { css } from "@linaria/core";
import { useMutation } from "react-query";
import * as yup from "yup";

import { MessageBar } from "components/MessageBar";
import { FormComponents } from "components/formComponents/FormComponents";
import { useForm } from "components/formComponents/useForm";
import { ApiError, DefaultService, extractApiErrorDetail, isHandledApiError } from "httpClient";


export function ForgotPasswordForm() {
	const { mutateAsync, isSuccess, isError, error } = useMutation(
		(email: string) => DefaultService.createUserPasswordResetApiUsersCreateUserPasswordResetPost(email),
		{
			useErrorBoundary: (err: ApiError) => !isHandledApiError(err, 401),
		}
	);

	const schema = yup.object({
		email: yup.string().required("Email is a required field.").email("Email is not in a valid format."),
	});

	const formContext = useForm<yup.InferType<typeof schema>>({
		onSubmit: data => mutateAsync(data.email),
		resolver: yupResolver(schema),
		mode: "onSubmit",
	});


	const pStyle = css`
		margin-top: 10px;
		margin-bottom: 20px;
	`;

	const messageBarWrapperStyle = css`
		width: 100%;
		margin-top: 10px;
	`;

	const innerFormStyle = css`
		width: 100%;
	`;


	return (
		<FormComponents.Form state={formContext}>
			<p className={pStyle}>Please enter your email address.</p>
			<div className={innerFormStyle}>
				<FormComponents.TextField placeholder="Email" name="email" type="email" isReadOnly={isSuccess} />
				{isSuccess ? (
					<div className={messageBarWrapperStyle}>
						<MessageBar type="success" isMultiline>Success! If the account exists, you will recieve and email with a password reset link.</MessageBar>
					</div>
				)
					: <FormComponents.Submit isDisabled={isSuccess}>Reset password</FormComponents.Submit>}
				{isError && error instanceof ApiError && (
					<div className={messageBarWrapperStyle}>
						<MessageBar type="error" isMultiline>{(extractApiErrorDetail(error) ?? error).message}</MessageBar>
					</div>
				)}
			</div>
		</FormComponents.Form>
	);
}
