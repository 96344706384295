import { css } from "@linaria/core";
import classNames from "classnames";
import { ForwardedRef, ReactNode, forwardRef } from "react";
import { Link as ReactRouterLinke } from "react-router-dom";

import { theme } from "theme";


interface ILinkProps {
	href: string,
	children: ReactNode,
	className?: string,
}

export default forwardRef(function Link({ href, children, className }: ILinkProps, ref: ForwardedRef<HTMLAnchorElement>) {
	const linkStyle = css`
		transition: color 0.3s ease-in-out;
		&:hover {
			color: ${theme.semantic.foregroundMuted}
		}
	`;

	return (
		// eslint-disable-next-line react/forbid-component-props
		<ReactRouterLinke ref={ref} to={href} className={classNames(linkStyle, className)}>{children}</ReactRouterLinke>
	);
});
