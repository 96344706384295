import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "@linaria/core";
import type { AriaToastRegionProps, AriaToastProps } from "@react-aria/toast";
import { useToastRegion, useToast } from "@react-aria/toast";
import { ToastQueue, ToastState, useToastQueue } from "@react-stately/toast";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { ReactNode, useRef } from "react";
import { useButton, AriaButtonProps } from "react-aria";
import { createPortal } from "react-dom";

import { theme } from "theme";
import { assertUnreachable } from "utils/assertUnreachable";
import { IMessageType } from "utils/messageType";


interface IToastContent {
	onRender: () => ReactNode,
	type: IMessageType,
}


interface IToastProviderProps {
	children?: undefined,
}


// Global toast queue
export const toastQueue = new ToastQueue<IToastContent>({
	maxVisibleToasts: 5,
	hasExitAnimation: false,
});

export function GlobalToastRegion(props: IToastProviderProps) {
	const state = useToastQueue(toastQueue);

	// Render toast region.
	return state.visibleToasts.length > 0
		? createPortal(
			<ToastRegion {...props} state={state} />,
			document.body
		)
		: null;
}


interface IToastRegionProps extends AriaToastRegionProps {
	state: ToastState<IToastContent>,
}

function ToastRegion({ state, ...props }: IToastRegionProps) {
	const ref = useRef<HTMLDivElement>(null);
	const { regionProps } = useToastRegion(props, state, ref);

	const toastRegionStyle = css`
		position: fixed;
		z-index: 999;
		bottom: 16px;
		right: 16px;
		display: flex;
		flex-direction: column;
		gap: 8px;
	`;

	return (
		<AnimatePresence mode="wait">
			<div
				{...regionProps}
				ref={ref}
				className={toastRegionStyle}
			>
				{state.visibleToasts.map(toast => (
					<Toast key={toast.key} toast={toast} state={state} />
				))}
			</div>
		</AnimatePresence>
	);
}


interface IToastProps extends AriaToastProps<IToastContent> {
	state: ToastState<IToastContent>,
}

function Toast({ state, ...props }: IToastProps) {
	const toast = props.toast;
	const ref = useRef<HTMLDivElement>(null);
	const { toastProps, titleProps, closeButtonProps } = useToast(props, state, ref);

	const toastStyle = css`
		display: flex;
		align-items: center;
		gap: 16px;
		padding: 12px 16px;
		border-radius: 8px;
	`;

	const toastInfoStyle = css`
		background-color: ${theme.semantic.info};
		color: ${theme.semantic.infoForeground};
		border: 1px solid ${theme.semantic.infoBorder};
	`;

	const toastWarningStyle = css`
		background-color: ${theme.semantic.warning};
		color: ${theme.semantic.warningForeground};
		border: 1px solid ${theme.semantic.warningBorder};
	`;

	const toastErrorStyle = css`
		background-color: ${theme.semantic.danger};
		color: ${theme.semantic.dangerForeground};
		border: 1px solid ${theme.semantic.dangerBorder};
	`;

	const toastSuccessStyle = css`
		background-color: ${theme.semantic.success};
		color: ${theme.semantic.successForeground};
		border: 1px solid ${theme.semantic.successBorder};
	`;

	const style =
		toast.content.type === "info" ? toastInfoStyle
		: toast.content.type === "warning" ? toastWarningStyle
		: toast.content.type === "error" ? toastErrorStyle
		: toast.content.type === "success" ? toastSuccessStyle
		: assertUnreachable(toast.content.type, "unknown toast.content.type");


	return (
		<motion.div
			initial={{
				opacity: 0,
				height: 0,
			}}
			exit={{
				opacity: 0,
				height: 0,
				zIndex: 0,
				transition: {
					type: "tween",
					duration: 0.15,
					ease: "circIn",
				},
			}}
			animate={{
				opacity: 1,
				height: "auto",
				zIndex: 1,
				transition: {
					type: "tween",
					duration: 0.15,
					ease: "circOut",
				},
			}}
		>
			<div
				{...toastProps}
				ref={ref}
				className={classNames(toastStyle, style)}
			>
				<div {...titleProps}>{props.toast.content.onRender()}</div>
				<DismissButton {...closeButtonProps} messageType={toast.content.type} />
			</div>
		</motion.div>
	);
}


interface IDismissButtonProps extends Omit<AriaButtonProps, "children"> {
	messageType: IMessageType,
}

function DismissButton(props: IDismissButtonProps) {
	const ref = useRef<HTMLButtonElement>(null);
	const { buttonProps } = useButton(props, ref);
	const messageType = props.messageType;

	const buttonStyle = css`
		background: none;
		appearance: none;
		border-radius: 50%;
		height: 32px;
		width: 32px;
		font-size: 16px;
		padding: 0;

		&:active {
			background: rgba(255, 255, 255, 0.2);
		}
	`;

	const infoStyle = css`
		color: ${theme.semantic.infoForeground};
		border: 1px solid ${theme.semantic.infoBorder};

		&:focus-visible {
			outline: none;
  			box-shadow: 0 0 0 2px ${theme.semantic.infoBorder}, 0 0 0 4px white;
		}
	`;

	const warningStyle = css`
		color: ${theme.semantic.warningForeground};
		border: 1px solid ${theme.semantic.warningBorder};

		&:focus-visible {
			outline: none;
  			box-shadow: 0 0 0 2px ${theme.semantic.warningBorder}, 0 0 0 4px white;
		}
	`;

	const errorStyle = css`
		color: ${theme.semantic.dangerForeground};
		border: 1px solid ${theme.semantic.dangerBorder};

		&:focus-visible {
			outline: none;
  			box-shadow: 0 0 0 2px ${theme.semantic.dangerBorder}, 0 0 0 4px white;
		}
	`;

	const successStyle = css`
		color: ${theme.semantic.successForeground};
		border: 1px solid ${theme.semantic.successBorder};

		&:focus-visible {
			outline: none;
  			box-shadow: 0 0 0 2px ${theme.semantic.successBorder}, 0 0 0 4px white;
		}
	`;

	const style =
		messageType === "info" ? infoStyle
		: messageType === "warning" ? warningStyle
		: messageType === "error" ? errorStyle
		: messageType === "success" ? successStyle
		: assertUnreachable(messageType, "unknown messageType");

	return (
		<button type="button" {...buttonProps} ref={ref} className={classNames(buttonStyle, style)}>
			<FontAwesomeIcon icon={faX} />
		</button>
	);
}
