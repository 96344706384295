import { faEnvelope, faTrash } from "@fortawesome/free-solid-svg-icons";
import { css } from "@linaria/core";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { CommandBar, ICommandBarItem } from "components/CommandBar";
import { WeonLoadingOverlay } from "components/LoadingOverlay";
import { Separator } from "components/Separator";
import { IColumn, Table } from "components/Table";
import { toastQueue } from "components/Toast";
import { useAuth } from "components/providers/AuthProvider";
import { DefaultService, User, UserInvitation } from "httpClient";
import { theme } from "theme";
import { invariant } from "utils/invariant";
import { generateGetOrganizationQueryOptions } from "utils/react-query/data-fetching/organization";
import { generateGetOrganizationUserInvitationsQueryOptions, generateGetOrganizationUsersQueryOptions } from "utils/react-query/data-fetching/user";

import { H1 } from "../(components)/H1";
import { MainContainer } from "../(components)/MainContainer";


type IPseudoUserRow = {
	id: string,
	name: string,
	email: string,
	status: "active" | "inactive" | "pending invitation" | "invitation expired",
	role?: string,
} & (
	{
		type: "user",
		user: User,
		invitation?: undefined,
	} | {
		type: "invitation",
		user?: undefined,
		invitation: UserInvitation,
	}
);

export default function ManageOrganizationPage() {
	const { user } = useAuth();

	const navigate = useNavigate();

	const organizationQuery = useQuery(generateGetOrganizationQueryOptions());
	const organizationUsersQuery = useQuery(generateGetOrganizationUsersQueryOptions());

	const organizationUserInvitationsQuery = useQuery(generateGetOrganizationUserInvitationsQueryOptions());

	const { mutateAsync } = useMutation(
		{
			mutationFn: (userInvitationId: number) => DefaultService.revokeOrganizationUserInvitationApiUsersRevokeOrganizationUserInvitationUserInvitationIdDelete(userInvitationId),
			onError: () => Promise.resolve(() => toastQueue.add({ onRender: () => "Something went wrong. Contact us if the issue persists.", type: "error" })),
		}
	);

	const [selectedPseudoUserRowId, setSelectedPseudoUserRowId] = useState<string | undefined>();


	if (organizationQuery.isError)
		throw organizationQuery.error;

	if (organizationUsersQuery.isError)
		throw organizationUsersQuery.error;

	if (organizationUserInvitationsQuery.isError)
		throw organizationUserInvitationsQuery.error;

	if (
		organizationQuery.isIdle
		|| organizationQuery.isLoading
		|| organizationUsersQuery.isLoading
		|| organizationUsersQuery.isIdle
		|| organizationUserInvitationsQuery.isIdle
		|| organizationUserInvitationsQuery.isLoading
	)
		return <WeonLoadingOverlay />;

	const organization = organizationQuery.data;

	const pseudoOrganizationUsers: IPseudoUserRow[] = [
		...organizationUserInvitationsQuery.data.map(x => ({
			id: `inv-${x.userInvitationId}`,
			email: x.email,
			name: "TBA",
			role: x.roles.includes("organization_admin") ? "Organization Admin" : "Normal user",
			status: "pending invitation" as const,
			type: "invitation" as const,
			invitation: x,
		})),
		...organizationUsersQuery.data.map(x => ({
			id: `usr-${x.userId}`,
			email: x.email,
			name: `${x.firstName} ${x.lastName}${x.userId === user?.userId ? " 🫵" : ""}`,
			role: x.roles.includes("organization_admin") ? "Organization Admin" : "Normal user",
			status: x.isActive ? "active" as const : "inactive" as const,
			type: "user" as const,
			user: x,
		})),
	];

	const divStyle = css`
		display: flex;
		flex-direction: column;
		height: 100%;
	`;

	const separatorWrapperStyle = css`
		width: 100%;
	`;

	const mainSectionStyle = css`
		display: grid;
		margin-top: 40px;
	`;

	const organizationNameStyle = css`
		color: ${theme.palette.blue};
	`;

	const usersSectionStyle = css`
		display: flex;
		flex-direction: column;
		gap: 12px;
		overflow-x: hidden;
		width: 100%;
	`;

	const sectionTitleStyle = css`
		margin: 0;
	`;

	const usersTableContainerStyle = css`
		background-color: ${theme.semantic.card};
		border-radius: 12px;
		padding: 24px 16px;
		overflow-x: hidden;
		width: 100%;
	`;

	const columns: IColumn<typeof pseudoOrganizationUsers[number]>[] = [
		{
			name: "Name", key: "name", minWidth: 120, onRender: item => item.name, comparator: (a, b) => a.name.localeCompare(b.name),
		},
		{ name: "Email", key: "email", minWidth: 120, onRender: item => item.email },
		{ name: "Role", key: "role", minWidth: 120, onRender: item => item.role },
		{ name: "Status", key: "status", minWidth: 120, onRender: item => <span className={css`font-weight: 600;`}>{item.status}</span> },
	];

	const commandBarItems: ICommandBarItem[] = [
		{
			icon: faEnvelope,
			text: "Invite User",
			onPress: () => navigate("invite-user"),
		},
		{
			icon: faTrash,
			text: "Revoke Invitation",
			isDisabled: !pseudoOrganizationUsers.some(x => x.id === selectedPseudoUserRowId && x.type === "invitation"),
			onPress: async () => {
				invariant(selectedPseudoUserRowId, "selectedPseudoUserRowId must be defined");
				const userInvitation = pseudoOrganizationUsers.find(x => x.id === selectedPseudoUserRowId)?.invitation;
				invariant(userInvitation, "userInvitation must be defined");
				await mutateAsync(userInvitation.userInvitationId);
			},
		},
	];

	return (
		<MainContainer>
			<div className={divStyle}>
				{/* <Breadcrumbs /> */}
				<H1>
					Manage
					{" "}
					<span className={organizationNameStyle}>{organization.name}</span>
				</H1>
				<div className={separatorWrapperStyle}><Separator /></div>
				<div className={mainSectionStyle}>


					<div className={usersSectionStyle}>
						<h2 className={sectionTitleStyle}>Users</h2>
						<div className={usersTableContainerStyle}>
							<CommandBar items={commandBarItems} />
							<Table
								columns={columns}
								items={pseudoOrganizationUsers}
								selectionMode="single"
								onSelectionChanged={x => setSelectedPseudoUserRowId(x?.id)}
							/>
						</div>
					</div>
				</div>
			</div>
		</MainContainer>
	);
}
