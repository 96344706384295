import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import { css } from "@linaria/core";
import { ReactNode, useCallback, useEffect, useMemo } from "react";
import { FormProvider, UseFormReturn, useFormContext, useWatch } from "react-hook-form";
import { useMutation, useQueries, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import * as yup from "yup";

import { ErrorBoundary } from "components/ErrorBoundary";
import { Image } from "components/Image";
import { Loader } from "components/Loader";
import { WeonLoadingOverlay } from "components/LoadingOverlay";
import { MenuButton } from "components/MenuButton";
import { MessageBar } from "components/MessageBar";
import { IColumn, Table } from "components/Table";
import { toastQueue } from "components/Toast";
import { FormComponents } from "components/formComponents/FormComponents";
import { useForm } from "components/formComponents/useForm";
import { ApiError, Body_create_garment_api_garments_create_garment_post, DefaultService, Garment, GarmentUpdate, extractApiErrorDetail } from "httpClient";
import { theme } from "theme";
import { invariant } from "utils/invariant";
import { useCategoryNameRadioOptions } from "utils/react-query/data-fetching/categories";
import { generateGetOrganizationGarmentsQueryOptions } from "utils/react-query/data-fetching/garment";
import { useSexOptions } from "utils/react-query/data-fetching/sex";
import { ensureIsNotError } from "utils/react-query/ensureIsNotError";
import { isNotLoadingOrIdle } from "utils/react-query/isNotLoadingOrIdle";
import { imageUrl } from "utils/resourcePaths";

import { useCreateGarmentSchema, useEditGarmentSchema } from "./(components)/garmentSchemas";
import { WizardContentLayout } from "../(components)/WizardContentLayout";
import { WizardButtons } from "../../(components)/WizardButtons";
import { WizardDescription } from "../../(components)/WizardDescription";
import { INewGarmentImageFilesSchema } from "../CreatePhotoshootWizardPage";


export default function FillMetadataPage() {
	return (
		(
			<WizardContentLayout>
				<PageContent />
			</WizardContentLayout>
		)
	);
}

function PageContent() {
	const garmentsQuery = useQuery(generateGetOrganizationGarmentsQueryOptions());
	const { orderId } = useParams();
	invariant(orderId, "orderId must be defined");

	ensureIsNotError(garmentsQuery);

	if (!isNotLoadingOrIdle(garmentsQuery))
		return <WeonLoadingOverlay />;

	return <GarmentFormsSection garments={garmentsQuery.data.filter(x => x.createdForOrderId === orderId)} />;
}

interface IGarmentFormsSection {
	garments: Garment[],
}

type IGarmentFormItem = { id: string } & ({
	file: File,
	garment?: undefined,
} | {
	file: File,
	garment: Garment,
} | {
	file?: undefined,
	garment: Garment,
});

function parsePotentialExternalGarmentId(orderId: string, file: File) {
	return `${orderId}:${file.name}`;
}

function getGarmentQueryCacheKey(externalGarmentId: string | null = null) {
	return ["get-garment-by-external-garment-id", externalGarmentId];
}

function createPipeGarmentFormItemQuery(item: IGarmentFormItem, orderId: string) {
	const externalGarmentId = item.garment ? item.garment.externalGarmentId : parsePotentialExternalGarmentId(orderId, item.file);

	return ({
		queryKey: getGarmentQueryCacheKey(externalGarmentId),
		queryFn: async () => {
			if (externalGarmentId) {
				try {
					const garment = await DefaultService.getGarmentByExternalGarmentIdApiGarmentsGetGarmentByExternalGarmentIdExternalGarmentIdGet(externalGarmentId);
					return ({ ...item, garment });
				} catch (err) {
					if (err instanceof ApiError && err.status === 404)
						return item;
					throw err;
				}
			}

			return item;
		},
		initialData: item,
		useErrorBoundary: false,
	});
}

function GarmentFormsSection({ garments }: IGarmentFormsSection) {
	const { orderId } = useParams();
	invariant(orderId, "orderId must be defined");
	const queryClient = useQueryClient();
	const categoryNameOptions = useCategoryNameRadioOptions();
	const sexOptions = useSexOptions();
	const formContext = useFormContext<INewGarmentImageFilesSchema>();
	const files = useWatch({ name: "files", control: formContext.control });

	const queries = useMemo(() => [
		...files.map(file => ({ file, garment: garments.find(x => x.externalGarmentId === parsePotentialExternalGarmentId(orderId, file)), id: file.name })),
		...garments.filter(
			garment => !garment.externalGarmentId || !(files.map(file => parsePotentialExternalGarmentId(orderId, file)).includes(garment.externalGarmentId))
		).map(garment => ({ file: undefined, garment, id: garment.garmentId })),
	].map(x => createPipeGarmentFormItemQuery(x, orderId)), [files, garments, orderId]);

	const pipedQueries = useQueries(queries);

	// Cast is fine here since default value is set in createPipeGarmentFormItemQuery
	const rows = useMemo(() => pipedQueries.map(x => ({ ...x.data as IGarmentFormItem, RowProvider: GarmentFormProvider })), [pipedQueries]);

	const columnWrapperStyle = css`
		padding-right: 16px;
	`;

	const menuButtonWrapperStyle = css`
		display: flex;
		justify-content: center;
	`;

	const columns: IColumn<typeof rows[number]>[] = useMemo(() => [
		{
			key: "image",
			onRender: item => {
				if (item.garment) {
					return (
						<div className={columnWrapperStyle}>
							<GarmentImage garment={item.garment} />
						</div>
					);
				}

				return (
					<div className={columnWrapperStyle}>
						<FileImage file={item.file} />
					</div>
				);
			},
			name: "Image",
			minWidth: 120,
			defaultWidth: "1fr",
		},
		{
			key: "sex",
			onRender: () => (
				<div className={columnWrapperStyle}>
					<FormComponents.RadioGroup name="sex" radios={sexOptions.options.map(x => ({ value: x.data, text: x.text }))} />
				</div>
			),
			minWidth: 140,
			defaultWidth: "1fr",
			name: "Sex",
		},
		{
			key: "category",
			onRender: () => (
				<div className={columnWrapperStyle}>
					<FormComponents.RadioGroup name="categoryName" {...categoryNameOptions} />
				</div>
			),
			minWidth: 210,
			defaultWidth: "1fr",
			name: "Category",
		},
		{
			key: "status",
			onRender: item => <GarmentRowStatusIndicator garment={item.garment} />,
			minWidth: 140,
			defaultWidth: "1fr",
			name: "Status",
			isMultiline: true,
		},
		{
			key: "more",
			onRender: item => (
				<div className={menuButtonWrapperStyle}>
					{(item.garment ? <GarmentRowMenuButton garment={item.garment} formContext={formContext} /> : <FileRowMenuButton file={item.file} formContext={formContext} />)}
				</div>
			),
			name: "",
			minWidth: 40,
			defaultWidth: 40,
		},
	], [
		categoryNameOptions,
		columnWrapperStyle,
		formContext,
		menuButtonWrapperStyle,
		sexOptions.options,
	]);


	const tableWrapperStyle = css`
		margin-top: 20px;
		overflow: auto;
		flex: 1 1 auto;
		height: 0;
	`;

	return (
		<>
			<div className={tableWrapperStyle}>
				<Table items={rows} columns={columns} />
			</div>
			<WizardDescription descriptionIconProp={faQuestionCircle} descriptionText={"Provide information about your garments to help guide our AI. Make sure that all garments are successfully marked as \"Saved\" before proceeding."} />
			<WizardButtons
				isNexButtontDisabled={!rows.every(x => x.garment)}
				onRequestNavigateNextStep={navigateNext => {
					void queryClient.invalidateQueries({ queryKey: generateGetOrganizationGarmentsQueryOptions().queryKey }).then(() => navigateNext?.());
				}}
			/>
		</>
	);
}


function FileImage({ file }: { file: File }) {
	const src = useMemo(() => URL.createObjectURL(file), [file]);

	const imgStyle = css`
		object-fit: cover;
		border-radius: 4px;
	`;

	return <Image width={80} height={80 * 4 / 3} alt={file.name} src={src} className={imgStyle} showImageOptions />;
}

function GarmentImage({ garment }: { garment: Garment }) {
	const garmentImage = Object.values(garment.garmentImages)[0];
	invariant(garmentImage, "garmentImage must be defined");

	const imgStyle = css`
		object-fit: cover;
		border-radius: 4px;
	`;

	return <Image width={80} height={80 * 4 / 3} alt={garment.garmentId} src={imageUrl(garmentImage.originalGarmentImageKey, "xs")} className={imgStyle} showImageOptions />;
}

function FileRowMenuButton({ file, formContext }: { file: File, formContext: UseFormReturn<INewGarmentImageFilesSchema> }) {
	const { orderId } = useParams();
	invariant(orderId, "orderId must be defined");
	const files = useWatch({ control: formContext.control, name: "files" });

	const deleteFile = useCallback(() => {
		formContext.setValue("files", files.filter(x => parsePotentialExternalGarmentId(orderId, x) !== parsePotentialExternalGarmentId(orderId, file)), { shouldValidate: true });
	}, [file, files, formContext, orderId]);

	const menuButtonStyle = css`
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 32px;
		height: 32px;
		border-radius: 100%;
		&:hover {
			background-color: ${theme.semantic.cardMuted};
		}
	`;

	return (
		<MenuButton
			variant="normalized"
			popoverPlacement="bottom end"
			items={[
				{
					key: "delete",
					onRender: () => (
						"Delete"
					),
					onClick: () => deleteFile(),
				},
			]}
		>
			<div className={menuButtonStyle}>
				<FontAwesomeIcon icon={faEllipsisV} />
			</div>
		</MenuButton>
	);
}

function GarmentRowStatusIndicator({ garment }: { garment: Garment | undefined }) {
	const columnWrapperStyle = css`
		padding-right: 16px;
	`;

	const formContext = useFormContext<INewGarmentImageFilesSchema>();

	const { formState: { isSubmitting } } = formContext;

	return (
		<div className={columnWrapperStyle}>
			{(isSubmitting ? (
				<Loader size={24} />
			) : garment ? (
				<MessageBar type="success">Saved</MessageBar>
			) : (
				<MessageBar type="info">Requires input</MessageBar>
			))}
		</div>
	);
}

function GarmentRowMenuButton({ garment, formContext }: { garment: Garment, formContext: UseFormReturn<INewGarmentImageFilesSchema> }) {
	const { orderId } = useParams();
	invariant(orderId, "orderId must be defined");
	const files = useWatch({ control: formContext.control, name: "files" });

	const queryClient = useQueryClient();

	const deleteGarmentMutation = useMutation(() => DefaultService.deleteGarmentApiGarmentDeleteGarmentGarmentIdDelete(garment.garmentId), {
		onSuccess: () => {
			formContext.setValue("files", files.filter(x => parsePotentialExternalGarmentId(orderId, x) !== garment.externalGarmentId), { shouldValidate: true });
		},
		onSettled: () => {
			void queryClient.invalidateQueries({ queryKey: getGarmentQueryCacheKey(garment.externalGarmentId) });
			void queryClient.invalidateQueries({ queryKey: generateGetOrganizationGarmentsQueryOptions().queryKey });
		},
	});

	const menuButtonStyle = css`
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 32px;
		height: 32px;
		border-radius: 100%;
		&:hover {
			background-color: ${theme.semantic.cardMuted};
		}
	`;

	return (
		deleteGarmentMutation.isLoading ? <Loader /> : (
			<MenuButton
				variant="normalized"
				popoverPlacement="bottom end"
				items={[
					{
						key: "delete",
						onRender: () => (
							"Delete"
						),
						onClick: () => deleteGarmentMutation.mutate(),
					},
				]}
			>
				<div className={menuButtonStyle}>
					<FontAwesomeIcon icon={faEllipsisV} />
				</div>
			</MenuButton>
		)
	);
}

type IGarmentFormProviderProps = {
	item: IGarmentFormItem,
	children: ReactNode,
};


function GarmentFormProvider({ item, children }: IGarmentFormProviderProps) {
	return (
		<ErrorBoundary>
			{item.garment ? (
				<UpdateGarmentFormProvider garment={item.garment}>
					{children}
				</UpdateGarmentFormProvider>
			) : (
				<CreateGarmentFormProvider file={item.file}>
					{children}
				</CreateGarmentFormProvider>
			)}
		</ErrorBoundary>
	);
}

interface ICreateGarmentFormProviderProps {
	file: File,
	children: ReactNode,
}


function CreateGarmentFormProvider({ file, children }: ICreateGarmentFormProviderProps) {
	const { orderId } = useParams();
	invariant(orderId, "orderId must be defined");
	const queryClient = useQueryClient();

	const createGarmentSchema = useCreateGarmentSchema();

	const createGarmentMutation = useMutation(
		(data: Body_create_garment_api_garments_create_garment_post) => DefaultService.createGarmentApiGarmentsCreateGarmentPost(data),
		{
			onSettled: () => queryClient.invalidateQueries({ queryKey: getGarmentQueryCacheKey(parsePotentialExternalGarmentId(orderId, file)) }),
			onError: (error: ApiError) => {
				const message = extractApiErrorDetail(error)?.message ?? error.message;
				toastQueue.add({ onRender: () => message, type: "error" }, { timeout: 6000 });
				formContext.reset();
			},
			useErrorBoundary: true,
		}
	);

	const formContext = useForm<yup.InferType<typeof createGarmentSchema>>({
		resolver: yupResolver(createGarmentSchema),
		defaultValues: {
			createdForOrderId: orderId,
			garmentImageFile: file,
			orientation: "Front",
		},
		onSubmit: data => createGarmentMutation.mutateAsync({ ...data, externalGarmentId: parsePotentialExternalGarmentId(orderId, data.garmentImageFile) }),
	});

	const { onSubmit, watch } = formContext;

	useEffect(() => {
		const subscription = watch(() => void onSubmit?.());
		return () => subscription.unsubscribe();
	}, [onSubmit, watch]);


	return (
		<FormProvider {...formContext}>
			{children}
		</FormProvider>
	);
}


interface IUpdateGarmentFormProviderProps {
	garment: Garment,
	children: ReactNode,
}

function UpdateGarmentFormProvider({ garment, children }: IUpdateGarmentFormProviderProps) {
	const { orderId } = useParams();
	invariant(orderId, "orderId must be defined");
	const garmentImage = Object.values(garment.garmentImages)[0];
	invariant(garmentImage, "garmentImage must be defined");

	const queryClient = useQueryClient();

	const updateGarmentSchema = useEditGarmentSchema();

	const updateGarmentMutation = useMutation(
		(data: GarmentUpdate) => DefaultService.updateGarmentApiGarmentsUpdateGarmentGarmentIdPut(garment.garmentId, data), {
			onSettled: () => queryClient.invalidateQueries({ queryKey: getGarmentQueryCacheKey(garment.externalGarmentId) }),
			onError: (error: ApiError) => {
				const message = extractApiErrorDetail(error)?.message ?? error.message;
				toastQueue.add({ onRender: () => message, type: "error" }, { timeout: 6000 });
				formContext.reset();
			},
			useErrorBoundary: true,
		}
	);

	const formContext = useForm<yup.InferType<typeof updateGarmentSchema>>({
		resolver: yupResolver(updateGarmentSchema),
		defaultValues: {
			categoryName: garment.categoryName,
			orientation: garmentImage.orientation,
			sex: garment.sex,
		},
		onSubmit: data => updateGarmentMutation.mutateAsync(data),
	});

	const { onSubmit, watch, formState: { isValid } } = formContext;

	useEffect(() => {
		const subscription = watch(() => isValid && void onSubmit?.());
		return () => subscription.unsubscribe();
	}, [isValid, onSubmit, watch]);

	return (
		<FormProvider {...formContext}>
			{children}
		</FormProvider>
	);
}
