import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "@linaria/core";
import classNames from "classnames";
import { ForwardedRef, MutableRefObject, ReactNode, forwardRef, useRef, Key } from "react";
import { AriaSelectOptions, HiddenSelect, useButton, useSelect } from "react-aria";
import { Item, ValidationState, useSelectState } from "react-stately";

import { theme } from "theme";

import { ListBox } from "./ListBox";
import { PopoverBase } from "./Popover";


export interface IUseSelectOptions<T = undefined> {
	disabled?: boolean,
	isError?: boolean,
	options: IOption<T>[],
}

interface IOption<T = undefined> {
	key: string,
	text: string,
	data: T,
}

export interface ISelectProps<T> {
	options: IOption<T>[],
	selectedKey?: string,
	name?: string,
	id?: string,
	text?: string,
	validationState?: ValidationState,
	isRequired?: boolean,
	isReadOnly?: boolean,
	isDisabled?: boolean,
	errorMessage?: string,
	onSelectionChange?: (value: Key) => void,
}


export function Select<T>(props: ISelectProps<T>) {
	return <InnerSelect {...{ label: props.text, ...props }}>{props.options.map(x => <Item key={x.key}>{x.text}</Item>)}</InnerSelect>;
}


function InnerSelect<T extends object>(props: AriaSelectOptions<T>) {
	// Create state based on the incoming props
	const state = useSelectState(props);

	// Get props for child elements from useSelect
	const ref = useRef(null);

	const {
		labelProps,
		triggerProps,
		valueProps,
		menuProps,
		errorMessageProps,
	} = useSelect(props, state, ref);

	const wrapperStyle = css`
		position: relative;
	`;

	const errorMessageStyle = css`
		margin-top: 5px;
		color: ${theme.semantic.errorColor};
		font-size: 12px;
		word-break: break-all;
		word-wrap: break-word;
		min-width: 100%;
    	width: 0;

		height: 0;
		opacity: 0;

		@keyframes entry {

			to {
				height: auto;
				opacity: 1;
			}
		}

		animation: entry 100ms forwards;
	`;

	const labelStyle = css`
		position: relative;
		padding: 5px 0;
		font-size: 14px;
		font-weight: 600;
		display: inline-block;
	`;

	const labelIsRequiredStyle = css`
		&::after {
			content: " *";
			color: ${theme.semantic.requiredAsterisk};
			padding-right: 12px;
		}
	`;

	return (
		<div className={wrapperStyle}>
			{props.label && <div className={classNames(labelStyle, { [labelIsRequiredStyle]: props.isRequired })} {...labelProps}>{props.label}</div>}
			<HiddenSelect
				state={state}
				triggerRef={ref}
				label={props.label}
				name={props.name}
			/>
			<SelectButton
				{...triggerProps}
				ref={ref}
				isInvalid={props.validationState === "invalid"}
				isDisabled={props.isDisabled}
			>
				<span {...valueProps}>
					{state.selectedItem
						? state.selectedItem.rendered
						: "Select an option"}
				</span>
			</SelectButton>
			{props.errorMessage && (
				<div {...errorMessageProps} className={errorMessageStyle}>
					{props.errorMessage}
				</div>
			)}
			{state.isOpen && (
				<PopoverBase state={state} triggerRef={ref} placement="bottom start">
					<ListBox {...menuProps} state={state} />
				</PopoverBase>
			)}
		</div>
	);
}

interface ISelectButtonProps {
	isDisabled?: boolean,
	children: ReactNode,
	isInvalid?: boolean,
}

const SelectButton = forwardRef(function SelectButton(props: ISelectButtonProps, ref: ForwardedRef<null>) {
	const { buttonProps } = useButton(props, ref as MutableRefObject<null>);

	const buttonStyle = css`
		border: 1px solid ${theme.semantic.fieldBorder};
		background-color: ${theme.semantic.field};
		width: 100%;
		font-size: 14px;
		padding: 8px 8px;
		cursor: pointer;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: 4px;
		gap: 8px;
		outline-color: ${theme.semantic.fieldOutline};
		transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;
		color: ${theme.semantic.foreground};

		&:hover {
			border-color: ${theme.semantic.fieldBorderHover};
		}
	`;

	const buttonInvalidStyle = css`
		&, &:hover, &:active, &:focus {
			border-color: ${theme.semantic.errorColor};
			outline-color: ${theme.semantic.errorColor};
		}
	`;

	return (
		<button type="button" {...buttonProps} ref={ref} className={classNames(buttonStyle, { [buttonInvalidStyle]: props.isInvalid })}>
			{props.children}
			<FontAwesomeIcon icon={faChevronDown} size="xs" />
		</button>
	);
});
