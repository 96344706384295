// import { css } from "@linaria/core";
import { useQuery } from "react-query";

import { WeonLoadingOverlay } from "components/LoadingOverlay";
import { Separator } from "components/Separator";
import { H1 } from "pages/app/(components)/H1";
import { generateGetOrganizationGarmentsQueryOptions } from "utils/react-query/data-fetching/garment";
import { generateGetOrganizationOrdersQueryOptions } from "utils/react-query/data-fetching/order";
import { generateGetOrganizationUsersQueryOptions } from "utils/react-query/data-fetching/user";
import { ensureIsNotError } from "utils/react-query/ensureIsNotError";
import { isNotLoadingOrIdle } from "utils/react-query/isNotLoadingOrIdle";

import { MainContainer } from "../../(components)/MainContainer";
import { OrdersList } from "../../(components)/OrdersList";
// import { useCreatePhotoshoot } from "../create/(components)/useCreatePhotoshoot";


export default function BrowsePhotoshootsPage() {
	// const { isLoading: createPhotoshootIsLoading, isSuccess: createPhotoshootIsSuccess } = useCreatePhotoshoot();
	const ordersQuery = useQuery(generateGetOrganizationOrdersQueryOptions());
	const garmentsQuery = useQuery(generateGetOrganizationGarmentsQueryOptions());
	const usersQuery = useQuery(generateGetOrganizationUsersQueryOptions());

	ensureIsNotError(garmentsQuery);
	ensureIsNotError(ordersQuery);
	ensureIsNotError(usersQuery);

	// const buttonWrapperStyle = css`
	// 	color: ${theme.palette.teriary};
	// 	padding: 12px 0;
	// 	border-radius: 4px;
	// 	font-weight: 600;
	// 	cursor: pointer;

	// 	&:hover {
	// 		color: ${theme.palette.teriary};
	// 	}
	// `;

	// const buttonRowStyle = css`
	// 	display: flex;
	// 	gap: 20px;
	// `;

	if (!isNotLoadingOrIdle(ordersQuery) || !isNotLoadingOrIdle(garmentsQuery) || !isNotLoadingOrIdle(usersQuery))
		return <WeonLoadingOverlay />;

	return (
		<MainContainer>
			<H1>
				Browse photoshoots
			</H1>
			<Separator space={20} />
			{/* <Breadcrumbs /> */}
			{/* <Hero
				imgSrc={heroUiPngSrc}
				title="Browse Photoshoots"
				description="Welcome to your organization's digital photoshoot gallery.
				Browse and navigate through the organization's current and past photoshoots.
				From here you can click to edit photoshoots left in draft, or click to review photoshoots that are in progress."
				functionalElement={(
					<div className={buttonRowStyle}>
						<Button variant="normalized" onPress={() => createPhotoshoot()}>
							<div className={buttonWrapperStyle}>Create a new Photoshoot</div>
						</Button>

						<Button variant="normalized" href="/app">
							<div className={buttonWrapperStyle}>Help</div>
						</Button>
					</div>
				)}
			/> */}
			<OrdersList orders={ordersQuery.data} />
		</MainContainer>
	);
}
