import { css } from "@linaria/core";
import { useMemo } from "react";
import { useQuery } from "react-query";

// import { Breadcrumbs } from "components/Breadcrumbs";
import { WeonLoadingOverlay } from "components/LoadingOverlay";
import { Separator } from "components/Separator";
import { useAuth } from "components/providers/AuthProvider";
import { Order, User } from "httpClient";
import { theme } from "theme";
import { generateGetOrganizationOrdersQueryOptions } from "utils/react-query/data-fetching/order";
// import { generateGetOrganizationQueryOptions } from "utils/react-query/data-fetching/organization";
import { isNotLoadingOrIdle } from "utils/react-query/isNotLoadingOrIdle";

import { H1 } from "./(components)/H1";
import { MainContainer } from "./(components)/MainContainer";
import { OrdersList } from "./(components)/OrdersList";
import { ShortcutButton } from "./(components)/ShortcutButton";
import { useCreatePhotoshoot } from "./photoshoots/create/(components)/useCreatePhotoshoot";
import IconCamera from "../../../public/icons/icon-camera.svg";
import IconList from "../../../public/icons/icon-list.svg";
import IconTshirt from "../../../public/icons/icon-tshirt.svg";


export function HomePage() {
	const organizationOrdersQuery = useQuery(generateGetOrganizationOrdersQueryOptions());
	// const organizationQuery = useQuery(generateGetOrganizationQueryOptions());

	const { user, isAuthenticated } = useAuth();

	if (!isAuthenticated)
		return <WeonLoadingOverlay />;

	// if (organizationQuery.isError)
	// 	throw organizationQuery.error;

	if (organizationOrdersQuery.isError)
		throw organizationOrdersQuery.error;

	if (!isNotLoadingOrIdle(organizationOrdersQuery))// || !isNotLoadingOrIdle(organizationQuery))
		return <WeonLoadingOverlay />;


	return (
		<DashboardPageContent orders={organizationOrdersQuery.data} user={user} />
	);
}


function DashboardPageContent({ orders, user }: { orders: Order[], user: User }) {
	const { createPhotoshoot } = useCreatePhotoshoot();

	const ordersInProgress = useMemo(() => {
		return orders.filter(x => x.orderStatus === "IN_PROGRESS" && x.orderRows.some(y => !y.orderRowResults.some(z => z.isAccepted)));
	}, [orders]).sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());

	const nameStyle = css`
		color: ${theme.palette.blue};
	`;


	const shortCutsContainerStyle = css`
		padding: 6rem 3rem;
	`;

	const shortCutsRowStyle = css`
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: 1fr;
		gap: 2rem;
	`;

	const mainContainerStyle = css`
		display: flex;
		flex-direction: column;
		flex: 1;
		height: 1px;
	`;

	const mutedTextStyle = css`
		width: 100%;
		text-align: center;
		color: ${theme.semantic.foregroundMuted};
		margin-bottom: 3rem;
		margin-top: 1rem;
	`;

	return (
		<MainContainer>
			{/* <Breadcrumbs /> */}
			<H1>
				{"Welcome, "}
				<span className={nameStyle}>{user.firstName}</span>
			</H1>
			<Separator space={20} />
			<div className={mainContainerStyle}>
				{orders.length > 0 ? (
					<>
						<div className={shortCutsContainerStyle}>
							<div className={shortCutsRowStyle}>
								<ShortcutButton text="View Drafts" href="/app/photoshoots/browse" IconComponent={IconList} />

								<ShortcutButton text="Create Photoshoot" onPress={createPhotoshoot} IconComponent={IconCamera} />

								<ShortcutButton text="Browse Garments" href="/app/garments" IconComponent={IconTshirt} />
							</div>
						</div>
						<h2>In-progress photoshoots</h2>
						<Separator space={20} />
						{ordersInProgress.length > 0 ? (
							<OrdersList orders={ordersInProgress} />
						) : <div className={mutedTextStyle}>Nothing here yet. Get started by creating a new photoshoot.</div>}
					</>
				) : (
					<div className={shortCutsContainerStyle}>
						<div className={mutedTextStyle}>Nothing here yet. Get started by creating a new photoshoot.</div>
						<div className={shortCutsRowStyle}>
							<div></div>
							<ShortcutButton text="Create Photoshoot" onPress={createPhotoshoot} IconComponent={IconCamera} />
							<div></div>
						</div>
					</div>
				)}

			</div>
		</MainContainer>
	);
}
