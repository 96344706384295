import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import { DefaultService } from "httpClient";


export function useCreatePhotoshoot() {
	const navigate = useNavigate();

	const {
		mutateAsync, isLoading, isIdle, isError, error, isSuccess,
	} = useMutation(() => DefaultService.createOrderApiOrdersCreateOrderPost(), {
		onSuccess: orderId => {
			navigate(`/app/photoshoots/create/${orderId}/upload-garments`);
		},
	});

	return {
		createPhotoshoot: () => void mutateAsync(), isLoading, isIdle, isError, error, isSuccess,
	};
}
