import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faArrowRightFromBracket, faBuildingUser, faChevronDown, faChevronRight, faUserPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "@linaria/core";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { Key, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "components/Button";
import { MenuButton } from "components/MenuButton";
import { Separator } from "components/Separator";
import { useAuth } from "components/providers/AuthProvider";
import { DefaultService } from "httpClient";
import { theme } from "theme";


interface INavLink {
	name: string,
	href: string,
}

type INavLinkGroupWithSublinks = {
	links: INavLink[],
	href?: undefined,
};

type INavLinkGroupSingle = {
	links?: undefined,
	href: string,
};

type INavLinkGroupBase = {
	name: string,
	icon: IconProp,
};

export type INavLinkGroup = INavLinkGroupBase & (
	INavLinkGroupWithSublinks | INavLinkGroupSingle
);


interface ISidebarProps {
	groups: INavLinkGroup[],
}


export function NavSidebar({ groups }: ISidebarProps) {
	const { user, logout } = useAuth();
	const navigate = useNavigate();

	// const { setIsDarkMode, isDarkMode } = useDarkMode();

	const organizationQuery = useQuery(["get-organization", user?.customerInfo?.organizationId], () => (user?.customerInfo ? DefaultService.getOrganizationApiOrganizationsGetOrganizationGet() : undefined));

	const navStyle = css`
		height: 100%;
		width: 260px;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		background-color: ${theme.semantic.background};

		border-right: 1px solid ${theme.semantic.borderMuted};

	`;

	const navSectionStyle = css`
		display: flex;
		flex-direction: column;
		gap: 8px;
	`;

	const userSectionStyle = css`
		padding: 16px 8px 0px 8px;
		display: grid;
		overflow: hidden;
	`;

	const userButtonStyle = css`
		display: grid;
		grid-template-columns: auto 1fr auto;
		cursor: pointer;
		border-radius: 4px;
		padding: 8px;
		transition: background-color 0.15s ease-in-out;
		overflow: hidden;

		color: ${theme.semantic.navLinkForeground};

		&:hover {
			background-color: ${theme.semantic.navLinkHover};
		}
	`;

	const separatorWrapperStyle = css`
		padding: 8px 24px;
	`;

	const userInitialsStyle = css`
		border-radius: 100%;
		background-color: ${theme.semantic.sidebarInitials};
		color: ${theme.semantic.sidebarInitialsForeground};
		border: 1px solid ${theme.semantic.sidebarInitialsBorder};
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 2px;
		height: 38px;
		width: 38px;
		font-weight: 600;
	`;

	const userInfoBoxStyle = css`
		padding-left: 8px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: flex;
		flex-direction: column;
		justify-content: center;
	`;

	const emailWrapperStyle = css`
		font-size: 12px;
		text-overflow: ellipsis;
		overflow: hidden;
		color: ${theme.semantic.foregroundMuted};
	`;

	const organizationNameStyle = css`
		font-size: 14px;
		font-weight: 600;
		text-overflow: ellipsis;
		overflow: hidden;
	`;

	const organizationLoadingStyle = css`
		color: transparent;

		@keyframes shine {
			to {
				background-position-x: -200%;
			}
		}

		background: linear-gradient(110deg, ${theme.semantic.skeleton} 8%, ${theme.semantic.skeletonShimmer} 18%, ${theme.semantic.skeleton} 33%);
		border-radius: 5px;
		background-size: 200% 100%;
		animation: 1.5s shine linear infinite;

	`;

	const menuIconWrapperStyle = css`
		padding: 0 10px;
		font-size: 8px;
		margin-left: auto;
		display: flex;
		align-items: center;
	`;

	// const bottomSectionStyle = css`
	// 	margin-top: auto;
	// 	padding: 16px 8px;
	// `;

	// const darkModeToggleButtonWrapperStyle = css`
	// 	display: flex;
	// 	align-items: center;
	// 	padding: 2px 16px;
	// 	gap: 20px;
	// 	justify-content: space-between;
	// 	cursor: pointer;
	// 	border-radius: 6px;
	// 	border: 1px solid ${theme.semantic.border};
	// 	transition: background-color 0.15s ease-in-out;

	// 	color: ${theme.semantic.navLinkForeground};

	// 	&:hover {
	// 		background-color: ${theme.semantic.navLinkHover};
	// 	}
	// `;

	const innerButtonWrapperStyle = css`

	`;

	const iconStyle = css`
		margin-right: 16px;
	`;

	if (organizationQuery.isError) throw organizationQuery.error;

	return (
		<nav className={navStyle}>
			{user && (
				<div className={userSectionStyle}>
					<MenuButton
						variant="normalized"
						popoverPlacement="right top"
						items={[
							{
								key: "Edit profile",
								onRender: key => (
									<span className={innerButtonWrapperStyle}>
										<FontAwesomeIcon icon={faUserPen} className={iconStyle} />
										{key}
									</span>
								),
								onClick: () => navigate("/app/edit-user"),
							},

							...(user.roles.includes("organization_admin") ? [
								{
									key: "Manage Organization",
									onRender: (key: Key) => (
										<span className={innerButtonWrapperStyle}>
											<FontAwesomeIcon icon={faBuildingUser} className={iconStyle} />
											{key}
										</span>
									),
									onClick: () => navigate("/app/manage-organization"),
								},
							] : []),

							{
								key: "Log out group",
								items: [{
									key: "Log out",
									onRender: key => (
										<span className={innerButtonWrapperStyle}>
											<FontAwesomeIcon icon={faArrowRightFromBracket} className={iconStyle} />
											{key}
										</span>
									),
									onClick: () => logout(),
								}],
							},
						]}
					>
						<div className={userButtonStyle}>
							<div className={userInitialsStyle}>{`${user.firstName.charAt(0)}${user.lastName.charAt(0)}`}</div>
							<div className={userInfoBoxStyle}>
								<div className={classNames(organizationNameStyle, { [organizationLoadingStyle]: organizationQuery.isIdle || organizationQuery.isLoading })}>
									{organizationQuery.data?.name ?? "Weon Insider"}
								</div>
								<div className={emailWrapperStyle}>
									{user.email}
								</div>
							</div>
							<div className={menuIconWrapperStyle}>
								<FontAwesomeIcon icon={faChevronRight} />
							</div>
						</div>
					</MenuButton>
				</div>
			)}
			<div className={separatorWrapperStyle}>
				<Separator />
			</div>
			<div className={navSectionStyle}>
				{groups.map((x, i) => <NavLinkGroupComponent key={i} group={x} />)}
			</div>
			{/* <div className={bottomSectionStyle}>
				<label className={darkModeToggleButtonWrapperStyle}>
					<FontAwesomeIcon icon={faMoon} />
					<Switch isSelected={isDarkMode} onChange={() => setIsDarkMode(prev => !prev)} />
				</label>
			</div> */}
		</nav>
	);
}

interface INavLinkGroupComponentProps {
	group: INavLinkGroup,
}

export function NavLinkGroupComponent({ group }: INavLinkGroupComponentProps) {
	const stripAppBasePath = (x: string) => x.replaceAll("/app", "");
	const location = useLocation();

	const pathname = useMemo(() => stripAppBasePath(location.pathname), [location]);

	const isCurrentPath = useMemo(() => {
		const isCurrentPathFn = (x: string) => (stripAppBasePath(x) === "" ? stripAppBasePath(x) === pathname : pathname.startsWith(stripAppBasePath(x)));
		return group.href !== undefined ? isCurrentPathFn(group.href) : group.links.some(x => isCurrentPathFn(x.href));
	}, [group, pathname]);

	useEffect(() => setIsOpenState(isOpenState => (isCurrentPath ? true : isOpenState)), [isCurrentPath]);

	const [isOpenState, setIsOpenState] = useState<boolean>(isCurrentPath);

	const wrapperStyle = css`
		padding: 0 8px;
	`;

	const containerStyle = css`
		font-size: 14px;
		position: relative;
		display: flex;
		flex-direction: column;
		border-radius: 4px;
	`;

	const iconTextWrapperStyle = css`
		display: flex;
		gap: 20px;
		padding: 10px 16px;
		cursor: pointer;
		user-select: none;
		font-weight: normal;
		transition: background-color 0.15s ease-in-out;
		border-radius: 4px;
		color: ${theme.semantic.navLinkForeground};
		align-items: center;

		&:hover {
			background-color: ${theme.semantic.navLinkHover};
		}
	`;

	const iconTextWrapperIsCurrentPathStyle = css`
		color: ${theme.semantic.navLinkActiveForeground};
		&:hover {
			background-color: ${theme.semantic.navLinkActiveHover};
		}
	`;

	const linksContainerStyle = css`
		padding-bottom: 10px;
	`;

	const currentPathStyle = css`
		background-color: ${theme.semantic.navLinkActive};
		color: ${theme.semantic.navLinkActiveForeground};

		&:hover{
			color: ${theme.semantic.navLinkActiveForeground};
		}
	`;

	const linkStyle = css`
		display: flex;
		padding: 4px 16px;
		gap: 20px;
		color: ${theme.semantic.navLinkForeground};
	`;

	const isCurrentPathStyle = css`
		font-weight: bold;
		color: ${theme.semantic.navLinkActiveForeground};
	`;

	return (
		<div className={wrapperStyle}>
			<div className={classNames(containerStyle, { [currentPathStyle]: isCurrentPath })}>
				<Button href={group.href} variant="normalized" onPress={() => setIsOpenState(prev => !prev)}>
					<div className={classNames(iconTextWrapperStyle, { [iconTextWrapperIsCurrentPathStyle]: isCurrentPath })}>
						<FontAwesomeIcon icon={group.icon} width={18} />
						{group.name}
						{Array.isArray(group.links) && <FontAwesomeIcon icon={faChevronDown} style={{ marginLeft: "auto", rotate: isOpenState ? "180deg" : "0deg", transition: "rotate 0.15s ease-in-out" }} />}
					</div>
				</Button>
				<AnimatePresence initial={false} mode="wait">
					{Array.isArray(group.links) && isOpenState && (
						<motion.div
							className={linksContainerStyle}
							initial={{
								opacity: 0,
								height: 0,
							}}
							exit={{
								opacity: 0,
								height: 0,
								zIndex: 0,
								transition: {
									type: "tween",
									duration: 0.15,
									ease: "circIn",
								},
							}}
							animate={{
								opacity: 1,
								height: "auto",
								zIndex: 1,
								transition: {
									type: "tween",
									duration: 0.15,
									ease: "circOut",
								},
							}}
						>
							{group.links.map((x, i) => (
								<Button key={i} href={x.href} variant="normalized">
									<div className={classNames(linkStyle, { [currentPathStyle]: isCurrentPath })}>
										<FontAwesomeIcon icon={group.icon} style={{ opacity: 0 }} width={18} />
										<div className={classNames({ [isCurrentPathStyle]: pathname.startsWith(stripAppBasePath(x.href)) })}>{x.name}</div>
									</div>
								</Button>
							))}
						</motion.div>
					)}
				</AnimatePresence>
			</div>
		</div>
	);
}
