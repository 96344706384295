// eslint-disable-next-line import/no-extraneous-dependencies
import { useObjectRef } from "@react-aria/utils";
import { ForwardedRef, ReactNode, Ref, forwardRef } from "react";
import { AriaButtonProps, useButton } from "react-aria";


export interface IPressEvent {
	type: "pressstart" | "pressend" | "pressup" | "press",
	pointerType: "mouse" | "pen" | "touch" | "keyboard" | "virtual",
	target: Element,
	shiftKey: boolean,
	ctrlKey: boolean,
	metaKey: boolean,
	altKey: boolean,
}

export type IButtonProps = Omit<AriaButtonProps<"button">, "label"> & (
	{
		children?: ReactNode,
	}
);


export const Clickable = forwardRef(function Clickable({ ...props }: IButtonProps, forwardedRef: ForwardedRef<Element>) {
	const ref = useObjectRef(forwardedRef);

	const { buttonProps } = useButton({
		elementType: props.href ? "a" : "button",
		onPress: props.onPress,
		// @ts-expect-error: Undocumented but exists @see https://github.com/adobe/react-spectrum/blob/60e30029e5627ac514a354219a6f790f5fee45f7/packages/%40react-aria/button/src/useButton.ts#L58
		preventFocusOnPress: true,
		...props,
	}, ref);

	return (
		<span {...buttonProps} ref={ref as Ref<HTMLButtonElement>}>
			{props.children}
		</span>
	);
});
