import { Outlet } from "react-router-dom";

import { PagePanel } from "components/Panel";
import { UnAuthGuard } from "components/ProtectedRoute";
import { IRoute } from "pages/Routes";

import { AuthLayout } from "./(components)/AuthLayout";
import LoginPage from "./login/LoginPage";
import ForgotPasswordPage from "./login/forgot-password/ForgotPasswordPage";
import ResetPasswordPage from "./login/reset-password/ResetPasswordPage";
import RegisterPage from "./register/RegisterPage";


export function useAuthRoutes(): IRoute {
	return ({
		path: "auth",
		name: "Auth",
		element: <UnAuthGuard>
			<AuthLayout>
				<Outlet />
			</AuthLayout>
		</UnAuthGuard>,
		children: [
			{
				path: "login",
				name: "Login",
				element: <>
					<LoginPage />
					<Outlet />
				</>,
				children: [
					{
						path: "forgot-password",
						name: "Forgot password",
						element: <PagePanel headerText="Forgot your Password?" size="sm">
							<ForgotPasswordPage />
						</PagePanel>,
					},
				],
			},
			{
				path: "register",
				name: "Register",
				element: <RegisterPage />,
			},
			{
				path: "reset-password",
				name: "Reset password",
				element: <ResetPasswordPage />,
			},
		],
	});
}
