import { yupResolver } from "@hookform/resolvers/yup";
import { css } from "@linaria/core";
import { useMemo } from "react";
import { FormProvider } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import * as yup from "yup";

import { Image } from "components/Image";
import { WeonLoadingOverlay } from "components/LoadingOverlay";
import { MessageBar } from "components/MessageBar";
import { Separator } from "components/Separator";
import { IColumn, Table } from "components/Table";
import { toastQueue } from "components/Toast";
import { FormComponents } from "components/formComponents/FormComponents";
import { useForm } from "components/formComponents/useForm";
import { useWizard } from "components/providers/WizardProvider";
import { ApiError, DefaultService, Order, OrderCommit, extractApiErrorDetail, isHandledApiError } from "httpClient";
import { fieldIsRequiredMessage } from "utils/fieldIsRequiredMessage";
import { invariant } from "utils/invariant";
import { generateGetOrganizationGarmentsQueryOptions } from "utils/react-query/data-fetching/garment";
import { generateGetOrderQueryOptions } from "utils/react-query/data-fetching/order";
import { generateGetOrganizationPersonsQueryOptions } from "utils/react-query/data-fetching/person";
import { ensureIsNotError } from "utils/react-query/ensureIsNotError";
import { isNotLoadingOrIdle } from "utils/react-query/isNotLoadingOrIdle";
import { imageUrl } from "utils/resourcePaths";

import { WizardContentLayout } from "../(components)/WizardContentLayout";
import { WizardButtons } from "../../(components)/WizardButtons";


export default function SummaryPage() {
	return (
		<WizardContentLayout>
			<PageContent />
		</WizardContentLayout>
	);
}

function PageContent() {
	const { orderId } = useParams();
	invariant(orderId, "orderId must be defined");

	const orderQuery = useQuery(generateGetOrderQueryOptions(orderId));
	const garmentsQuery = useQuery(generateGetOrganizationGarmentsQueryOptions());
	const personsQuery = useQuery(generateGetOrganizationPersonsQueryOptions());

	ensureIsNotError(garmentsQuery);
	ensureIsNotError(orderQuery);
	ensureIsNotError(personsQuery);

	if (!isNotLoadingOrIdle(orderQuery) || !isNotLoadingOrIdle(garmentsQuery) || !isNotLoadingOrIdle(personsQuery))
		return <WeonLoadingOverlay />;

	return <SummarySection order={orderQuery.data} />;
}

interface ISummarySectionProps {
	order: Order,
}

function SummarySection({ order }: ISummarySectionProps) {
	const { navigateNextStep } = useWizard();

	const commitOrderMutation = useMutation((data: OrderCommit) => DefaultService.commitOrderApiOrdersOrderIdCommitOrderPut(order.orderId, data), {
		onError: (error: ApiError) => {
			const message = (extractApiErrorDetail(error) ?? error).message;
			toastQueue.add({ onRender: () => message, type: "error" }, { timeout: 6000 });
		},
		onSuccess: () => navigateNextStep?.(),
		useErrorBoundary: err => !isHandledApiError(err, [422, 400]),
	});

	const schema = useMemo(() => yup.object({
		name: yup.string().required(fieldIsRequiredMessage),
		userNotes: yup.string().nullable().default(null),
	}), []);

	const formContext = useForm<yup.InferType<typeof schema>>({
		resolver: yupResolver(schema),
		defaultValues: {
			name: order.name,
			userNotes: order.userNotes,
		},
		onSubmit: data => commitOrderMutation.mutateAsync(data),
	});

	const mainContainerStyle = css`
		overflow: visible;
		flex: 1 1 auto;
		height: 0;
		display: grid;
		grid-template-columns: 1fr 2fr;
		grid-template-rows: 1fr;
		grid-gap: 40px;
	`;

	const userNotesContainerStyle = css`
		display: flex;
		flex-direction: column;
		gap: 40px;
	`;


	const infoContainerStyle = css`
		display: flex;
		flex-direction: column;
	`;

	const orderRowsContainerStyle = css`
		flex: 1 1 auto;
		height: 0;
		overflow-y: scroll;
		overflow-x: auto;
		position: relative;
	`;

	const imageContainerStyle = css`
		height: calc(60px * 4/3);
		width: 60px;
		object-fit: cover;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
	`;

	const items = order.orderRows.map(x => ({ ...x, id: x.orderRowId }));

	const imgStyle = css`
		object-fit: contain;
	`;

	const columns: IColumn<typeof items[number]>[] = [
		{
			key: "outerGarmentImage",
			name: "Outer Garment",
			minWidth: 60,
			onRender: item => {
				return (
					<div className={imageContainerStyle}>
						{item.outerGarmentImageMetadata && <Image className={imgStyle} fill src={imageUrl(item.outerGarmentImageMetadata.originalGarmentImageKey ?? "TODO image not found", "thumbnail")} alt="outer garment" />}
					</div>
				);
			},
		},
		{
			key: "upperGarmentImage",
			name: "Upper Garment",
			minWidth: 60,
			onRender: item => {
				return (
					<div className={imageContainerStyle}>
						{item.upperGarmentImageMetadata && <Image className={imgStyle} fill src={imageUrl(item.upperGarmentImageMetadata.originalGarmentImageKey ?? "TODO image not found", "thumbnail")} alt="upper garment" />}
					</div>
				);
			},
		},
		{
			key: "lowerGarmentImage",
			name: "Lower Garment",
			minWidth: 60,
			onRender: item => {
				return (
					<div className={imageContainerStyle}>
						{item.lowerGarmentImageMetadata && <Image className={imgStyle} fill src={imageUrl(item.lowerGarmentImageMetadata.originalGarmentImageKey ?? "TODO image not found", "thumbnail")} alt="lower garment" />}
					</div>
				);
			},
		},
		{
			key: "personImage",
			name: "Model",
			minWidth: 60,
			onRender: item => {
				return (
					<div className={imageContainerStyle}>
						{item?.personImageMetadata && item.personImageMetadata.clothedPersonImageKey && <Image className={imgStyle} fill src={imageUrl(item.personImageMetadata.clothedPersonImageKey ?? "TODO image not found", "thumbnail")} alt="person image" />}
					</div>
				);
			},
		},
		// {
		// 	key: "tuckUpper",
		// 	name: "Tuck ",
		// 	minWidth: 30,
		// 	onRender: item => {
		// 		return item.tuckUpper ? "Yes" : "No";
		// 	},
		// },
	];

	const summaryRowStyle = css`
		margin-bottom: 40px;
		display: flex;
		justify-content: flex-end;
		font-weight: 600;
		font-size: 16px;
	`;

	return (
		<FormProvider {...formContext}>
			<div className={mainContainerStyle}>
				<div className={userNotesContainerStyle}>
					<FormComponents.TextField name="name" placeholder="Photoshoot name" />
					<FormComponents.TextField name="userNotes" isMultiline rows={8} placeholder="Write any special instructions or notes here..." />
				</div>
				<div className={infoContainerStyle}>
					<div className={orderRowsContainerStyle}>
						<Table columns={columns} items={items} />
					</div>
					<Separator space={20} />
					<div className={summaryRowStyle}>
						Total photos:
						{" "}
						{order.orderRows.length}
					</div>
				</div>
			</div>
			{commitOrderMutation.isError && commitOrderMutation.error instanceof ApiError && <MessageBar type="error">{(extractApiErrorDetail(commitOrderMutation.error) ?? commitOrderMutation.error).message}</MessageBar>}
			<WizardButtons
				nextButtonText="Submit order"
				onRequestNavigateNextStep={() => {
					void formContext.onSubmit?.();
				}}
			/>
		</FormProvider>
	);
}
