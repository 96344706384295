import { css } from "@linaria/core";

import { Button } from "components/Button";
import { theme } from "theme";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ShortcutButton({ IconComponent, text, onPress, href }: { IconComponent: any, text: string } & ({ onPress: () => void, href?: undefined } | { href: string, onPress?: undefined })) {
	const buttonWrapperStyle = css`
		display: grid;
	`;

	const innerButtonStyle = css`
		background-color: ${theme.semantic.muted};
		color: ${theme.semantic.mutedForeground};
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: row;
		gap: 1rem;
		padding: 2rem 0;
		cursor: pointer;
		transition: background-color 0.3s ${theme.timingFunctions.default};
		border: 0.5px solid ${theme.semantic.border};
		border-radius: 0.5rem;

		&:hover {
			background-color: ${theme.semantic.mutedHover};
		}
	`;

	// const imgStyle = css`
	// 	object-fit: cover;
	// 	border-radius: 4px;
	// 	/* transition: transform 0.3s ${theme.timingFunctions.default};

	// 	${"."}${innerButtonStyle}:hover & {
	// 		transform: translateY(-10px);
	// 	} */
	// `;

	const textStyle = css`
		font-weight: normal;
		font-size: 1.1rem;
		text-align: center;
		/* transition: transform 0.3s ${theme.timingFunctions.default};

		${"."}${innerButtonStyle}:hover & {
			transform: translateY(-10px);
		} */
	`;

	return (
		<div className={buttonWrapperStyle}>
			<Button variant="normalized" href={href} onPress={onPress}>
				<div className={innerButtonStyle}>
					<IconComponent width="40px" height="40px" />
					{/* <Image src={heroImgSrc} height={40} width={40} alt="" className={imgStyle} /> */}
					<div className={textStyle}>{text}</div>
				</div>
			</Button>
		</div>
	);
}
