import { useQuery } from "react-query";

import { IUseRadioGroupOptions } from "components/RadioGroup";
import { IUseSelectOptions } from "components/Select";
import { DefaultService, Categories } from "httpClient";


export function generateGetCategoriesQueryOptions() {
	return {
		queryKey: ["get-categories"],
		queryFn: () => DefaultService.getCategoriesApiGetCategoriesGet(),
	};
}


export function generateGetCategoryNamesQueryOptions() {
	return {
		queryKey: ["get-category-names"],
		queryFn: () => DefaultService.getCategoryNamesApiConstantsGetCategoryNamesGet(),
	};
}

export const useCategoryNameOptions = (): IUseSelectOptions<keyof Categories> => {
	const categoriesQuery = useQuery(generateGetCategoriesQueryOptions());

	return {
		disabled: categoriesQuery.isLoading || categoriesQuery.isIdle,
		isError: categoriesQuery.isError,
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		options: (Object.values(categoriesQuery.data ?? {})).filter(x => x.showCustomer).map(x => ({ key: x.name!, text: x.displayName ?? x.name!, data: x.name as keyof Categories })),
	};
};

export const useCategoryNameRadioOptions = (): IUseRadioGroupOptions<keyof Categories> => {
	const categoriesQuery = useQuery(generateGetCategoriesQueryOptions());

	return {
		disabled: categoriesQuery.isLoading || categoriesQuery.isIdle,
		isError: categoriesQuery.isError,
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		radios: (Object.values(categoriesQuery.data ?? {})).filter(x => x.showCustomer).map(x => ({ text: x.displayName ?? x.name!, value: x.name as keyof Categories })),
	};
};
