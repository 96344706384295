import { css } from "@linaria/core";
import classNames from "classnames";
import { createContext, FocusEvent, ReactNode, useContext, useRef } from "react";
import { AriaRadioGroupProps, AriaRadioProps, useFocusRing, useRadio, useRadioGroup, VisuallyHidden } from "react-aria";
import { RadioGroupState, useRadioGroupState, ValidationState } from "react-stately";

import { theme } from "theme";
import { invariant } from "utils/invariant";


export interface IUseRadioGroupOptions<T extends string> {
	disabled?: boolean,
	isError?: boolean,
	radios: IRadio<T>[],
}

interface IRadio<T extends string> {
	text: string,
	value: T,
}

interface IOnRenderRadioProps {text: string, isSelected: boolean, isFocused: boolean, isDisabled: boolean}

export interface IRadioGroupProps<T extends string> {
	name: string,
	text?: string,
	radios: IRadio<T>[],
	value: T,
	onChange: (value: T) => void,
	onBlur?: (e: FocusEvent<Element, Element>) => void,
	errorMessage?: string,
	validationState?: ValidationState,
	isDisabled?: boolean,
	onRenderRadio?: (props: IOnRenderRadioProps) => ReactNode,
	isRequired?: boolean,
}

export function RadioGroup<T extends string>({
	text,
	radios,
	value,
	onChange,
	onBlur,
	isDisabled,
	name,
	onRenderRadio,
	errorMessage,
	validationState,
}: IRadioGroupProps<T>) {
	return (
		<AriaRadioGroup
			label={text}
			value={value}
			onChange={value => onChange(value as T)}
			isDisabled={isDisabled}
			name={name}
			errorMessage={errorMessage}
			validationState={validationState}
		>
			{radios.map(x => <AriaRadio key={x.value} onBlur={onBlur} value={x.value} text={x.text} onRender={onRenderRadio ?? DefaultRadioComponent} />)}
		</AriaRadioGroup>
	);
}

const RadioContext = createContext<RadioGroupState | undefined>(undefined);

function useRadioContext() {
	const value = useContext(RadioContext);
	invariant(value, "useRadioContext should only be used within the context of a RadioContextProvider");
	return value;
}

function AriaRadioGroup(props: AriaRadioGroupProps & { children: ReactNode }) {
	const {
		children, label, errorMessage, validationState,
	} = props;

	const state = useRadioGroupState(props);

	const { radioGroupProps, labelProps, errorMessageProps } = useRadioGroup(props, state);

	const labelStyle = css`
		position: relative;
		padding: 5px 0;
		font-size: 14px;
		font-weight: 600;
		display: inline-block;
	`;

	const labelIsRequiredStyle = css`
		&::after {
			content: " *";
			color: ${theme.semantic.requiredAsterisk};
			padding-right: 12px;
		}
	`;

	const errorMessageStyle = css`
		margin-top: 5px;
		color: ${theme.semantic.errorColor};
		font-size: 12px;
		word-break: break-all;
		word-wrap: break-word;
		min-width: 100%;
		width: 0;

		height: 0;
		opacity: 0;

		@keyframes entry {

			to {
				height: auto;
				opacity: 1;
			}
		}

		animation: entry 100ms forwards;
	`;

	return (
		<div {...radioGroupProps}>
			{label && <span {...labelProps} className={classNames(labelStyle, { [labelIsRequiredStyle]: props.isRequired })}>{label}</span>}
			<RadioContext.Provider value={state}>
				{children}
			</RadioContext.Provider>

			{errorMessage && validationState === "invalid" && (
				<div {...errorMessageProps} className={errorMessageStyle}>
					{errorMessage}
				</div>
			)}
		</div>
	);
}

function DefaultRadioComponent({ isFocused, isSelected, text, isDisabled }: IOnRenderRadioProps) {
	const strokeWidth = isSelected ? 6 : 2;

	const circleStyle = css`
		fill: none;
		stroke: ${theme.semantic.switch};
	`;

	const circleIsFocusedStyle = css`
		stroke: ${theme.semantic.fieldOutline};
	`;

	const circleOutlineStyle = css`
		fill: none;
		stroke: ${theme.semantic.fieldOutline};
	`;

	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				opacity: isDisabled ? 0.4 : 1,
			}}
		>
			<svg
				width={24}
				height={24}
				aria-hidden="true"
				style={{ marginRight: 4 }}
			>
				<circle
					cx={12}
					cy={12}
					r={8 - strokeWidth / 2}
					className={classNames(circleStyle, { [circleIsFocusedStyle]: isSelected })}
					strokeWidth={strokeWidth}
				/>
				{isFocused && (
					<circle
						cx={12}
						cy={12}
						r={11}
						className={circleOutlineStyle}
						strokeWidth={2}
					/>
				)}
			</svg>
			{text}
		</div>
	);
}

function AriaRadio(props: AriaRadioProps & { onRender: ({ text, isSelected, isFocused }: IOnRenderRadioProps) => ReactNode, text: string }) {
	const { onRender, text } = props;
	const state = useRadioContext();
	const ref = useRef(null);
	const { inputProps, isSelected, isDisabled } = useRadio(props, state, ref);
	const { isFocusVisible, focusProps } = useFocusRing();

	const labelStyle = css`
		cursor: pointer;
	`;

	return (
		<label className={labelStyle}>
			<VisuallyHidden>
				<input {...inputProps} {...focusProps} ref={ref} />
			</VisuallyHidden>
			{onRender({ text, isSelected, isDisabled, isFocused: isFocusVisible })}
		</label>
	);
}
