import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { faCircleExclamation, faImages, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import { css } from "@linaria/core";
import classNames from "classnames";
import { useCallback, useMemo, useRef, useState } from "react";
import { FieldErrors, FormProvider, useController, useFormContext } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import * as yup from "yup";

import { Button } from "components/Button";
import { Image } from "components/Image";
import { WeonLoadingOverlay } from "components/LoadingOverlay";
import { toastQueue } from "components/Toast";
import { FormComponents } from "components/formComponents/FormComponents";
import { useForm } from "components/formComponents/useForm";
import { useWizard } from "components/providers/WizardProvider";
import { useFileDropzone } from "components/useFileDropzone";
import { extractApiErrorDetail, isHandledApiError, ApiError, DefaultService, Order, OrderUpdate } from "httpClient";
import { theme } from "theme";
import { fieldIsRequiredMessage } from "utils/fieldIsRequiredMessage";
import { invariant } from "utils/invariant";
import { generateGetOrganizationGarmentsQueryOptions } from "utils/react-query/data-fetching/garment";
import { generateGetOrderQueryOptions } from "utils/react-query/data-fetching/order";
import { ensureIsNotError } from "utils/react-query/ensureIsNotError";
import { isNotLoadingOrIdle } from "utils/react-query/isNotLoadingOrIdle";
import { ALLOWED_IMAGE_EXTENSIONS } from "utils/useFileSchema";
import { useMeasure } from "utils/useMeasure";

import { WizardContentLayout } from "../(components)/WizardContentLayout";
import { WizardButtons } from "../../(components)/WizardButtons";
import { WizardDescription } from "../../(components)/WizardDescription";
import { INewGarmentImageFilesSchema } from "../CreatePhotoshootWizardPage";


export default function UploadGarmentsPage() {
	return (
		<WizardContentLayout>
			<PageContent />

		</WizardContentLayout>
	);
}

function PageContent() {
	const { orderId } = useParams();
	invariant(orderId, "orderId must be defined");

	const orderQuery = useQuery(generateGetOrderQueryOptions(orderId));
	ensureIsNotError(orderQuery);

	if (!isNotLoadingOrIdle(orderQuery))
		return <WeonLoadingOverlay />;

	return <UploadGarmentsSection order={orderQuery.data} />;
}

interface IUploadGarmentsSectionProps {
	order: Order,
}


function UploadGarmentsSection({ order }: IUploadGarmentsSectionProps) {
	const { navigateNextStep } = useWizard();
	const formContext = useFormContext<INewGarmentImageFilesSchema>();

	const updateOrderMutation = useMutation((data: OrderUpdate) => DefaultService.updateOrderApiOrdersOrderIdUpdateOrderPut(order.orderId, data), {
		onError: (error: ApiError) => {
			const message = (extractApiErrorDetail(error) ?? error).message;
			toastQueue.add({ onRender: () => message, type: "error" }, { timeout: 6000 });
		},
		onSuccess: () => navigateNextStep?.(),
		useErrorBoundary: err => !isHandledApiError(err, [422, 400]),
	});

	const schema = useMemo(() => yup.object({
		name: yup.string().required(fieldIsRequiredMessage),
		userNotes: yup.string().nullable().default(null),
	}), []);

	const orderUpdateFormContext = useForm<yup.InferType<typeof schema>>({
		resolver: yupResolver(schema),
		defaultValues: {
			name: order.name,
			userNotes: null,
		},
		onSubmit: data => updateOrderMutation.mutateAsync(data),
	});

	const files = formContext.watch("files");

	const onFilesDropped = useCallback((newFiles: File[]) => {
		formContext.setValue(
			"files",
			Object.values(Object.fromEntries(
				files.map(x => ([x.name, x]) as const).concat(newFiles.map(x => ([x.name, x])))
			))
		);

		void formContext.trigger();
	}, [files, formContext]);


	const { dropzoneProps, inputProps, isDragging } = useFileDropzone({
		onFilesDropped,
	});

	const dropzoneStyle = css`
		border: 3px dashed ${theme.semantic.border};
		border-radius: 6px;
		position: relative;
		margin-top: 20px;
		cursor: pointer;
		display: flex;

		color: ${theme.semantic.foregroundMuted};
		padding: 20px;
		flex: 1 1 auto;
		overflow-y: auto;
		height: 0px;

		transition: ease 0.4s;
	`;

	const dropzoneIsDragginStyle = css`
		border: 3px solid ${theme.palette.blue};
		background-color: ${theme.palette.blueLight};
		color: ${theme.palette.blue};
	`;

	const inputStyle = css`
		position: absolute;
		visibility: hidden;
	`;

	const noFilesContainerStyle = css`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex: 1;
		gap: 20px;
	`;

	const iconStyle = css`
		font-size: 72px;
		color: ${theme.semantic.foregroundMuted};
	`;

	const noFilesTextStyle = css`
		font-size: 18px;

	`;

	const emphStyle = css`
		font-weight: 600;
	`;

	return (
		<>
			<FormProvider {...orderUpdateFormContext}>
				<FormComponents.TextField
					name="name"
					placeholder="Photoshoot name"
				/>
			</FormProvider>
			<div {...dropzoneProps} className={classNames(dropzoneStyle, { [dropzoneIsDragginStyle]: isDragging })}>
				<input className={inputStyle} type="file" multiple accept={ALLOWED_IMAGE_EXTENSIONS.map(ext => `.${ext}`).join(",")} {...inputProps} />
				{files.length ? <NewGarmentImageFilesPreview files={files} /> : (
					<div className={noFilesContainerStyle}>
						<FontAwesomeIcon icon={faImages} className={iconStyle} />
						<div className={noFilesTextStyle}>
							Press the zone to
							{" "}
							<span className={emphStyle}>browse files</span>
							{" "}
							or
							{" "}
							<span className={emphStyle}>drag them</span>
							{" "}
							here.
						</div>
					</div>
				)}
			</div>
			<WizardDescription
				descriptionIconProp={faQuestionCircle}
				descriptionText="Upload images of the garments that you want to include in your photoshoot. Remember that you can reuse garments from previous photoshoots without uploading them again."
			/>
			<WizardButtons
				onRequestNavigateNextStep={() => {
					void orderUpdateFormContext.onSubmit?.();
				}}
			/>
		</>
	);
}

interface INewGarmentImageFilesPreviewProps {
	files: File[],
}

function NewGarmentImageFilesPreview({ files }: INewGarmentImageFilesPreviewProps) {
	const garmentImageFilesContainerStyle = css`
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 16px;
	`;

	return (
		<div className={garmentImageFilesContainerStyle}>
			{files.map((x, i) => <FilePreview key={x.name} fileIndex={i} />)}
		</div>
	);
}

interface IFilePreviewProps {
	fileIndex: number,
}

function FilePreview({ fileIndex }: IFilePreviewProps) {
	const [fileCotainerDivRef, { width: fileContainerWidth }] = useMeasure();
	const filenameRef = useRef<HTMLSpanElement>(null);
	const [isPreviewError, setIsPreviewError] = useState(false);
	const queryClient = useQueryClient();

	const formContext = useFormContext<INewGarmentImageFilesSchema>();

	const { field, fieldState: { error: errors } } = useController({ control: formContext.control, name: "files" });

	const files = field.value;

	const error = (errors as unknown as FieldErrors | undefined)?.[fileIndex];

	const file = files[fileIndex];

	invariant(file, "file must be defined");

	const imageUrl = useMemo(() => URL.createObjectURL(file), [file]);

	const removeFile = useCallback(() => {
		formContext.setValue("files", files.filter((_, i) => i !== fileIndex));
		void formContext.trigger("files");
		void queryClient.invalidateQueries({ queryKey: generateGetOrganizationGarmentsQueryOptions().queryKey });
	}, [formContext, files, queryClient, fileIndex]);

	const filePreviewStyle = css`
		object-fit: cover;
		border-radius: 4px;
	`;

	const filePreviewContainerStyle = css`
		border: 1px solid ${theme.semantic.border};
		border-radius: 4px;
		position: relative;
		align-self: flex-start;
		height: min-content;
		position: relative;
	`;

	const filePreviewContainerErrorStyle = css`
		border: 1px solid ${theme.semantic.dangerBorder};
	`;

	const filenameContainerStyle = css`
		overflow: hidden;
		padding: 8px;
		position: absolute;
		bottom: 0px;
		width: 100%;
		background-color: ${theme.palette.black};
		color: ${theme.palette.white};
		opacity: 0.5;
		white-space: nowrap;
	`;

	const filenameStyle = css`
		overflow: visible;
	`;

	const filenameScrollTextStyle = css`
		animation: scroll-text 5s linear infinite;

		@keyframes scroll-text {
			0% {
				transform: translateX(0); /* Start position of the scrolling animation */
			}
			100% {
				transform: translateX(-100%); /* End position of the scrolling animation */
			}
		}
	`;

	const removeButtonContainerStyle = css`
		position: absolute;
		top: 0;
		right: 0;
		transform: translateX(50%) translateY(-50%);
		opacity: 0;
		transition: opacity 0.2s ease;
		z-index: 1;
		
		${"."}${filePreviewContainerStyle}:hover &, &:focus {			
			opacity: 1;
		}

	`;

	const removeButtonStyle = css`
		border-radius: 100%;
		width: 32px;
		height: 32px;
		background-color: ${theme.semantic.danger};
		color: ${theme.semantic.dangerForeground};
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
	`;

	const imageWrapperStyle = css`
		overflow: hidden;
	`;

	const errorImageContainerStyle = css`
		display: flex;
		justify-content: center;
		align-items: center;
		width: 150px;
		height: 265px;
	`;

	const errorIconStyle = css`
		color: ${theme.semantic.dangerForeground};
		font-size: 48px;
	`;

	const errorMessageContainerStyle = css`
		overflow: hidden;
		padding: 8px;
		position: absolute;
		top: 0px;
		width: 100%;
		background-color: ${theme.semantic.danger};
		color: ${theme.semantic.dangerForeground};
		opacity: 0.75;
		white-space: nowrap;
		z-index: 0;
	`;

	const errorMessageStyle = css`
		overflow: visible;

		animation: scroll-text 5s linear infinite;

		@keyframes scroll-text {
			0% {
				transform: translateX(0); /* Start position of the scrolling animation */
			}
			100% {
				transform: translateX(-100%); /* End position of the scrolling animation */
			}
		}
	`;

	return (
		<div ref={fileCotainerDivRef} className={classNames(filePreviewContainerStyle, { [filePreviewContainerErrorStyle]: !!error || isPreviewError })}>
			<div className={imageWrapperStyle}>
				{isPreviewError ? (
					<div className={errorImageContainerStyle}>
						<FontAwesomeIcon icon={faCircleExclamation} className={errorIconStyle} />
					</div>
				) : (
					<Image
						alt={file.name}
						src={imageUrl}
						className={filePreviewStyle}
						width={150}
						height={265}
						onError={() => {
							setIsPreviewError(true);
						}}
					/>
				)}
			</div>
			<div className={filenameContainerStyle}>
				<div className={classNames(filenameStyle, { [filenameScrollTextStyle]: (filenameRef.current?.getBoundingClientRect().width ?? 0) > fileContainerWidth })}>
					<span ref={filenameRef}>{file.name}</span>
				</div>
			</div>
			<div className={removeButtonContainerStyle}>
				<Button
					variant="normalized"
					onPress={() => {
						removeFile();
					}}
				>
					<div className={removeButtonStyle}><FontAwesomeIcon icon={faX} /></div>
				</Button>
			</div>

			{error && (
				<div className={errorMessageContainerStyle}>
					<div className={errorMessageStyle}>
						<span>{error?.message as string}</span>
					</div>
				</div>
			)}
		</div>
	);
}
