import { useController, useFormContext } from "react-hook-form";

import { NumericField as NumericFieldComponent, INumericFieldProps } from "components/NumericField";

import { IFormFieldProps } from "./types";


export function NumericField(props: IFormFieldProps<INumericFieldProps>) {
	const {
		name,
		isDisabled = false,
		...rest
	} = props;

	const { control, formState: { isSubmitting } } = useFormContext();

	const {
		field: {
			// Legacy
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			onChange, onBlur, name: fieldName, value,
		},
		fieldState: { error },
	} = useController({
		name, control,
	});

	return (
		<NumericFieldComponent
			{...rest}
			name={fieldName}
			// Legacy
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			value={value ?? ""}
			isDisabled={isDisabled || isSubmitting}
			errorMessage={error?.message}
			onChange={onChange}
			onBlur={onBlur}
			validationState={error ? "invalid" : "valid"}
		/>
	);
}
