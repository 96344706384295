/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useMemo } from "react";
import * as yup from "yup";


const MAX_FILE_SIZE = 100000000;

export const ALLOWED_IMAGE_EXTENSIONS = ["png", "jpg", "jpeg", "webp"];

export function useFileSchema(allowedExtensions: string[]) {
	const allowedExtensionsProcessed = useMemo(() => allowedExtensions.map(x => x.toLowerCase().replace(".", "")), [allowedExtensions]);
	return useMemo(() => yup.mixed<File>()
		.test({
			message: `File too big, can't exceed ${MAX_FILE_SIZE / 1000000} MB`,
			test: file => {
				if (file)
					return file.size < MAX_FILE_SIZE;
				return true;
			},
		}).test({
			message: `This extension is not allowed. Only ${allowedExtensions.join(",")} are allowed.`,
			test: file => {
				if (file)
					return allowedExtensionsProcessed.includes(file.name.split(".").pop()?.toLowerCase() ?? "");
				return true;
			},
		}), [allowedExtensions, allowedExtensionsProcessed]);
}
