import { css } from "@linaria/core";
import { ReactNode } from "react";


interface IMainProps {
	children: ReactNode,

}


export default function Main({ children }: IMainProps) {
	const mainStyle = css`
		flex: 1;
		overflow-y: auto;
		overflow-x: hidden;
		position: relative;
	`;

	return (
		<main className={mainStyle}>
			{children}
		</main>
	);
}
