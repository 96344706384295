import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { css } from "@linaria/core";
import type { PressEvent } from "@react-types/shared";

import { Button } from "./Button";


export interface ICommandBarItem {
	icon: IconProp,
	text: string,
	isDisabled?: boolean,
	onPress?: (e?: PressEvent) => (Promise<void> | void),
}

interface ICommandBarProps {
	items: ICommandBarItem[],
}

export function CommandBar({ items }: ICommandBarProps) {
	const commandBarStyle = css`
		display: flex;
		gap: 4px;
	`;

	return (
		<div className={commandBarStyle}>
			{items.map((x, i) => (
				<Button
					key={i}
					variant="flat"
					leftIconProps={{ icon: x.icon, marginRight: 12 }}
					size="md"
					onPress={x.onPress}
					isDisabled={x.isDisabled}
				>
					{x.text}
				</Button>
			))}
		</div>
	);
}
