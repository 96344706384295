import { css } from "@linaria/core";
import classNames from "classnames";
import { useMemo, useRef } from "react";
import { mergeProps, useFocusRing, useListBox, useOption, AriaListBoxProps } from "react-aria";
import { ListState, Node } from "react-stately";

import { theme } from "theme";


interface IInnerListBoxProps<T> extends AriaListBoxProps<T> {
	state: ListState<T>,
	width?: number,
}

export function ListBox<T>(props: Omit<IInnerListBoxProps<T>, "children">) {
	const state = props.state;

	// Get props for the listbox element
	const ref = useRef(null);
	const { listBoxProps, labelProps } = useListBox(props, state, ref);

	const items = useMemo(() => Array.from(state.collection), [state.collection]);

	const ulStyle = css`
		border: 1px solid ${theme.semantic.border};
		padding: 0;
		margin: 5px 0;
		list-style: none;

		border-radius: 4px;
	`;

	return (
		<>
			<div {...labelProps}>{props.label}</div>
			<ul
				{...listBoxProps}
				ref={ref}
				className={ulStyle}
				style={{ width: props.width }}
			>
				{items.map(item => (
					<Option key={item.key} item={item} state={state} />
				))}
			</ul>
		</>
	);
}


interface IOptionProps<T> {
	state: ListState<T>,
	item: Node<T>,
}

function Option<T>({ item, state }: IOptionProps<T>) {
	// Get props for the option element
	const ref = useRef<HTMLLIElement>(null);

	const { optionProps, isSelected, isDisabled } = useOption(
		{ key: item.key },
		state,
		ref
	);

	// Determine whether we should show a keyboard
	// focus ring for accessibility
	const { isFocusVisible, focusProps } = useFocusRing();

	const liStyle = css`
		padding: 8px 16px;
		cursor: pointer;
		background-color: ${theme.semantic.listItem};
		outline: none;

		&:hover {
			background-color: ${theme.semantic.listItemHover};
		}
	`;

	const liSelectedStyle = css`
		&, &:hover{
			background-color: ${theme.semantic.listItemSelected};
		}
	`;

	const liDisabledStyle = css`
		background-color: ${theme.semantic.disabledButton};
		color: ${theme.semantic.disabledButtonForeground};
	`;

	const liFocusStyle = css`
		outline: 2px solid ${theme.palette.blue};
		background-color: ${theme.semantic.listItemHover};
	`;


	return (
		<li
			{...mergeProps(optionProps, focusProps)}
			ref={ref}
			className={classNames(liStyle, { [liSelectedStyle]: isSelected, [liDisabledStyle]: isDisabled, [liFocusStyle]: isFocusVisible })}
		>
			{item.rendered}
		</li>
	);
}
