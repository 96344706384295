import { css } from "@linaria/core";
import { ReactNode } from "react";


export function H1({ children }: { children: ReactNode }) {
	const h1Style = css`
		font-weight: 600;
		font-size: 1.4rem; 
		margin: 0 0 6px 0;
	`;

	return (
		<h1 className={h1Style}>
			{children}
		</h1>
	);
}
