import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "@linaria/core";
import classNames from "classnames";
import { motion } from "framer-motion";
import { useQuery } from "react-query";

import { Accordion } from "components/Accordion";
import { Image } from "components/Image";
import { WeonLoadingOverlay } from "components/LoadingOverlay";
import { Separator } from "components/Separator";
import { Garment, GarmentImage, Order } from "httpClient";
import { theme } from "theme";
import { generateGetOrganizationGarmentsQueryOptions } from "utils/react-query/data-fetching/garment";
import { generateGetOrganizationOrdersQueryOptions } from "utils/react-query/data-fetching/order";
import { ensureIsNotError } from "utils/react-query/ensureIsNotError";
import { isNotLoadingOrIdle } from "utils/react-query/isNotLoadingOrIdle";
import { imageUrl } from "utils/resourcePaths";

import { H1 } from "../(components)/H1";
import { MainContainer } from "../(components)/MainContainer";


export default function GarmentsPage() {
	const garmentsQuery = useQuery(generateGetOrganizationGarmentsQueryOptions());
	const ordersQuery = useQuery(generateGetOrganizationOrdersQueryOptions());

	ensureIsNotError(garmentsQuery);
	ensureIsNotError(ordersQuery);

	if (!isNotLoadingOrIdle(garmentsQuery) || !isNotLoadingOrIdle(ordersQuery))
		return <WeonLoadingOverlay />;

	// const buttonWrapperStyle = css`
	// 	color: ${theme.palette.teriary};
	// 	padding: 12px 0;
	// 	border-radius: 4px;
	// 	font-weight: 600;
	// 	cursor: pointer;

	// 	&:hover {
	// 		color: ${theme.palette.teriary};
	// 	}
	// `;

	// const buttonRowStyle = css`
	// 	display: flex;
	// 	gap: 20px;
	// `;

	const descriptionTextStyle = css`
			display: flex;
			align-items: center;
			padding: 2rem;
			line-height: 1.6em;
		`;

	return (
		<MainContainer>
			<H1>
				Browse garments
			</H1>
			<Separator space={20} />
			{/* <Hero
				imgSrc={heroGarmentsPngSrc}
				title="Garments"
				description="This is an overview of what garments have been uploaded and used in digital photoshoots.
				The images should ideally provided as ghost mannequin packshots.
				Each garment can be reused in multiple photoshoots, but the connected photoshoot is the original photoshoot of which it was added for."
				functionalElement={(
					<div className={buttonRowStyle}>
						<Button variant="normalized" href="/app">
							<div className={buttonWrapperStyle}>Help</div>
						</Button>
					</div>
				)}
			/> */}
			<div className={descriptionTextStyle}>

				<FontAwesomeIcon
					icon={faQuestionCircle}
					style={{ marginRight: 16 }}
					size="xl"
				/>
				Browse your uploaded garments here. You can reuse these in any future photoshoots.
			</div>
			<GarmentSection garments={garmentsQuery.data} orders={ordersQuery.data} />
		</MainContainer>
	);
}

function GarmentSection({ garments, orders }: { garments: Garment[], orders: Order[] }) {
	const sectionStyle = css`
		overflow: auto;
		flex: 1 1 auto;
		height: 0;
	`;

	const accordionWrapperStyle = css`
		margin-top: 20px;
		overflow-x: hidden;
		overflow-y: hidden;
	`;

	const accordionButtonStyle = css`
		cursor: pointer;
		display: flex;
		align-items: center;
		gap: 20px;
		padding: 12px;
		border-radius: 6px;

		transition: background-color 0.5s ${theme.timingFunctions.default};
	`;

	const accordionButtonChevronStyle = css`
		transition: transform 0.5s ${theme.timingFunctions.default};
	`;

	const accordionButtonChevronIsOpenStyle = css`
		transform: rotate(180deg);
	`;

	const accordionButtonTextStyle = css`
		margin: 0;
	`;

	const garmentsContainerStyle = css`
		padding-top: 10px;
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		overflow: hidden;
	`;

	return (
		<section className={sectionStyle}>
			{orders.sort((a, b) => {
				return new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime();
			}).filter(order => garments.some(garment => garment.createdForOrderId === order.orderId))
				.map(x => ({
					order: x,
					garmentImages: garments.filter(garment => garment.createdForOrderId === x.orderId).flatMap(x => Object.values(x.garmentImages).slice(0, 1)),
				})).map(x => {
					return (
						<div
							key={x.order.orderId}
							className={accordionWrapperStyle}
						>
							<Accordion
								startExpanded
								accordionButton={isOpen => (
									<div className={accordionButtonStyle}>
										<FontAwesomeIcon icon={faChevronDown} className={classNames(accordionButtonChevronStyle, { [accordionButtonChevronIsOpenStyle]: isOpen })} />
										<h3 className={accordionButtonTextStyle}>
											{x.order.name}
										</h3>
									</div>
								)}
							>
								<div
									className={garmentsContainerStyle}
								>
									{x.garmentImages.map(garmentImage => (
										<GarmentImageImage
											key={garmentImage.garmentImageId}
											garmentImage={garmentImage}
										/>
									))}
								</div>
							</Accordion>
						</div>
					);
				})}
			{/* <Table items={items} columns={columns} /> */}
		</section>
	);
}

interface IGarmentImageImageProps {
	garmentImage: GarmentImage,
}

function GarmentImageImage({ garmentImage }: IGarmentImageImageProps) {
	const imageOptionContainerStyle = css`
		width: 100px;
		height: calc(100px * 4/3);
		position: relative;
		cursor: pointer;
		border: 1px solid ${theme.semantic.border};
		transition: border 0.5s ${theme.timingFunctions.default};
		border-radius: 4px;
	
		&:hover {
			border: 1px solid ${theme.semantic.borderActive};
		}
	`;

	const imgStyle = css`
		object-fit: contain;
		transition: transform 0.5s ${theme.timingFunctions.default};
	`;

	return (
		<motion.div
			initial={{ width: 0, opacity: 0 }}
			animate={{ width: "auto", opacity: 1 }}
			exit={{ width: 0, opacity: 0 }}
		>
			<div
				key={garmentImage.garmentImageId}
			>
				<div
					className={imageOptionContainerStyle}
				>
					<Image className={imgStyle} src={imageUrl(garmentImage.originalGarmentImageKey)} alt="" fill showImageOptions />
				</div>
			</div>
		</motion.div>
	);
}
