import { css } from "@linaria/core";
import classNames from "classnames";
import { Key, ReactNode, useMemo, useRef } from "react";
import { AriaMenuProps, useMenuTrigger, useMenu, useMenuItem, useMenuSection, useSeparator } from "react-aria";
import { Item, MenuTriggerProps, Node, Section, TreeState, useMenuTriggerState, useTreeState } from "react-stately";

import { theme } from "theme";

import { Button, IButtonProps } from "./Button";
import { IPopoverPlacement, PopoverBase } from "./Popover";
import { Separator } from "./Separator";


interface IMenuItem {
	key: Key,
	onRender: (key: Key) => ReactNode,
	onClick?: () => void,
}

interface IMenuSection {
	key: Key,
	title?: string,
	items: IMenuItem[],
}

function isMenuSection(item: IMenuItem | IMenuSection): item is IMenuSection {
	return (item as IMenuSection).items !== undefined;
}


type IMenuButtonProps<T> = {
	items: (IMenuItem | IMenuSection)[],
	popoverPlacement?: IPopoverPlacement,
} & IInnerMenuButtonProps<T>;


export function MenuButton<T>({ items, popoverPlacement, ...props }: IMenuButtonProps<T>) {
	const flattenedItems = useMemo(() => {
		const arr: IMenuItem[] = [];

		const populateFlattenedItems = (x: IMenuItem | IMenuSection) => {
			if (isMenuSection(x)) {
				for (const item of x.items)
					populateFlattenedItems(item);
			} else {
				arr.push(x);
			}
		};

		for (const item of items)
			populateFlattenedItems(item);


		return arr;
	}, [items]);

	if (flattenedItems.length !== new Set(flattenedItems.map(x => x.key)).size)
		throw Error("Keys must be unique");


	const onClickMap = useMemo(() => {
		const keyToOnClick: { [key: Key]: (() => void) | undefined } = {};

		for (const item of flattenedItems)
			keyToOnClick[item.key] = item.onClick;

		return keyToOnClick;
	}, [flattenedItems]);

	return (
		<InnerMenuButton popoverPlacement={popoverPlacement} onAction={key => onClickMap[key]?.()} {...props} buttonChildren={props.children}>
			{items.map(x => (isMenuSection(x) ? (
				<Section key={x.key}>
					{x.items.map(x => <Item key={x.key} textValue={x.key.toString()}>{x.onRender(x.key)}</Item>)}
				</Section>
			) : <Item key={x.key} textValue={x.key.toString()}>{x.onRender(x.key)}</Item>))}
		</InnerMenuButton>
	);
}


type IInnerMenuButtonProps<T> = AriaMenuProps<T> & MenuTriggerProps & IButtonProps & {
	popoverPlacement?: IPopoverPlacement,
	buttonChildren?: ReactNode,
};

function InnerMenuButton<T extends object>(props: IInnerMenuButtonProps<T>) {
	// Create state based on the incoming props
	const state = useMenuTriggerState(props);

	// Get props for the button and menu elements
	const ref = useRef(null);
	const { menuTriggerProps, menuProps } = useMenuTrigger<T>({}, state, ref);

	return (
		<>
			<Button
				{...{ ...props, ...menuTriggerProps }}
				ref={ref}
			>
				{props.buttonChildren}
			</Button>
			{state.isOpen && (
				<PopoverBase state={state} triggerRef={ref} placement={props.popoverPlacement} offset={1}>
					<Menu
						{...props}
						{...menuProps}
					/>
				</PopoverBase>
			)}
		</>
	);
}


function Menu<T extends object>(props: AriaMenuProps<T>) {
	// Create menu state based on the incoming props
	const state = useTreeState(props);

	// Get props for the menu element
	const ref = useRef(null);
	const { menuProps } = useMenu(props, state, ref);

	const ulStyle = css`
		margin: 0;
		list-style: none;
		outline: none;
		padding: 4px 4px;
		border-radius: 4px;
		background-color: ${theme.semantic.contextOverlay};
		color: ${theme.semantic.contextOverlayForeground};
		backdrop-filter: saturate(180%) blur(5px);
	`;

	return (
		<ul
			{...menuProps}
			ref={ref}
			className={ulStyle}
		>
			{Array.from(state.collection).map(item => (
				item.type === "section"
					? <MenuSection key={item.key} section={item} state={state} />
					: <MenuItem key={item.key} item={item} state={state} />
			))}
		</ul>
	);
}

interface IMenuItemProps<T> {
	state: TreeState<T>,
	item: Node<T>,
	onAction?: (key: Key) => void,
	onClose?: () => void,
}


function MenuItem<T>({ item, state, onAction, onClose }: IMenuItemProps<T>) {
	// Get props for the menu item element
	const ref = useRef(null);

	const { menuItemProps, isFocused, isSelected, isDisabled } = useMenuItem(
		{ key: item.key, onAction, onClose },
		state,
		ref
	);

	const liStyle = css`
		outline: none;
		cursor: pointer;
		display: flex;
		justify-content: space-between;
		min-width: 180px;
		padding: 8px 16px;
		border-radius: 4px;		

		&:hover {
			background-color: ${theme.semantic.contextOverlayHover};
		}
	`;

	const liFocusedStyle = css`
		background-color: ${theme.semantic.contextOverlayHover};
	`;

	const liDisabledStyle = css`
		color: ${theme.semantic.disabledButtonForeground};
	`;

	return (
		<li
			{...menuItemProps}
			ref={ref}
			className={classNames(liStyle, { [liFocusedStyle]: isFocused, [liDisabledStyle]: isDisabled })}
		>
			{item.rendered}
			{isSelected && <span aria-hidden="true">✅</span>}
		</li>
	);
}

interface IMenuSectionProps<T> {
	state: TreeState<T>,
	section: Node<T>,
}

function MenuSection<T>({ section, state }: IMenuSectionProps<T>) {
	const { itemProps, headingProps, groupProps } = useMenuSection({
		"heading": section.rendered,
		"aria-label": section["aria-label"],
	});

	const { separatorProps } = useSeparator({
		elementType: "li",
	});

	const separatorWrapperStyle = css`
		padding: 2px 8px;
	`;

	const spanStyle = css`
		font-weight: bold;
		font-size: 1.1em;
		padding: 2px 5px;
	`;

	const ulStyle = css`
		padding: 0;
		list-style: none;
	`;

	// If the section is not the first, add a separator element.
	// The heading is rendered inside an <li> element, which contains
	// a <ul> with the child items.
	return (
		<>
			{section.key !== state.collection.getFirstKey() && (
				<li
					{...separatorProps}
					className={separatorWrapperStyle}
				>
					<Separator space={5} />
				</li>
			)}
			<li {...itemProps}>
				{section.rendered && (
					<span
						{...headingProps}
						className={spanStyle}
					>
						{section.rendered}
					</span>
				)}
				<ul
					{...groupProps}
					className={ulStyle}
				>
					{Array.from(section.childNodes).map(node => (
						<MenuItem
							key={node.key}
							item={node}
							state={state}
						/>
					))}
				</ul>
			</li>
		</>
	);
}
