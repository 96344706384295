import { faCameraRetro, faHome, faPeopleGroup, faShirt } from "@fortawesome/free-solid-svg-icons";
import { css } from "@linaria/core";
import { ReactNode } from "react";

import { ErrorBoundary } from "components/ErrorBoundary";
import Main from "components/Main";
import { AppPageErrorContent } from "pages/error";

import Footer from "./Footer";
import { INavLinkGroup, NavSidebar } from "./NavSidebar";


interface ILayoutProps {
	children: ReactNode,
}

export default function AppLayout({ children }: ILayoutProps) {
	const containerStyle = css`
		display: flex;
		height: calc(100% - 0px);
	`;

	const navSiderbarWrapperStyle = css`
		position: sticky;
		display: flex;
		flex-direction: column;
	`;

	const navMiddleStyle = css`
		flex: 1;
	`;

	const navLinkGroup: INavLinkGroup[] = [
		{
			name: "Home",
			href: "/app",
			icon: faHome,
		},
		{
			name: "Photoshoots",
			icon: faCameraRetro,
			links: [
				{
					name: "Create",
					href: "/app/photoshoots/create",
				},
				{
					name: "Browse",
					href: "/app/photoshoots/browse",
				},
			],
		},
		{
			name: "Garments",
			href: "/app/garments",
			icon: faShirt,
		},
		{
			name: "Models",
			href: "/app/models",
			icon: faPeopleGroup,
		},
	];

	return (
		<>
			<div className={containerStyle}>
				<div className={navSiderbarWrapperStyle}>
					<div className={navMiddleStyle}>
						<NavSidebar groups={navLinkGroup} />
					</div>
					<Footer />
				</div>
				<Main>
					<ErrorBoundary
						onErrorRender={
							({ error, reload }) => <AppPageErrorContent error={error as Error} reset={reload} />
						}
					>
						{children}
					</ErrorBoundary>
				</Main>
			</div>
		</>
	);
}
