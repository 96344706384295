import { yupResolver } from "@hookform/resolvers/yup";
import { css } from "@linaria/core";
import * as yup from "yup";

import { Button } from "components/Button";
import { Image } from "components/Image";
import { FormComponents } from "components/formComponents/FormComponents";
import { useForm } from "components/formComponents/useForm";
import { useAuth } from "components/providers/AuthProvider";
import { ApiError, extractApiErrorDetail } from "httpClient";

import weonLogoBlackSrc from "../../../../../public/weon-logo-black.png";


export function LoginForm() {
	const { login } = useAuth();

	const schema = yup.object({
		email: yup.string().required("Email is a required field.").email("Email is not in a valid format."),
		password: yup.string().required("Password is a required field."),
	});

	const formContext = useForm<yup.InferType<typeof schema>>({
		onSubmit: async data => {
			await login(data.email, data.password).catch((e: ApiError) => {
				formContext.setError("password", {
					message: (extractApiErrorDetail(e) ?? e).message,
				});
			});
		},
		resolver: yupResolver(schema),
		mode: "onSubmit",
	});


	const loginFormWrapperStyle = css`
		display: flex;
		justify-content: start;
		align-items: center;
		flex-direction: column;
		gap: 50px;
		position: relative;
	`;

	const forgotPasswordWrapperStyle = css`
		margin-top: 5px;
		display: flex;
		font-size: 11px;
	`;

	return (
		<div className={loginFormWrapperStyle}>
			<Image alt="weon logo" src={weonLogoBlackSrc} width={200} height="auto" />
			<FormComponents.Form state={formContext} sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
				<FormComponents.TextField placeholder="Email" name="email" type="email" />
				<FormComponents.TextField placeholder="Password" name="password" type="password" />
				<FormComponents.Submit large>Log in</FormComponents.Submit>
				<div className={forgotPasswordWrapperStyle}><Button href="/auth/login/forgot-password" variant="link">Forgot password?</Button></div>
			</FormComponents.Form>
		</div>
	);
}
