/* eslint-disable @typescript-eslint/indent */
import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { DefaultService, User, Body_login_api_auth_token_post, ApiError, isHandledApiError } from "httpClient";
import { invariant } from "utils/invariant";


export type IUserState = {
	user: User,
	isAuthenticated: true,
	isLoading: false,
} | {
	user: undefined,
	isAuthenticated: false,
	isLoading: false,
} | {
	user: undefined,
	isAuthenticated: false,
	isLoading: true,
};

type IAuthContext = {
	login: (username: string, password: string, redirectTo?: string) => Promise<void>,
	logout: () => void,
	fetchUser: () => void,
} & IUserState;

const AuthContext = createContext<IAuthContext | undefined>(undefined);


export function AuthProvider({ children }: { children?: ReactNode }) {
	const [hasFetchedUser, setHasFetchedUser] = useState(false);

	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const logoutQuery = useMutation(
		"logout",
		() => DefaultService.logoutApiAuthRemoveTokenPost(),
		{
			retry: false,
			onSuccess: () => queryClient.clear(),
		}
	);

	const userQuery = useQuery(["me"], () => DefaultService.readUsersMeApiUsersMeGet(), {
		retry: false,
		useErrorBoundary: (error: ApiError) => !isHandledApiError(error, 401),
		enabled: !hasFetchedUser,
		onSettled: () => setHasFetchedUser(true),
	});

	const fetchUser = useCallback(() => void userQuery.refetch(), [userQuery]);

	const loginMutation = useMutation(
		"login",
		(credentials: Body_login_api_auth_token_post) => DefaultService.loginApiAuthTokenPost(credentials),
		{
			retry: false,
			onSuccess: () => {
				fetchUser();
			},
			useErrorBoundary: (error: ApiError) => !isHandledApiError(error, 401),
		}
	);

	const login = useCallback(async (username: string, password: string, redirectTo?: string) => {
		// retrieve token
		await loginMutation.mutateAsync({ username, password });

		navigate(redirectTo ?? "/app");
	}, [loginMutation, navigate]);

	const logout = useCallback(() => {
		logoutQuery.mutate();
		userQuery.remove();
		navigate("/auth/login");
	}, [logoutQuery, navigate, userQuery]);

	const userState: IUserState = useMemo(() => {
		if (userQuery.data) {
			return {
				user: userQuery.data,
				isAuthenticated: true,
				isLoading: false,
			};
		} else if (userQuery.isLoading) {
			return {
				user: undefined,
				isAuthenticated: false,
				isLoading: true,
			};
		} else {
			return {
				user: undefined,
				isAuthenticated: false,
				isLoading: false,
			};
		}
	}, [userQuery.data, userQuery.isLoading]);

	const context: IAuthContext = useMemo(() => ({
		...userState,
		login,
		logout,
		fetchUser,
	}), [fetchUser, login, logout, userState]);

	return (
		<AuthContext.Provider value={context}>
			{children}
		</AuthContext.Provider>
	);
}

export const useAuth = () => {
	const value = useContext(AuthContext);
	invariant(value, "useAuth should only be used within the context of an AuthProvider");
	return value;
};
