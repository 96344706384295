/* eslint-disable react/forbid-component-props */
// TODO

import { faX } from "@fortawesome/free-solid-svg-icons";
import { css } from "@linaria/core";
import classNames from "classnames";
import debounce from "lodash/debounce";
import { useEffect, useMemo, useRef, useState } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

import { theme } from "theme";

import { Button } from "./Button";
import { Modal } from "./Modal";
import { PopoverBase } from "./Popover";


type IImageProps = {
	showImageOptions?: boolean,
	hideShimmer?: boolean,
	src: string,
	className?: string,
	alt: string,
	onError?: () => void,
	draggable?: boolean,
	style?: React.CSSProperties,
} & (
	{
		fill: boolean,
		height?: undefined,
		width?: undefined,
	} | {
		fill?: undefined,
		height: number | string,
		width: number | string,
	}
);

export function Image({
	src,
	showImageOptions,
	hideShimmer,
	fill,
	onError,
	...props
}: IImageProps) {
	const [isLoading, setIsLoading] = useState(true);
	const [inspectImageIsLoading, setInspectImageIsLoading] = useState(true);
	const [imgSrc, setImgSrc] = useState(src);

	const inspectImgSrc = useMemo(() => {
		return imgSrc.split("?")[0] ?? imgSrc;
	}, [imgSrc]);

	useEffect(() => {
		setImgSrc(src);
	}, [src]);

	const [isOpen, setIsOpen] = useState(false);
	const ref = useRef(null);

	const setIsOpenDebounced = debounce((val: boolean) => setIsOpen(!!showImageOptions && val), 500);

	const loadingStyle = css`
		background: ${theme.semantic.skeleton};
		background-image: linear-gradient(to right, ${theme.semantic.skeleton} 0%, ${theme.semantic.skeletonShimmer} 20%, ${theme.semantic.skeleton} 40%, ${theme.semantic.skeleton} 100%);
		background-repeat: no-repeat;
		background-size: 800px 100%; 
		display: inline-block;
		position: relative; 
		
		animation-duration: 1.25s;
		animation-fill-mode: forwards; 
		animation-iteration-count: infinite;
		animation-name: shimmer;
		animation-timing-function: linear;

		@keyframes shimmer {
			0% {
				background-position: 100% 0;
			}
			
			100% {
				background-position: -100% 0; 
			}
		}
	`;

	const containerStyle = css`
		margin: 0;
		border: 1px solid ${theme.semantic.border};
		outline: none;
		padding: 8px 8px;
		border-radius: 6px;
		background-color: ${theme.semantic.card};
	`;

	const modalRef = useRef<HTMLDialogElement>(null);

	const toolbarContainerStyle = css`
		display: flex;
		justify-content: flex-end;
		padding: 20px;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 2;
	`;

	const toolbarStyle = css`
		background-color: ${theme.semantic.card};
		padding: 8px;
		border-radius: 12px;
		color: ${theme.semantic.foreground};
	`;

	const fullImgStyle = css`
		border-radius: 6px;
		object-fit: scale-down;
		position: absolute;
		height: 100%;
		width: 100%;
		inset: 0px;
		color: transparent;
	`;

	const inspectImageLoadingStyle = css`
		background: ${theme.semantic.skeleton};
		background-image: linear-gradient(to right, ${theme.semantic.skeleton} 0%, ${theme.semantic.skeletonShimmer} 20%, ${theme.semantic.skeleton} 40%, ${theme.semantic.skeleton} 100%);
		background-repeat: no-repeat;
		background-size: 800px 100%; 
		display: inline-block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		height: 400px;
		width: 300px;
		
		animation-duration: 1.25s;
		animation-fill-mode: forwards; 
		animation-iteration-count: infinite;
		animation-name: shimmer;
		animation-timing-function: linear;

		@keyframes shimmer {
			0% {
				background-position: 100% 0;
			}
			
			100% {
				background-position: -100% 0; 
			}
		}
	`;

	const fullImgContainerStyle = css`
		position: relative;
		height: 100vh;
		width: 100vw;
	`;

	const fillStyle = css`
		position: absolute;
		height: 100%;
		width: 100%;
		inset: 0px;
		color: transparent;
	`;

	const tipOverlayStyle = css`
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: ${theme.semantic.underlay};
		animation: show-on-entry 0.2s forwards;
		animation-delay: 1.5s;
		position: absolute;
		inset: 0;
		z-index: 1;
		font-size: 26px;
		font-weight: 600;
		color: ${theme.semantic.underlayForeground};
		pointer-events: none;

		@keyframes show-on-entry {
			from {
				opacity: 1;
			}	
			to {
				opacity: 0;
			}
		};
	`;


	return (
		<>
			<img
				ref={ref}
				{...props}
				src={imgSrc}
				onLoad={() => setIsLoading(false)}
				onMouseEnter={() => setIsOpenDebounced(true)}
				onMouseLeave={() => setIsOpenDebounced(false)}
				className={classNames(props.className, { [loadingStyle]: !hideShimmer && isLoading, [fillStyle]: fill })}
				onError={() => {
					onError?.();
					// setImgSrc("/TODO ERROR IMAGE");
				}}

			/>
			{showImageOptions && (
				<>
					<Modal ref={modalRef} closeOnBackdropClick={false}>
						{(isOpen, close) => (
							isOpen && (
								<>
									<div className={toolbarContainerStyle}>
										<div className={toolbarStyle}>
											<Button variant="flat" leftIconProps={{ icon: faX }} onPress={() => close()} />
										</div>
									</div>
									<TransformWrapper
										minScale={0.1}
										maxScale={30}
										centerOnInit
										limitToBounds
										panning={{
											activationKeys: ["Control", "Alt"],
										}}
										wheel={{
											activationKeys: ["Control", "Alt"],
										}}
										doubleClick={{
											disabled: true,
										}}
									>
										<TransformComponent
											wrapperProps={{
												onClick: e => {
													e.preventDefault();
													e.stopPropagation();
												},
											}}
											wrapperStyle={{
												width: "100vw",
												height: "100vh",
												cursor: "grab",
												overflow: "visible",
												position: "relative",
											}}
										>
											<div className={tipOverlayStyle}>Use Ctrl or Alt to pan/zoom</div>
											<div className={fullImgContainerStyle}>
												<img
													src={inspectImgSrc}
													onLoad={() => setInspectImageIsLoading(false)}
													className={classNames(fullImgStyle, { [inspectImageLoadingStyle]: inspectImageIsLoading })}
													alt={props.alt}
												/>
											</div>
										</TransformComponent>
									</TransformWrapper>
								</>
							)
						)}
					</Modal>
					{isOpen && (
						<>
							<PopoverBase
								triggerRef={ref}
								placement="right"
								offset={10}
								withUnderlay={false}
								isNonModal
								state={{
									isOpen: isOpen,
									close: () => null,
									open: () => null,
									setOpen: () => null,
									toggle: () => null,
								}}
							>
								<div
									onMouseEnter={() => setIsOpenDebounced(true)}
									onMouseLeave={() => setIsOpenDebounced(false)}
								>
									<div
										className={containerStyle}
									>
										<Button variant="flat" size="sm" onPress={() => modalRef.current?.showModal()}>Inspect</Button>
									</div>
								</div>
							</PopoverBase>
						</>
					)}
				</>
			)}

		</>
	);
}
