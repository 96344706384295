import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "@linaria/core";
import { useQuery } from "react-query";

import { Image } from "components/Image";
import { WeonLoadingOverlay } from "components/LoadingOverlay";
import { Separator } from "components/Separator";
import { IColumn, Table } from "components/Table";
import { Person } from "httpClient";
import { generateGetOrganizationPersonsQueryOptions } from "utils/react-query/data-fetching/person";
import { ensureIsNotError } from "utils/react-query/ensureIsNotError";
import { isNotLoadingOrIdle } from "utils/react-query/isNotLoadingOrIdle";
import { imageUrl } from "utils/resourcePaths";

import { H1 } from "../(components)/H1";
import { MainContainer } from "../(components)/MainContainer";


export default function ModelsPage() {
	const personsQuery = useQuery(generateGetOrganizationPersonsQueryOptions());

	ensureIsNotError(personsQuery);

	if (!isNotLoadingOrIdle(personsQuery))
		return <WeonLoadingOverlay />;

	// const buttonWrapperStyle = css`
	// 	color: ${theme.palette.teriary};
	// 	padding: 12px 0;
	// 	border-radius: 4px;
	// 	font-weight: 600;
	// 	cursor: pointer;

	// 	&:hover {
	// 		color: ${theme.palette.teriary};
	// 	}
	// `;

	// const buttonRowStyle = css`
	// 	display: flex;
	// 	gap: 20px;
	// `;

	const descriptionTextStyle = css`
			display: flex;
			align-items: center;
			padding: 2rem;
			line-height: 1.6em;
		`;

	return (
		<MainContainer>
			<H1>
				Browse models
			</H1>
			<Separator space={20} />
			{/* <Hero
				imgSrc={heroMannequinPngSrc}
				title="Models"
				description="This view showcases the available models for you and your organization.
				You can utilize these model images to create visually appealing digital photoshoots by incorporating your provided garments."
				functionalElement={(
					<div className={buttonRowStyle}>
						<Button variant="normalized" href="/app">
							<div className={buttonWrapperStyle}>Help</div>
						</Button>
					</div>
				)}
			/> */}
			<div className={descriptionTextStyle}>
				<FontAwesomeIcon
					icon={faQuestionCircle}
					style={{ marginRight: 16 }}
					size="xl"
				/>
				Browse available models here. You can use these in any of your photoshoots.
			</div>
			<PersonSection persons={personsQuery.data} />
		</MainContainer>
	);
}


function PersonSection({ persons }: { persons: Person[] }) {
	const items = persons.map(x => ({ ...x, id: x.personId }));

	const imageRowContainerStyle = css`
		display: flex;
		gap: 12px;
	`;

	const imgWrapperStyle = css`
		position: relative;
		width: 100px;
		height: calc(100px * 4/3);
		border-radius: 4px;
		overflow: hidden;
	`;

	const imgStyle = css`
		object-fit: contain;
	`;

	const missingImgStyle = css`
		border: 1px solid gray;
		height: 100%;
	`;

	const columns: IColumn<typeof items[number]>[] = [
		{
			key: "personImages",
			name: "Images",
			minWidth: 100,
			onRender: item => (
				<div className={imageRowContainerStyle}>
					{item.personImages.map(x => (
						<div key={x.personImageId} className={imgWrapperStyle}>
							{x.clothedPersonImageKey ? <Image src={imageUrl(x.clothedPersonImageKey, "xs")} alt="" fill className={imgStyle} showImageOptions />
							: <div className={missingImgStyle}></div>}
						</div>
					))}
				</div>
			),
		},
		{
			key: "sex",
			name: "Sex",
			minWidth: 100,
			onRender: item => item.sex,
		},
	];

	const sectionStyle = css`
		overflow: auto;
		flex: 1 1 auto;
		height: 0;
	`;

	return (
		<section className={sectionStyle}>
			<Table columns={columns} items={items} />
		</section>
	);
}
