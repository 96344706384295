import { useMemo } from "react";
import { useController, useFormContext } from "react-hook-form";

import { IFormFieldProps } from "./types";
import { Select as SelectComponent, ISelectProps } from "../Select";


export function Select<T>(props: IFormFieldProps<ISelectProps<T>>) {
	const {
		name,
		isDisabled = false,
		...rest
	} = props;

	const keyToOption = useMemo(() => Object.fromEntries(props.options.map(x => ([x.key, x]))), [props.options]);

	const { control, formState: { isSubmitting } } = useFormContext();

	const {
		field: {
			// Legacy
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			onChange, name: fieldName, value,
		},
		fieldState: { error },
	} = useController({
		name, control,
	});


	return (
		<SelectComponent
			{...rest}
			name={fieldName}
			// Legacy
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			selectedKey={props.options.find(x => x.data === value)?.key}
			isDisabled={isDisabled || isSubmitting}
			errorMessage={error?.message}
			validationState={error ? "invalid" : "valid"}
			onSelectionChange={key => {
				onChange(keyToOption[key]?.data);
			}}
		/>
	);
}
