import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "@linaria/core";


interface IWizardDescriptionProps {
	descriptionIconProp?: IconProp,
	descriptionText?: string,
}

export function WizardDescription({ descriptionIconProp, descriptionText }: IWizardDescriptionProps) {
	const descriptionTextStyle = css`
		display: flex;
		align-items: center;
		padding: 1rem 0rem 0rem 0rem;
		line-height: 1.6em;
	`;

	return (
		<>
			{descriptionText && (
				<div className={descriptionTextStyle}>
					{descriptionIconProp && (
						<FontAwesomeIcon
							icon={descriptionIconProp}
							style={{ marginRight: 16 }}
							size="xl"
						/>
					)}
					{descriptionText}
				</div>
			)}
		</>
	);
}
