/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_create_garment_api_garments_create_garment_post } from '../models/Body_create_garment_api_garments_create_garment_post';
import type { Body_login_api_auth_token_post } from '../models/Body_login_api_auth_token_post';
import type { BodySection } from '../models/BodySection';
import type { Categories } from '../models/Categories';
import type { CategoryName } from '../models/CategoryName';
import type { Country } from '../models/Country';
import type { Garment } from '../models/Garment';
import type { GarmentUpdate } from '../models/GarmentUpdate';
import type { ImageOrientation } from '../models/ImageOrientation';
import type { ImageSize } from '../models/ImageSize';
import type { Order } from '../models/Order';
import type { OrderCommit } from '../models/OrderCommit';
import type { OrderRow } from '../models/OrderRow';
import type { OrderRowResultUpdate } from '../models/OrderRowResultUpdate';
import type { OrderRowSave } from '../models/OrderRowSave';
import type { OrderUpdate } from '../models/OrderUpdate';
import type { Organization } from '../models/Organization';
import type { OrganizationUserInvitationCreation } from '../models/OrganizationUserInvitationCreation';
import type { Person } from '../models/Person';
import type { Role } from '../models/Role';
import type { Sex } from '../models/Sex';
import type { User } from '../models/User';
import type { UserCreation } from '../models/UserCreation';
import type { UserInvitation } from '../models/UserInvitation';
import type { UserPasswordChange } from '../models/UserPasswordChange';
import type { UserSelfUpdate } from '../models/UserSelfUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultService {

    /**
     * Get Organization Persons
     * @returns Person Successful Response
     * @throws ApiError
     */
    public static getOrganizationPersonsApiPersonsGetOrganizationPersonsGet(): CancelablePromise<Array<Person>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/persons/get-organization-persons',
        });
    }

    /**
     * Get Organization Garments
     * @returns Garment Successful Response
     * @throws ApiError
     */
    public static getOrganizationGarmentsApiGarmentsGetOrganizationGarmentsGet(): CancelablePromise<Array<Garment>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/garments/get-organization-garments',
        });
    }

    /**
     * Get Garment By External Garment Id
     * @param externalGarmentId
     * @returns Garment Successful Response
     * @throws ApiError
     */
    public static getGarmentByExternalGarmentIdApiGarmentsGetGarmentByExternalGarmentIdExternalGarmentIdGet(
        externalGarmentId: string,
    ): CancelablePromise<Garment> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/garments/get-garment-by-external-garment-id/{external_garment_id}',
            path: {
                'external_garment_id': externalGarmentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Garment
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createGarmentApiGarmentsCreateGarmentPost(
        formData: Body_create_garment_api_garments_create_garment_post,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/garments/create-garment',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Garment
     * @param garmentId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateGarmentApiGarmentsUpdateGarmentGarmentIdPut(
        garmentId: string,
        requestBody: GarmentUpdate,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/garments/update-garment/{garment_id}',
            path: {
                'garment_id': garmentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Garment
     * @param garmentId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteGarmentApiGarmentDeleteGarmentGarmentIdDelete(
        garmentId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/garment/delete-garment/{garment_id}',
            path: {
                'garment_id': garmentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Categories
     * @returns Categories Successful Response
     * @throws ApiError
     */
    public static getCategoriesApiGetCategoriesGet(): CancelablePromise<Categories> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/get-categories',
        });
    }

    /**
     * Read Users Me
     * @returns User Successful Response
     * @throws ApiError
     */
    public static readUsersMeApiUsersMeGet(): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users/me',
        });
    }

    /**
     * Register
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static registerApiUsersRegisterPost(
        requestBody: UserCreation,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/register',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create User Password Reset
     * @param email
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createUserPasswordResetApiUsersCreateUserPasswordResetPost(
        email: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/create-user-password-reset',
            query: {
                'email': email,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Change Password
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static changePasswordApiUsersChangePasswordPost(
        requestBody: UserPasswordChange,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/change-password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update User
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateUserApiUsersUpdateUserPost(
        requestBody: UserSelfUpdate,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/update-user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Users By Organization
     * @returns User Successful Response
     * @throws ApiError
     */
    public static getUsersByOrganizationApiUsersGetUsersByOrganizationGet(): CancelablePromise<Array<User>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users/get-users-by-organization',
        });
    }

    /**
     * Invite Organization User
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static inviteOrganizationUserApiUsersInviteOrganizationUserPost(
        requestBody: OrganizationUserInvitationCreation,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/invite-organization-user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Revoke Organization User Invitation
     * @param userInvitationId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static revokeOrganizationUserInvitationApiUsersRevokeOrganizationUserInvitationUserInvitationIdDelete(
        userInvitationId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/users/revoke-organization-user-invitation/{user_invitation_id}',
            path: {
                'user_invitation_id': userInvitationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Organization User Invitations
     * @returns UserInvitation Successful Response
     * @throws ApiError
     */
    public static getOrganizationUserInvitationsApiUsersGetOrganizationUserInvitationsGet(): CancelablePromise<Array<UserInvitation>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users/get-organization-user-invitations',
        });
    }

    /**
     * Login
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    public static loginApiAuthTokenPost(
        formData: Body_login_api_auth_token_post,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/token',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Logout
     * @returns any Successful Response
     * @throws ApiError
     */
    public static logoutApiAuthRemoveTokenPost(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/remove-token',
        });
    }

    /**
     * Create Order
     * @returns string Successful Response
     * @throws ApiError
     */
    public static createOrderApiOrdersCreateOrderPost(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/orders/create-order',
        });
    }

    /**
     * Get Order
     * @param orderId
     * @returns Order Successful Response
     * @throws ApiError
     */
    public static getOrderApiOrdersOrderIdGetOrderGet(
        orderId: string,
    ): CancelablePromise<Order> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/orders/{order_id}/get-order',
            path: {
                'order_id': orderId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Organization Orders
     * @returns Order Successful Response
     * @throws ApiError
     */
    public static getOrganizationOrdersApiOrdersGetOrganizationOrdersGet(): CancelablePromise<Array<Order>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/orders/get-organization-orders',
        });
    }

    /**
     * Get Order Row
     * @param orderRowId
     * @returns OrderRow Successful Response
     * @throws ApiError
     */
    public static getOrderRowApiOrderRowsOrderRowIdGetOrderRowGet(
        orderRowId: string,
    ): CancelablePromise<OrderRow> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/order-rows/{order_row_id}/get-order-row',
            path: {
                'order_row_id': orderRowId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Review Order Row Result
     * @param orderRowResultId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static reviewOrderRowResultApiOrderRowResultsOrderRowResultIdReviewPut(
        orderRowResultId: string,
        requestBody: OrderRowResultUpdate,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/order-row-results/{order_row_result_id}/review',
            path: {
                'order_row_result_id': orderRowResultId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Order
     * @param orderId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteOrderApiOrdersOrderIdDeleteOrderDelete(
        orderId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/orders/{order_id}/delete-order',
            path: {
                'order_id': orderId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Save Order Rows
     * @param orderId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static saveOrderRowsApiOrdersOrderIdSaveOrderRowsPut(
        orderId: string,
        requestBody: Array<OrderRowSave>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/orders/{order_id}/save-order-rows',
            path: {
                'order_id': orderId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Commit Order
     * @param orderId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static commitOrderApiOrdersOrderIdCommitOrderPut(
        orderId: string,
        requestBody: OrderCommit,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/orders/{order_id}/commit-order',
            path: {
                'order_id': orderId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Order
     * @param orderId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateOrderApiOrdersOrderIdUpdateOrderPut(
        orderId: string,
        requestBody: OrderUpdate,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/orders/{order_id}/update-order',
            path: {
                'order_id': orderId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Result Image File
     * @param orderRowResultId
     * @param asAttachement
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getResultImageFileApiOrderRowResultsOrderRowResultIdGetResultImageFileGet(
        orderRowResultId: string,
        asAttachement?: (boolean | null),
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/order-row-results/{order_row_result_id}/get-result-image-file',
            path: {
                'order_row_result_id': orderRowResultId,
            },
            query: {
                'as_attachement': asAttachement,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Result Image File
     * @param orderRowResultId
     * @param asAttachement
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getResultImageFileApiOrderRowResultsOrderRowResultIdGetResultImageFileFilenameGet(
        orderRowResultId: string,
        asAttachement?: (boolean | null),
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/order-row-results/{order_row_result_id}/get-result-image-file/{filename}',
            path: {
                'order_row_result_id': orderRowResultId,
            },
            query: {
                'as_attachement': asAttachement,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Organization
     * @returns Organization Successful Response
     * @throws ApiError
     */
    public static getOrganizationApiOrganizationsGetOrganizationGet(): CancelablePromise<Organization> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/organizations/get-organization',
        });
    }

    /**
     * Get Sexes
     * @returns Sex Successful Response
     * @throws ApiError
     */
    public static getSexesApiConstantsGetSexesGet(): CancelablePromise<Array<Sex>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/constants/get-sexes',
        });
    }

    /**
     * Get Body Sections
     * @returns BodySection Successful Response
     * @throws ApiError
     */
    public static getBodySectionsApiConstantsGetBodySectionsGet(): CancelablePromise<Array<BodySection>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/constants/get-body-sections',
        });
    }

    /**
     * Get Image Orientations
     * @returns ImageOrientation Successful Response
     * @throws ApiError
     */
    public static getImageOrientationsApiConstantsGetImageOrientationsGet(): CancelablePromise<Array<ImageOrientation>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/constants/get-image-orientations',
        });
    }

    /**
     * Get Category Names
     * @returns CategoryName Successful Response
     * @throws ApiError
     */
    public static getCategoryNamesApiConstantsGetCategoryNamesGet(): CancelablePromise<Array<CategoryName>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/constants/get-category-names',
        });
    }

    /**
     * Get Countries
     * @returns Country Successful Response
     * @throws ApiError
     */
    public static getCountriesApiConstantsGetCountiresGet(): CancelablePromise<Array<Country>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/constants/get-countires',
        });
    }

    /**
     * Get User Roles
     * @returns Role Successful Response
     * @throws ApiError
     */
    public static getUserRolesApiConstantsGetUserRolesGet(): CancelablePromise<Array<Role>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/constants/get-user-roles',
        });
    }

    /**
     * Get Image
     * @param key
     * @param size
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getImageApiImagesKeyGet(
        key: string,
        size?: (ImageSize | null),
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/images/{key}',
            path: {
                'key': key,
            },
            query: {
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Catch All
     * @param fullPath
     * @returns any Successful Response
     * @throws ApiError
     */
    public static catchAllFullPathGet(
        fullPath: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/{full_path}',
            path: {
                'full_path': fullPath,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
