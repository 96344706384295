import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "@linaria/core";
import classNames from "classnames";
import { FocusEvent, ReactNode, useRef } from "react";
import { useLocale, useNumberField, useButton } from "react-aria";
import { useNumberFieldState } from "react-stately";

import { theme } from "theme";

import { IValidationState } from "./formComponents/types";


export interface INumericFieldProps {
	id?: string,
	label?: string,
	value?: number,
	setValue?: (value: number) => void,
	validationState?: IValidationState,
	isRequired?: boolean,
	isReadOnly?: boolean,
	isDisabled?: boolean,
	errorMessage?: string,
	name?: string,
	onChange?: (value: number) => void,
	onBlur?: (value: FocusEvent<Element, Element>) => void,
}


export function NumericField(props: INumericFieldProps) {
	const { locale } = useLocale();
	const state = useNumberFieldState({ ...props, locale });
	const inputRef = useRef<HTMLInputElement>(null);

	const {
		labelProps,
		groupProps,
		inputProps,
		incrementButtonProps,
		decrementButtonProps,
		errorMessageProps,
	} = useNumberField(props, state, inputRef);

	const errorMessageStyle = css`
		margin-top: 5px;
		color: ${theme.semantic.errorColor};
		font-size: 12px;
		word-break: break-all;
		word-wrap: break-word;
		min-width: 100%;
    	width: 0;

		height: 0;
		opacity: 0;

		@keyframes entry {

			to {
				height: auto;
				opacity: 1;
			}
		}

		animation: entry 100ms forwards;
	`;

	const buttonContainerStyle = css`
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		width: 19px;
	`;

	const inputStyle = css`
		border: 1px solid ${theme.semantic.fieldBorder};
		background-color: ${theme.semantic.field};
		padding: 0px 8px;
		border-top-left-radius: 6px;
		border-bottom-left-radius: 6px;
		outline-color: ${theme.semantic.fieldOutline};
		transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;

		&:hover {
			background-color: ${theme.semantic.fieldHover};
			border: 1px solid ${theme.semantic.fieldBorderHover};
		}

	`;

	const inputInvalidStyle = css`
		&, &:hover, &:focus, &:active {
			border-color: ${theme.semantic.errorColor};
			outline-color: ${theme.semantic.errorColor};
		}
	`;

	const labelStyle = css`
		padding: 5px 0;
		font-size: 14px;
		font-weight: 600;
		display: inline-block;
	`;

	const labelIsRequiredStyle = css`
		&::after {
			content: " *";
			color: ${theme.semantic.requiredAsterisk};
			padding-right: 12px;
		}
	`;

	const groupStyle = css`
		display: flex;
	`;

	return (
		<div>
			<label {...labelProps} className={classNames(labelStyle, { [labelIsRequiredStyle]: props.isRequired })}>{props.label}</label>
			<div {...groupProps} className={groupStyle}>
				<input {...inputProps} ref={inputRef} className={classNames(inputStyle, { [inputInvalidStyle]: props.validationState === "invalid" })} />
				<div className={buttonContainerStyle}>
					<NumericFieldButton {...incrementButtonProps} isIncrementButton validationState={props.validationState}><FontAwesomeIcon icon={faChevronUp} size="xs" /></NumericFieldButton>
					<NumericFieldButton {...decrementButtonProps} validationState={props.validationState}><FontAwesomeIcon icon={faChevronDown} size="xs" /></NumericFieldButton>
				</div>
			</div>
			{props.errorMessage && (
				<div {...errorMessageProps} className={errorMessageStyle}>
					{props.errorMessage}
				</div>
			)}
		</div>
	);
}


interface INumericFieldButtonProps {
	isDisabled?: boolean,
	children: ReactNode,
	isIncrementButton?: boolean,
	validationState?: IValidationState,
}

function NumericFieldButton(props: INumericFieldButtonProps) {
	const ref = useRef<HTMLButtonElement>(null);
	const { buttonProps } = useButton(props, ref);

	const buttonStyle = css`
		padding: 0;
		border: 1px solid ${theme.semantic.fieldBorder};
		width: 100%;
		cursor: pointer;
		background-color: ${theme.semantic.accessoryButton};
		max-height: 16px;

		&:hover {
			background-color: ${theme.semantic.accessoryButtonHover};
		}
	`;

	const incrementButtonStyle = css`
		border-top-right-radius: 6px;
		border-bottom: none;
	`;

	const decrementButtonStyle = css`
		border-bottom-right-radius: 6px;
	`;

	const buttonIsInvalidStyle = css`
		border-color: ${theme.semantic.errorColor};
		outline-color: ${theme.semantic.errorColor};
	`;

	return <button type="button" {...buttonProps} ref={ref} className={classNames(buttonStyle, props.isIncrementButton ? incrementButtonStyle : decrementButtonStyle, { [buttonIsInvalidStyle]: props.validationState === "invalid" })}>{props.children}</button>;
}
