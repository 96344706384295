import { yupResolver } from "@hookform/resolvers/yup";
import { css } from "@linaria/core";
import noop from "lodash/noop";
import { useMemo } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { MessageBar } from "components/MessageBar";
import { toastQueue } from "components/Toast";
import { FormComponents } from "components/formComponents/FormComponents";
import { useForm } from "components/formComponents/useForm";
import { ApiError, DefaultService, OrganizationUserInvitationCreation, extractApiErrorDetail, isHandledApiError } from "httpClient";
import { fieldIsRequiredMessage } from "utils/fieldIsRequiredMessage";


function useInviteOrganizationUserSchema() {
	return useMemo(() => yup.object({
		email: yup.string().email("Email is invalid.").required(fieldIsRequiredMessage),
		isOrganizationAdmin: yup.bool().required(fieldIsRequiredMessage),
	}), []);
}


export default function InviteUserPage() {
	const schema = useInviteOrganizationUserSchema();
	const navigate = useNavigate();

	const { mutateAsync, error, isError } = useMutation((data: OrganizationUserInvitationCreation) => DefaultService.inviteOrganizationUserApiUsersInviteOrganizationUserPost(data), {
		onSuccess: () => {
			toastQueue.add({ onRender: () => "Invitation sent", type: "success" }, { timeout: 10000 });
			navigate("..");
		},
		useErrorBoundary: (error: ApiError) => !isHandledApiError(error, [409]),
	});

	const formContext = useForm<yup.InferType<typeof schema>>({
		onSubmit: data => mutateAsync(data).catch(noop),
		resolver: yupResolver(schema),
	});

	const messageBarWrapperStyle = css`
		margin-top: 12px;
	`;

	return (
		<FormComponents.Form state={formContext}>
			<FormComponents.TextField name="email" label="Email" />
			<FormComponents.Switch name="isOrganizationAdmin" label="Organization Admin" />
			<FormComponents.Submit>Send Invitation</FormComponents.Submit>
			{isError && error instanceof ApiError && (
				<div className={messageBarWrapperStyle}>
					<MessageBar type="error">{(extractApiErrorDetail(error) ?? error).message}</MessageBar>
				</div>
			)}
		</FormComponents.Form>
	);
}
