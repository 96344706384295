import { css } from "@linaria/core";
import classNames from "classnames";
import { FocusEvent, useRef } from "react";
import { useTextField } from "react-aria";

import { theme } from "theme";


export type ITextFieldProps = {
	id?: string,
	label?: string,
	placeholder?: string,
	name?: string,
	description?: string,
	errorMessage?: string,
	value?: string,
	onChange?: (value: string) => void,
	onBlur?: (value: FocusEvent<Element, Element>) => void,
	type?: "text" | "search" | "url" | "tel" | "email" | "password",
	isRequired?: boolean,
	isReadOnly?: boolean,
	isDisabled?: boolean,
	validationState?: "valid" | "invalid",
	variant?: "default" | "flat",
	fontSize?: number,
} & ({
	isMultiline?: true,
	rows?: number,
} | {
	isMultiline?: undefined,
	rows?: undefined,
});


export function TextField({ isMultiline = undefined, ...props }: ITextFieldProps) {
	return isMultiline ? <TextAreaField {...props} /> : <TextInputField {...props} />;
}


export function TextAreaField(props: ITextFieldProps) {
	const ref = useRef(null);
	const { labelProps, inputProps, descriptionProps, errorMessageProps } = useTextField({ ...props, inputElementType: "textarea" }, ref);

	return (
		<div className={wrapperStyle}>
			<label {...labelProps} className={classNames(labelStyle, { [labelIsRequiredStyle]: props.isRequired })}>{props.label}</label>
			<textarea {...inputProps} ref={ref} style={{ fontSize: props.fontSize }} className={classNames(inputStyle, textAreaStyle, { [inputInvalidStyle]: props.validationState === "invalid" })} rows={props.rows} />
			{props.description && (
				<div {...descriptionProps} className={descriptionStyle}>
					{props.description}
				</div>
			)}
			{props.errorMessage && (
				<div {...errorMessageProps} className={errorMessageStyle}>
					{props.errorMessage}
				</div>
			)}
		</div>
	);
}

export function TextInputField(props: ITextFieldProps) {
	const ref = useRef(null);
	const { labelProps, inputProps, descriptionProps, errorMessageProps } = useTextField(props, ref);

	return (
		<div className={wrapperStyle}>
			<label {...labelProps} className={classNames(labelStyle, { [labelIsRequiredStyle]: props.isRequired })}>{props.label}</label>
			<input {...inputProps} ref={ref} style={{ fontSize: props.fontSize }} className={classNames(inputStyle, { [inputInvalidStyle]: props.validationState === "invalid" })} />
			{props.description && (
				<div {...descriptionProps} className={descriptionStyle}>
					{props.description}
				</div>
			)}
			{props.errorMessage && (
				<div {...errorMessageProps} className={errorMessageStyle}>
					{props.errorMessage}
				</div>
			)}
		</div>
	);
}


const wrapperStyle = css`
	display: flex;
	flex-direction: column;
`;

const errorMessageStyle = css`
	margin-top: 5px;
	color: ${theme.semantic.errorColor};
	font-size: 12px;
	height: 0;
	opacity: 0;
	word-break:break-all;
	word-wrap: break-word;
	min-width: 100%;
	width: 0;

	@keyframes entry {

		to {
			height: auto;
			opacity: 1;
		}
	}

	animation: entry 100ms forwards;
`;

const descriptionStyle = css`
	margin-top: 5px;
	font-size: 12px;
`;

const textAreaStyle = css`
	resize:none;
`;

const inputStyle = css`
	padding: 6px 8px;
	line-height: 16px;
	font-size: 14px;
	border-radius: 4px;
	border: 1px solid ${theme.semantic.fieldBorder};
	background-color: ${theme.semantic.field};
	outline-color: ${theme.semantic.fieldOutline};
	transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;

	&:hover {
		background-color: ${theme.semantic.fieldHover};
		border: 1px solid ${theme.semantic.fieldBorderHover};
	}
`;

const inputInvalidStyle = css`
	&, &:hover, &:focus, &:active {
		border-color: ${theme.semantic.errorColor};
		outline-color: ${theme.semantic.errorColor};
	}
`;

const labelStyle = css`
	padding: 5px 0;
	font-size: 14px;
	font-weight: 600;
`;

const labelIsRequiredStyle = css`
	&::after {
		content: " *";
		color: ${theme.semantic.requiredAsterisk};
		padding-right: 12px;
	}
`;
