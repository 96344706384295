import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { css } from "@linaria/core";
import { useSearchParams } from "react-router-dom";

import { Button } from "components/Button";

import { ResetPasswordForm } from "./(components)/ResetPasswordForm";


export default function ResetPasswordPage() {
	const [searchParams] = useSearchParams();
	const token = searchParams.get("token");

	const contentStyle = css`
		height: 100%;
		display: grid;
		grid-template-rows: auto 1fr;
	`;

	const mainAreaStyle = css`
		display: flex;
		justify-content: center;
		align-items: start;
		flex-direction: column;
		height: 100%;
		padding-bottom: 32px;
	`;

	const linkWrapperStyle = css`
		padding: 16px;
	`;

	const missingTokenContainerStyle = css`
		width: 260px;
	`;

	return (
		<div className={contentStyle}>
			<div className={mainAreaStyle}>
				{token ? <ResetPasswordForm token={token} /> : (
					<div className={missingTokenContainerStyle}>
						<h1>Something went wrong.</h1>
						<p>
							Make sure to follow the link in the email. If the issue persists, please contact us at
							{" "}
							<Button href="mailto:support@weon.ai" variant="link">support@weon.ai</Button>
						</p>
					</div>
				)}
				<div className={linkWrapperStyle}><Button href="/auth/login" variant="link" leftIconProps={{ icon: faChevronLeft, marginRight: 8 }}>Go to login</Button></div>
			</div>
		</div>

	);
}
