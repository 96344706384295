import { yupResolver } from "@hookform/resolvers/yup";
import { css } from "@linaria/core";
import { useEffect, useMemo } from "react";
import { useMutation } from "react-query";
import * as yup from "yup";

import { ErrorBoundary } from "components/ErrorBoundary";
import { WeonLoadingOverlay } from "components/LoadingOverlay";
import { MessageBar } from "components/MessageBar";
import { Separator } from "components/Separator";
import { FormComponents } from "components/formComponents/FormComponents";
import { useForm } from "components/formComponents/useForm";
import { useAuth } from "components/providers/AuthProvider";
import { ApiError, DefaultService, User, UserSelfUpdate, extractApiErrorDetail, isHandledApiError } from "httpClient";
import { theme } from "theme";
import { fieldIsRequiredMessage } from "utils/fieldIsRequiredMessage";
import { phoneNumberIsInInvalidFormatMessage, phoneNumberRegex } from "utils/phoneNumberRegex";

import { H1 } from "../(components)/H1";
import { MainContainer } from "../(components)/MainContainer";


export default function EditUserPage() {
	const { user, isAuthenticated } = useAuth();

	if (!isAuthenticated)
		return <WeonLoadingOverlay />;

	return (
		<EditUserPageContent user={user} />
	);
}

function EditUserPageContent({ user }: { user: User }) {
	const divStyle = css`
		display: flex;
		flex-direction: column;
		height: 100%;
	`;

	const separatorWrapperStyle = css`
		width: 100%;
	`;

	const formWrapperStyle = css`
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 40px;

	`;

	const usersNameStyle = css`
color: ${theme.palette.blue};
`;

	return (
		<MainContainer>
			{/* <Breadcrumbs /> */}
			<div className={divStyle}>
				<H1>
					Edit
					<span className={usersNameStyle}>
						{" "}
						{user.firstName}
						{" "}
						{user.lastName}
					</span>
					&apos;s Profile
				</H1>
				<div className={separatorWrapperStyle}><Separator /></div>
				<div className={formWrapperStyle}>
					<ErrorBoundary>
						<FormSection user={user} />
					</ErrorBoundary>
				</div>
			</div>
		</MainContainer>
	);
}


interface IFormSectionProps {
	user: User,
}

function useEditUserSchema() {
	return useMemo(() => yup.object({
		firstName: yup.string().required(fieldIsRequiredMessage),
		lastName: yup.string().required(fieldIsRequiredMessage),
		phoneNumber: yup.string().required(fieldIsRequiredMessage).matches(phoneNumberRegex, phoneNumberIsInInvalidFormatMessage),
		currentPassword: yup.string().required(fieldIsRequiredMessage),
	}), []);
}

function FormSection({ user }: IFormSectionProps) {
	const { fetchUser } = useAuth();

	const updateUserMutation = useMutation((data: UserSelfUpdate) => DefaultService.updateUserApiUsersUpdateUserPost(data), {
		useErrorBoundary: (error: ApiError) => !isHandledApiError(error, [401, 403]),
		onSuccess: () => fetchUser(),
	});

	const schema = useEditUserSchema();

	const formContext = useForm<yup.InferType<typeof schema>>({
		defaultValues: { ...user, currentPassword: undefined },
		resolver: yupResolver(schema),
		onSubmit: data => updateUserMutation.mutateAsync(data),
	});

	const resetField = formContext.resetField;

	const messagebarWrapperStyle = css`
		margin-top: 20px;
	`;

	useEffect(() => {
		if (updateUserMutation.isSuccess)
			resetField("currentPassword");
	}, [resetField, updateUserMutation.isSuccess]);

	return (
		<>
			<FormComponents.Form state={formContext}>
				<FormComponents.TextField name="firstName" label="First Name" />
				<FormComponents.TextField name="lastName" label="Last Name" />
				<FormComponents.TextField name="phoneNumber" label="Phone Number" />
				<FormComponents.TextField name="currentPassword" label="Current Password" type="password" />
				<FormComponents.Submit>Update</FormComponents.Submit>
				{updateUserMutation.isSuccess && (
					<div className={messagebarWrapperStyle}>
						<MessageBar type="success">User updated succesfully!</MessageBar>
					</div>
				)}
				{updateUserMutation.isError && updateUserMutation.error instanceof ApiError
					&& (
						<div className={messagebarWrapperStyle}>
							<MessageBar type="error">{(extractApiErrorDetail(updateUserMutation.error) ?? updateUserMutation.error).message}</MessageBar>
						</div>
					)}
			</FormComponents.Form>
		</>
	);
}
