import { css } from "@linaria/core";

import { theme } from "theme";


export interface ILoaderProps {
	size?: number,
}

export function Loader({ size = 40 }: ILoaderProps) {
	const spanStyle = css`
		display: inline-block;

		@keyframes spin {
			0% {
				-webkit-transform: rotate(0deg);
				-moz-transform: rotate(0deg);
				-ms-transform: rotate(0deg);
				transform: rotate(0deg);
			}

			100% {
				-webkit-transform: rotate(360deg);
				-moz-transform: rotate(360deg);
				-ms-transform: rotate(360deg);
				transform: rotate(360deg);
			}
		}
		animation: spin 1.4s linear infinite;
	`;

	const circleStyle = css`
		stroke: ${theme.palette.blue};
		color: ${theme.palette.blue};



		@keyframes grow-shrink {
			0% {
				stroke-dasharray: 1px,200px;
				stroke-dashoffset: 0;
			}

			50% {
				stroke-dasharray: 100px,200px;
				stroke-dashoffset: -15px;
			}

			100% {
				stroke-dasharray: 100px,200px;
				stroke-dashoffset: -125px;
			}
		}

		animation: grow-shrink 1.4s ease-in-out infinite;
	`;

	return (
		<span className={spanStyle} style={{ width: size, height: size }}>
			<svg className="MuiCircularProgress-svg css-13o7eu2" viewBox="22 22 44 44">
				<circle className={circleStyle} cx="44" cy="44" r="20.2" fill="none" strokeWidth="3.6"></circle>
			</svg>
		</span>
	);
}
