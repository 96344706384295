import { css } from "@linaria/core";

// import { theme } from "theme";

// import { Image } from "./Image";
import { Loader } from "./Loader";
// import weonLogoSvgSrc from "../../public/weon-logo.svg";


export function LoadingOverlay() {
	const divStyle = css`
		position: absolute;
		background-color: rgba(0,0,0, 0.25);
		inset: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 100;
	`;

	return (
		<div className={divStyle}>
			<Loader size={48} />
		</div>
	);
}


export function WeonLoadingOverlay() {
	const divStyle = css`
		position: absolute;
		background-color: rgba(0,0,0, 0.05);
		inset: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 100;
	`;

	const loaderStyle = css`
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		perspective: 600px;
	`;

	// const loaderCubeStyle = css`
	// 	width: 100px;
	// 	height: 100px;
	// 	position: relative;
	// 	transform-style: preserve-3d;
	// 	transform-origin: 50% 50%;
	// 	animation-duration: 3s, 3s, 3s, 3s;
	// 	animation-delay: 0s, 3s, 6s, 9s;
	// 	animation-iteration-count: 1, 1, 1, infinite;
	// 	animation-name: rotate, rotate, rotate, rotate;
	// 	animation-direction: normal, normal, reverse, reverse;
	// 	animation-timing-function: linear, ease-out, ease-out, linear;

	// 	&:before, &:after {
	// 		content: "";
	// 		position: absolute;
	// 		width: 100%;
	// 		height: 100%;
	// 		background-color: transparent;
	// 	}

	// 	&:before {
	// 		transform: rotateX(90deg) translateZ(50px);
	// 	}

	// 	&:after {
	// 		transform: rotateY(90deg) translateZ(50px);
	// 	}

	// 	@keyframes rotate {
	// 		0% {
	// 			transform: rotateY(0deg) rotateX(0deg);
	// 		}

	// 		100% {
	// 			transform: rotateY(1turn) rotateX(1turn);
	// 		}
	// 	}
	// `;

	const spinnerStyle = css`
		width: 5rem;
		height: 5rem;
		border-top-color: transparent;
		border-left-color: transparent;
	
		animation: spinner 500ms linear infinite;
		border-bottom-color: #09d;
		border-right-color: #09d;
		border-style: solid;
		border-width: 6px;
		border-radius: 50%;  
		box-sizing: border-box;
		display: inline-block;
		vertical-align: middle;

		@keyframes spinner {
			0% { transform: rotate(0deg); }
			100% { transform: rotate(360deg); }
		}
	`;

	return (
		<div key="weonLoaderUnderlay" className={divStyle}>
			<div key="weonLoader" className={loaderStyle}>
				<span className={spinnerStyle} />
				{/* <div key="loaderCube" className={loaderCubeStyle}>
					<BoxPane key="face1" />
					<BoxPane key="face2" />
					<BoxPane key="face3" />
					<BoxPane key="face4" />
					<BoxPane key="face5" />
					<BoxPane key="face6" />
				</div> */}
			</div>
		</div>
	);
}

// function BoxPane() {
// 	const faceStyle = css`
// 		position: absolute;
// 		width: 100%;
// 		height: 100%;
// 		background-color: ${theme.palette.white};
// 		opacity: 0.8;
// 		border: 2px solid ${theme.palette.white};
// 		background-color: ${theme.palette.grayDark};

// 		&:nth-child(1) {
// 			transform: rotateY(0deg) translateZ(50px);
// 		}

// 		&:nth-child(2) {
// 			transform: rotateX(90deg) translateZ(50px);
// 		}

// 		&:nth-child(3) {
// 			transform: rotateY(90deg) translateZ(-50px);
// 		}

// 		&:nth-child(4) {
// 			transform: rotateX(-90deg) translateZ(50px);
// 		}

// 		&:nth-child(5) {
// 			transform: rotateY(90deg) translateZ(50px);
// 		}

// 		&:nth-child(6) {
// 			transform: rotateY(0deg) translateZ(-50px);
// 		}

// 		overflow: hidden;
// 	`;

// 	const imgStyle = css`
// 		width: 100%;
// 		height: 100%;
// 		object-fit: contain;
// 		padding: 8px;
// 	`;

// 	return <div key="face1" className={faceStyle}><Image className={imgStyle} src={weonLogoSvgSrc} alt="logo" fill /></div>;
// }
