import { css } from "@linaria/core";
import { Component, ErrorInfo, ReactNode } from "react";


import { Button } from "./Button";
import { InterpretError } from "./InterpretError";
import { MessageBar } from "./MessageBar";


type IOnErrorRender = ({ error, reload }: { error: unknown, reload: (() => void) }) => ReactNode;

interface IProps {
	children: ReactNode,
	onErrorRender?: IOnErrorRender,
}

type IState = ({ error?: undefined, hasError: false } | { error: unknown, hasError: true });

export class ErrorBoundary extends Component<IProps, IState & { onErrorRender?: IOnErrorRender }> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			hasError: false,
			onErrorRender: props.onErrorRender,
		};
	}

	static getDerivedStateFromError(error: unknown): IState | null {
		return {
			error,
			hasError: true,
		};
	}

	componentDidCatch(_error: unknown, _errorInfo: ErrorInfo) {
		// You can use your own error logging service here
	}

	render() {
		if (this.state.hasError) {
			const reload = () => this.setState({ hasError: false });

			return this.state.onErrorRender ? this.state.onErrorRender({ error: this.state.error, reload }) : (
				<div
					className={css`
                        display: grid;
                        text-align: center;
                        padding: 20px;
                    `}
				>
					<MessageBar
						type="error"
						actions={(
							<Button
								variant="flat"
								onPress={reload}
								size="sm"
							>
								Reload
							</Button>
						)}
					>
						<InterpretError error={this.state.error} />
					</MessageBar>
				</div>
			);
		}

		return this.props.children;
	}
}
