import { faArrowRightFromBracket, faEnvelope, faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { css } from "@linaria/core";

import { Button } from "components/Button";
import { useAuth } from "components/providers/AuthProvider";
import { ApiError, extractApiErrorDetail } from "httpClient";
import { theme } from "theme";


interface IAppPlatformErrorPageProps {
	error: Error,
	reset: () => void,
}


export function AppPageErrorContent({ error, reset }: IAppPlatformErrorPageProps) {
	const { logout } = useAuth();

	const heroStyle = css`
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
		padding: 0 48px;
		text-align: center;
	`;

	const h1Style = css`
		font-size: 22px;
		font-weight: 700;
		color: ${theme.palette.orange};
	`;

	const h2Style = css`
		font-size: 32px;
		margin: 16px 0;
	`;

	const h3Style = css`
		font-size: 18px;
		font-weight: 600;
		color: ${theme.semantic.foregroundMuted};
	`;

	const buttonContainerStyle = css`
		display: flex;
		gap: 20px;
		margin-top: 40px;
		margin-bottom: 40px;
	`;

	return (
		<div className={heroStyle}>
			<h1 className={h1Style}>Something went wrong</h1>
			<h2 className={h2Style}>{(error instanceof ApiError ? extractApiErrorDetail(error) ?? error : error).message ?? "An unknown error occured"}</h2>
			<h3 className={h3Style}>Please try again later or contact customer service if the problem persists.</h3>
			<div className={buttonContainerStyle}>
				<Button variant="default" onPress={() => logout()} size="lg" leftIconProps={{ icon: faArrowRightFromBracket, marginRight: 16 }}>Log out</Button>
				<Button variant="default" href="mailto:info@weon.ai" size="lg" leftIconProps={{ icon: faEnvelope, marginRight: 16 }}>Contact us</Button>
				<Button variant="primary" onPress={() => reset()} size="lg" leftIconProps={{ icon: faRotateRight, marginRight: 16 }}>Reload page</Button>
			</div>
		</div>
	);
}
