import { css } from "@linaria/core";
import classNames from "classnames";
import { useRef, ReactNode, cloneElement, ReactElement } from "react";
import { DismissButton, Overlay, usePopover, AriaPopoverProps, Placement, useOverlayTrigger } from "react-aria";
import { type OverlayTriggerState, useOverlayTriggerState } from "react-stately";

import { theme } from "theme";

import { Button } from "./Button";


export type IPopoverPlacement = Placement;

interface IPopoverBaseProps extends Omit<AriaPopoverProps, "popoverRef"> {
	children: ReactNode,
	state: OverlayTriggerState,
	width?: number,
	placement?: IPopoverPlacement,
	withUnderlay?: boolean,
}

export function PopoverBase({
	children, state, offset = -2, withUnderlay = true, ...props
}: IPopoverBaseProps) {
	const popoverRef = useRef(null);

	const { popoverProps, underlayProps } = usePopover({
		...props,
		offset,
		popoverRef,
	}, state);

	const underlayStyle = css`
		position: fixed;
		inset: 0;
	`;

	const slideDownStyle = css`
		@keyframes entry {
			from {
				transform: translateY(-10px);
				opacity: 0.4;
			}

			to {
				transform: translateY(0);
				opacity: 1;
			}
		}

		animation: entry 150ms forwards;
	`;

	const slideUpStyle = css`
		@keyframes entry {
			from {
				transform: translateY(10px);
				opacity: 0.4;
			}

			to {
				transform: translateY(0);
				opacity: 1;
			}
		}

		animation: entry 150ms forwards;
	`;

	const slideLeftStyle = css`
		@keyframes entry {
			from {
				transform: translateX(10px);
				opacity: 0.4;
			}

			to {
				transform: translateX(0);
				opacity: 1;
			}
		}

		animation: entry 150ms forwards;
	`;

	const slideRightStyle = css`
		@keyframes entry {
			from {
				transform: translateX(-10px);
				opacity: 0.4;
			}

			to {
				transform: translateX(0);
				opacity: 1;
			}
		}

		animation: entry 150ms forwards;
	`;

	const childrenWrapperStyle = css`
		overflow: auto;
		box-shadow: ${theme.shadows.popoverShadow};
	`;

	return (
		<Overlay>
			{withUnderlay && <div {...underlayProps} className={underlayStyle} />}
			<div
				{...popoverProps}
				ref={popoverRef}
				className={classNames(childrenWrapperStyle, {
					[slideDownStyle]: props.placement?.startsWith("bottom"),
					[slideUpStyle]: props.placement?.startsWith("top"),
					[slideLeftStyle]: props.placement?.startsWith("left"),
					[slideRightStyle]: props.placement?.startsWith("right"),
				})}
			>
				<DismissButton onDismiss={() => state.close()} />
				{children}
				<DismissButton onDismiss={() => state.close()} />
			</div>
		</Overlay>
	);
}


interface IPopoverProps {
	children: ((state: OverlayTriggerState) => ReactElement) | ReactElement,
	buttonElement: ReactNode,
	offset?: number,
	isDisabled?: boolean,
}

export function Popover({ children, buttonElement, offset, isDisabled }: IPopoverProps) {
	const ref = useRef(null);
	const state = useOverlayTriggerState({});

	const { triggerProps, overlayProps } = useOverlayTrigger(
		{ type: "dialog" },
		state,
		ref
	);


	return (
		<>
			<Button ref={ref} variant="normalized" {...triggerProps} isDisabled={isDisabled}>
				{buttonElement}
			</Button>
			{!isDisabled && state.isOpen && (
				<PopoverBase
					triggerRef={ref}
					placement="bottom start"
					offset={offset}
					state={state}
				>
					{cloneElement(typeof children === "function" ? children(state) : children, overlayProps)}
				</PopoverBase>
			)}
		</>
	);
}
