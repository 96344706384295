import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "@linaria/core";


interface ILabelValuePairProps {
	label: string,
	value: string,
	icon?: IconProp,
	variant?: "default" | "split",
}

export function LabelValuePair({ label, value, icon, variant = "default" }: ILabelValuePairProps) {
	const labelValuePairContainerStyle = variant === "default" ? css`
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
	` : css`
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	`;

	const labelStyle = variant === "default" ? css`
		word-break: keep-all;
		font-weight: 700;
		margin-right: 8px;
		white-space: nowrap;
	` : css`
		word-break: keep-all;
		font-weight: normal;
		margin-right: 8px;
		white-space: nowrap;
	`;

	const iconStyle = css`
		margin-right: 8px;
	`;


	return (
		<div className={labelValuePairContainerStyle}>
			<div className={labelStyle}>
				{icon && <FontAwesomeIcon icon={icon} className={iconStyle} />}
				{label}
				:
			</div>
			<div>
				{value}
			</div>
		</div>
	);
}
