import "normalize.css";
import { createRoot } from "react-dom/client";

import { App } from "./pages/App";


const container = document.createElement("div");
container.id = "react-root";
document.body.appendChild(container);
const root = createRoot(container);
root.render(<App />);
