import { css } from "@linaria/core";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { Button } from "components/Button";
import { Image } from "components/Image";
import { WeonLoadingOverlay } from "components/LoadingOverlay";
import { Order } from "httpClient";
import { theme } from "theme";
import { invariant } from "utils/invariant";
import { generateGetOrderQueryOptions } from "utils/react-query/data-fetching/order";
import { ensureIsNotError } from "utils/react-query/ensureIsNotError";
import { isNotLoadingOrIdle } from "utils/react-query/isNotLoadingOrIdle";

import undrawWellDoneIllustrationSvgSrc from "../../../../../../../public/undraw_well_done_re_3hpo.svg";


export default function DonePage() {
	const { orderId } = useParams();
	invariant(orderId, "orderId must be defined");

	const orderQuery = useQuery(generateGetOrderQueryOptions(orderId));
	ensureIsNotError(orderQuery);

	if (!isNotLoadingOrIdle(orderQuery))
		return <WeonLoadingOverlay />;

	return <PageContent order={orderQuery.data} />;
}

interface IPageContentProps {
	order: Order,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function PageContent({ order }: IPageContentProps) {
	const innerMainContainerStyle = css`
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: center;
		align-items: center;
		gap: 40px;
		padding: 40px;
	`;

	const h2Style = css`
		font-size: 28px;
		text-align: center;
		margin-top: 10px;
		margin-bottom: 12px;
	`;

	const h3Style = css`
		color: ${theme.palette.secondary};
		font-size: 1.2rem;
		font-weight: normal;
		padding: 6px;
		border-radius: 6px;
		background-color: ${theme.palette.secondaryLight};
		margin: 0.5rem;
	`;

	const pStyle = css`
		color: ${theme.semantic.foregroundMuted};
		line-height: 1.8em;
		max-width: 600px;
	`;

	const textContainerStyle = css`
		display: flex;
		flex-direction: column;
		align-items: center;
	`;

	// const imgContainerStyle = css`
	// 	position: relative;
	// 	height: 200px;
	// 	width: 478px;
	// 	margin-bottom: 20px;
	// `;

	const buttonContainerStyle = css`
		margin-bottom: 20px;
	`;

	return (
		<div className={innerMainContainerStyle}>
			<div className={textContainerStyle}>
				<Image src={undrawWellDoneIllustrationSvgSrc} height={220} width={220} alt="order confirmed illustration" />
				{/* <div className={checkMarkStyle}>
					<FontAwesomeIcon
						icon={faCheck}
						className={iconStyle}
					/>
				</div> */}
				<h2 className={h2Style}>Photoshoot Order Confirmed!</h2>
				<h3 className={h3Style}>
					{order.name}
				</h3>
				<p className={pStyle}>
					Your order has been placed and can now be found among your in-progress photoshoots.
					Your photos will be generated and processed shortly, and you will be notified when there are results to view.
					All done for now!
				</p>
			</div>
			<span className={buttonContainerStyle}>
				<Button variant="primary" size="lg" href="/app/photoshoots/browse">Browse Photoshoots</Button>
			</span>

		</div>
	);
}
