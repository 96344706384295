import { Permission, User } from "httpClient";


export class AppPlatformError extends Error {
	public readonly user: User;

	constructor(user: User, message?: string, options?: ErrorOptions) {
		super(message, options);
		this.user = user;
	}
}

export class NotACustomerError extends AppPlatformError {
	constructor(user: User) {
		super(user, `You are logged in as ${user.firstName} ${user.lastName} (${user.email}), whom is not registered as a customer user.`);
	}
}

export class BlockedCustomerError extends AppPlatformError {
	constructor(user: User) {
		super(user, `Your user (${user.email}) has unfortunately been blocked on the platform.`);
	}
}

export class MissingPermissionsError extends AppPlatformError {
	constructor(user: User, requiredPermissions: Permission[]) {
		super(user, `Your user misses one or more of the following permissions: "${requiredPermissions.join(", ")}"`);
	}
}

export class UnauthenticatedError extends Error {
	constructor() {
		super("You must be authenticated to access this resource.");
	}
}
