import { faCheck, faCircleExclamation, faCircleInfo, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "@linaria/core";
import classNames from "classnames";
import { ReactNode } from "react";

import { theme } from "theme";
import { assertUnreachable } from "utils/assertUnreachable";
import { IMessageType } from "utils/messageType";


interface IMessageBarProps {
	type: IMessageType,
	isMultiline?: boolean,
	actions?: ReactNode,
	children?: ReactNode,
}

export function MessageBar({ type, children, actions, isMultiline }: IMessageBarProps) {
	const messageBarStyle = css`
		min-height: 32px;
		width: 100%;
		display: flex;
		word-break: break-word;
		flex-direction: column;
	`;

	const messageBarContentStyle = css`
		border-radius: 4px;
		display: flex;
		min-width: 100%;
		width: 0;
	`;

	const iconWrapperStyle = css`
		flex-shrink: 0;
		margin: 8px 0px 8px 12px;
		min-width: 16px;
		min-height: 16px;
		display: flex;
	`;

	const childrenWrapper = css`
		display: flex;
		min-width: 0;
		flex-grow: 1;
		margin: 8px;
		font-size: 12px;
	`;

	const innerTextStyle = css`
		line-height: 16px;
	`;

	const innerTextNotMultilineStyle = css`
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	`;

	const actionsStyle = css`
		display: flex;
		flex: 0 0 auto;
		align-items: center;
		margin: 0px 12px 0px 8px;
		flex-direction: row-reverse;
	`;

	const typeStyle =
		type === "error" ? errorStyle
		: type === "success" ? successStyle
		: type === "info" ? infoStyle
		: type === "warning" ? warningStyle
		: assertUnreachable(type, "unknown messagebar type");

	const icon =
		type === "error" ? faCircleExclamation
		: type === "success" ? faCheck
		: type === "warning" ? faTriangleExclamation
		: type === "info" ? faCircleInfo
		: assertUnreachable(type, "unknown messagebar type");

	return (
		<div className={messageBarStyle}>
			<div className={classNames(messageBarContentStyle, typeStyle)}>
				<div className={iconWrapperStyle}>
					<FontAwesomeIcon icon={icon} />
				</div>
				<div className={childrenWrapper}>
					<span className={classNames(innerTextStyle, { [innerTextNotMultilineStyle]: !isMultiline })}>{children}</span>
				</div>
				{actions && !isMultiline && (
					<div className={actionsStyle}>
						{actions}
					</div>
				)}
			</div>
			{actions && isMultiline && (
				<div className={actionsStyle}>
					{actions}
				</div>
			)}
		</div>
	);
}


const errorStyle = css`
	border: 1px solid ${theme.semantic.dangerBorder};
	background-color: ${theme.semantic.danger};
	color: ${theme.semantic.dangerForeground};
`;

const warningStyle = css`
	border: 1px solid ${theme.semantic.warningBorder};
	background-color: ${theme.semantic.warning};
	color: ${theme.semantic.warningForeground};
`;

const successStyle = css`
	border: 1px solid ${theme.semantic.successBorder};
	background-color: ${theme.semantic.success};
	color: ${theme.semantic.successForeground};
`;

const infoStyle = css`
	border: 1px solid ${theme.semantic.infoBorder};
	background-color: ${theme.semantic.info};
	color: ${theme.semantic.infoForeground};
`;
