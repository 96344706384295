export function InterpretError({ error }: { error: unknown }) {
	if (error instanceof Error) {
		return (
			<>
				[
				{error.name}
				]
				{" "}
				{error.message}
			</>
		);
	}

	return <>An unexpected error has occured</>;
}
