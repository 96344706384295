import { useRef } from "react";
import { AriaCheckboxProps, useCheckbox } from "react-aria";
import { useToggleState } from "react-stately";


export function Checkbox(props: AriaCheckboxProps) {
	const { children } = props;
	const state = useToggleState(props);
	const ref = useRef<HTMLInputElement>(null);
	const { inputProps } = useCheckbox(props, state, ref);

	return (
		<label style={{ display: "block" }}>
			<input {...inputProps} ref={ref} />
			{children}
		</label>
	);
}
