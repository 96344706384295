import { useMemo } from "react";
import { useQueryClient } from "react-query";
import * as yup from "yup";

import { generateGetCategoriesQueryOptions } from "utils/react-query/data-fetching/categories";
import { generateGetOrganizationGarmentsQueryOptions } from "utils/react-query/data-fetching/garment";


export type ISaveOrderRowsSchema = yup.InferType<ReturnType<typeof useSaveOrderRowSchema>>;
export type ISaveOrderRowSchema = ISaveOrderRowsSchema["orderRows"][number];

export function useSaveOrderRowSchema() {
	const queryClient = useQueryClient();

	return useMemo(() => yup.object({
		orderRows: yup.array().of(
			yup.object({
				orderRowId: yup.string().required().nullable().default(null),
				mainGarmentId: yup.string().required("mainGarmentId is a required field."),
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				outerGarmentImageId: yup.string().required().nullable().default(null).test("test", "test", (outerGarmentImageId, context) => {
					// const message = "The showcase garment must be included in the photo.";
					// const { upperGarmentImageId, mainGarmentId } = context.parent as ISaveOrderRowSchema;
					// const garments = await queryClient.fetchQuery(generateGetOrganizationGarmentsQueryOptions());
					// const categories = await queryClient.fetchQuery(generateGetCategoriesQueryOptions());
					// const mainGarment = garments.find(x => x.garmentId === mainGarmentId);

					// if (mainGarment && categories) {
					// 	const mainGarmentBodySection = categories[mainGarment.categoryName]?.bodySection;

					// 	if (mainGarmentBodySection === "WholeBody" || mainGarmentBodySection === "UpperBody") {
					// 		if ((!outerGarmentImageId || !mainGarment.garmentImages[outerGarmentImageId]) && (!upperGarmentImageId || !mainGarment.garmentImages[upperGarmentImageId]))
					// 			return context.createError({ message });
					// 	}
					// }

					return true;
				}),
				upperGarmentImageId: yup.string().required("An upper-body garment is required.").test("test-main-garment-is-included", async (upperGarmentImageId, context) => {
					const message = "The showcase garment must be included in the photo.";
					const { outerGarmentImageId, mainGarmentId } = context.parent as ISaveOrderRowSchema;
					const garments = await queryClient.fetchQuery(generateGetOrganizationGarmentsQueryOptions());
					const categories = await queryClient.fetchQuery(generateGetCategoriesQueryOptions());
					const mainGarment = garments.find(x => x.garmentId === mainGarmentId);

					if (mainGarment && categories) {
						const mainGarmentBodySection = categories[mainGarment.categoryName]?.bodySection;

						if (mainGarmentBodySection === "WholeBody" || mainGarmentBodySection === "UpperBody") {
							if ((!outerGarmentImageId || !mainGarment.garmentImages[outerGarmentImageId]) && (!upperGarmentImageId || !mainGarment.garmentImages[upperGarmentImageId]))
								return context.createError({ message });
						}
					}

					return true;
				}),
				lowerGarmentImageId: yup.string().required().nullable().default(null).test("test", async (lowerGarmentImageId, context) => {
					const { upperGarmentImageId } = context.parent as ISaveOrderRowSchema;
					const garments = await queryClient.fetchQuery(generateGetOrganizationGarmentsQueryOptions());
					const categories = await queryClient.fetchQuery(generateGetCategoriesQueryOptions());
					const upperGarment = garments.find(x => x.garmentImages[upperGarmentImageId]);

					if (upperGarment && categories) {
						const mainGarmentBodySection = categories[upperGarment.categoryName]?.bodySection;

						if (mainGarmentBodySection !== "WholeBody" && !lowerGarmentImageId)
							return context.createError({ message: "You must select a lower body garment." });
					}

					return true;
				}).test("test-main-garment-is-included", async (lowerGarmentImageId, context) => {
					const { mainGarmentId } = context.parent as ISaveOrderRowSchema;
					const message = "The showcase garment must be included in the photo.";
					const garments = await queryClient.fetchQuery(generateGetOrganizationGarmentsQueryOptions());
					const categories = await queryClient.fetchQuery(generateGetCategoriesQueryOptions());
					const mainGarment = garments.find(x => x.garmentId === mainGarmentId);

					if (mainGarment && categories) {
						if (categories[mainGarment.categoryName]?.bodySection === "LowerBody" && (!lowerGarmentImageId || !mainGarment.garmentImages[lowerGarmentImageId]))
							return context.createError({ message });
					}

					return true;
				}),
				personImageId: yup.string().required("You must select a model image."),
				tuckUpper: yup.bool().required("tuckUpper is a required field."),
			})
		).required().min(1, "You must design one outfit in order to proceed."),
	}), [queryClient]);
}
