import { ImageSize, OpenAPI } from "httpClient";


export function imageUrl(key: string, size?: ImageSize) {
	const queryParams = size ? `?size=${size}` : "";
	return backendUrl(`/images/${key}${queryParams}`);
}


export function backendUrl(path: string) {
	return `${OpenAPI.BASE}/api${path}`;
}
