import { yupResolver } from "@hookform/resolvers/yup";
import { css } from "@linaria/core";
import noop from "lodash/noop";
import { useMutation } from "react-query";
import * as yup from "yup";

import { Button } from "components/Button";
import { MessageBar } from "components/MessageBar";
import { FormComponents } from "components/formComponents/FormComponents";
import { useForm } from "components/formComponents/useForm";
import { ApiError, DefaultService, UserPasswordChange, extractApiErrorDetail, isHandledApiError } from "httpClient";


interface IResetPasswordFormProps {
	token: string,
}

export function ResetPasswordForm({ token }: IResetPasswordFormProps) {
	const { mutateAsync, isSuccess, isError, error } = useMutation((data: UserPasswordChange) => DefaultService.changePasswordApiUsersChangePasswordPost(data), {
		useErrorBoundary: (err: ApiError) => !isHandledApiError(err, [401, 404, 410]),
	});

	const fieldIsRequiredMessage = "Field is required.";

	const schema = yup.object({
		password: yup.string().required(fieldIsRequiredMessage).min(8, "Password must have more than 8 characters").max(64, "Password must have max 64 characters"),
		confirmPassword: yup.string().oneOf([yup.ref("password")], "Passwords must match"),
	});

	const formContext = useForm<yup.InferType<typeof schema>>({
		onSubmit: async data => mutateAsync({ ...data, userPasswordChangeToken: token }).catch(noop),
		resolver: yupResolver(schema),
	});


	const h1Style = css`
		font-size: 2rem;
		margin-bottom: 1rem;
		text-align: center;
	`;


	const messageBarWrapperStyle = css`
		margin: 10px 0;
	`;

	const innerFormStyle = css`
	`;


	return (
		<FormComponents.Form state={formContext}>
			<h1 className={h1Style}>Reset password</h1>
			<div className={innerFormStyle}>
				<FormComponents.TextField label="Password" name="password" type="password" isReadOnly={isSuccess} isRequired />
				<FormComponents.TextField label="Confirm Password" name="confirmPassword" type="password" isReadOnly={isSuccess} isRequired />
				{isSuccess ? (
					<>
						<div className={messageBarWrapperStyle}>
							<MessageBar type="success">Your password has been succesfully reset.</MessageBar>
						</div>
						<Button variant="success" href="/auth/login">Go to login</Button>
					</>
				)
					: <FormComponents.Submit>Submit</FormComponents.Submit>}
				{isError && error instanceof ApiError && (
					<div className={messageBarWrapperStyle}>
						<MessageBar type="error">{(extractApiErrorDetail(error) ?? error).message}</MessageBar>
					</div>
				)}
			</div>
		</FormComponents.Form>
	);
}
