import { DefaultService } from "httpClient";


export function generateGetOrganizationUsersQueryOptions() {
	return {
		queryKey: "get-organization-users",
		queryFn: () => DefaultService.getUsersByOrganizationApiUsersGetUsersByOrganizationGet(),
	};
}

export function generateGetOrganizationUserInvitationsQueryOptions() {
	return {
		queryKey: "get-organization-user-invitations",
		queryFn: () => DefaultService.getOrganizationUserInvitationsApiUsersGetOrganizationUserInvitationsGet(),
	};
}
