import { yupResolver } from "@hookform/resolvers/yup";
import { css } from "@linaria/core";
import noop from "lodash/noop";
import { useMutation } from "react-query";
import * as yup from "yup";

import { MessageBar } from "components/MessageBar";
import { FormComponents } from "components/formComponents/FormComponents";
import { useForm } from "components/formComponents/useForm";
import { useAuth } from "components/providers/AuthProvider";
import { ApiError, DefaultService, UserCreation, extractApiErrorDetail, isHandledApiError } from "httpClient";
import { phoneNumberIsInInvalidFormatMessage, phoneNumberRegex } from "utils/phoneNumberRegex";


interface IResetPasswordFormProps {
	token: string,
}

export function RegisterForm({ token }: IResetPasswordFormProps) {
	const { fetchUser } = useAuth();

	const { mutateAsync, isError, error } = useMutation((data: UserCreation) => DefaultService.registerApiUsersRegisterPost(data), {
		useErrorBoundary: (error: ApiError) => !isHandledApiError(error, [401, 404, 410]),
		onSuccess: () => fetchUser(), // This will rerender and navigate to /app (through the authguards)
	});

	const fieldIsRequiredMessage = "Field is required.";

	const schema = yup.object({
		firstName: yup.string().required(fieldIsRequiredMessage),
		lastName: yup.string().required(fieldIsRequiredMessage),
		phoneNumber: yup.string().required(fieldIsRequiredMessage).matches(phoneNumberRegex, phoneNumberIsInInvalidFormatMessage),
		password: yup.string().required(fieldIsRequiredMessage).min(8, "Password must have more than 8 characters").max(64, "Password must have 64 characters or less"),
		confirmPassword: yup.string().oneOf([yup.ref("password")], "Passwords must match"),
	});

	const formContext = useForm<yup.InferType<typeof schema>>({
		onSubmit: data => mutateAsync({ ...data, userInvitationToken: token }).catch(noop),
		resolver: yupResolver(schema),
	});


	const h1Style = css`
		font-size: 36;
		margin-bottom: 5px;
		text-align: center;
	`;

	const pStyle = css`
		margin-top: 10px;
		margin-bottom: 20px;
		text-align: center;
	`;

	const messageBarWrapperStyle = css`
		max-width: 420px;
		margin-top: 10px;
	`;


	const loginFormStyle = css`
		display: flex;
		flex-direction: column;
		align-items: center;
		
	`;

	return (
		<div className={loginFormStyle}>
			<h1 className={h1Style}>We are thrilled to have you registered!</h1>
			<p className={pStyle}>Please enter you details.</p>
			<FormComponents.Form state={formContext}>
				<FormComponents.TextField label="First Name" name="firstName" isRequired />
				<FormComponents.TextField label="Last Name" name="lastName" isRequired />
				<FormComponents.TextField label="Phone Number" name="phoneNumber" isRequired />
				<FormComponents.TextField label="Password" name="password" type="password" isRequired />
				<FormComponents.TextField label="Confirm Password" name="confirmPassword" type="password" isRequired />
				<FormComponents.Submit>Submit</FormComponents.Submit>
				{isError && error instanceof ApiError && (
					<div className={messageBarWrapperStyle}>
						<MessageBar type="error" isMultiline>
							{(extractApiErrorDetail(error) ?? error).message}
						</MessageBar>
					</div>
				)}
			</FormComponents.Form>
		</div>
	);
}
