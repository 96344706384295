import { ReactNode } from "react";
import { QueryClient, QueryClientProvider as ReactQueryQueryClientProvider } from "react-query";

import { ApiError } from "httpClient";


const queryClient = new QueryClient();

queryClient.setDefaultOptions({
	queries: {
		staleTime: 5 * (60 * 1000), // 5 mins
		cacheTime: 10 * (60 * 1000), // 10 mins
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		retry: (failureCount, error) => {
			if (error instanceof ApiError && error.status === 404)
				return false;

			return failureCount < 3;
		},
		useErrorBoundary: true,
	},
	mutations: {
		onMutate: () => queryClient.cancelQueries(),
		onSettled: () => queryClient.invalidateQueries(),
		useErrorBoundary: true,
	},
});


export default function QueryClientProvider({ children }: { children: ReactNode }) {
	return (
		<ReactQueryQueryClientProvider client={queryClient}>
			{children}
		</ReactQueryQueryClientProvider>
	);
}
