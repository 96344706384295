import { faArrowUpFromBracket, faCheck, faClipboardList, faList, faShirt } from "@fortawesome/free-solid-svg-icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { css } from "@linaria/core";
import { AnimatePresence } from "framer-motion";
import isEmpty from "lodash/isEmpty";
import { ReactNode, useMemo } from "react";
import { FormProvider } from "react-hook-form";
import { useQuery } from "react-query";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";


import { WeonLoadingOverlay } from "components/LoadingOverlay";
import { useForm } from "components/formComponents/useForm";
import { IStep, WizardProvider, WizardComponent, useWizard } from "components/providers/WizardProvider";
import { Order } from "httpClient";
import { MainContainer } from "pages/app/(components)/MainContainer";
import { invariant } from "utils/invariant";
import { generateGetOrderQueryOptions } from "utils/react-query/data-fetching/order";
import { ALLOWED_IMAGE_EXTENSIONS, useFileSchema } from "utils/useFileSchema";


function useNewGarmentImageFilesSchema() {
	const fileSchema = useFileSchema(ALLOWED_IMAGE_EXTENSIONS).required();
	return useMemo(() => yup.object({ files: yup.array().of(fileSchema).required() }), [fileSchema]);
}

export type INewGarmentImageFilesSchema = yup.InferType<ReturnType<typeof useNewGarmentImageFilesSchema>>;

export default function CreatePhotoshootWizardPage() {
	const { orderId } = useParams();
	invariant(orderId, "orderId must be defined");

	const schema = useNewGarmentImageFilesSchema();

	const formContext = useForm<yup.InferType<typeof schema>>({
		resolver: yupResolver(schema),
		defaultValues: {
			files: [],
		},
		mode: "onChange",
	});

	const orderQuery = useQuery(generateGetOrderQueryOptions(orderId));

	if (orderQuery.isError)
		throw orderQuery.error;

	const steps: IStep[] = useMemo(() => [
		{
			label: "Upload Garments",
			href: `/app/photoshoots/create/${orderId}/upload-garments`,
			icon: faArrowUpFromBracket,
		},
		{
			label: "Fill Metadata",
			href: `/app/photoshoots/create/${orderId}/fill-metadata`,
			isBlocked: !isEmpty(formContext.formState.errors),	// This is a hack. React hook forms always starts with isValid = false (which is expected behaviour).
			icon: faClipboardList,
		},
		{
			label: "Design Outfits",
			href: `/app/photoshoots/create/${orderId}/design-outfits`,
			icon: faShirt,
		},
		{
			label: "Summary",
			href: `/app/photoshoots/create/${orderId}/summary`,
			icon: faList,
		},
		{
			label: "Done",
			href: `/app/photoshoots/create/${orderId}/done`,
			icon: faCheck,
		},
	], [formContext, orderId]);

	return (
		<AnimatePresence>
			{orderQuery.isIdle || orderQuery.isLoading ? <WeonLoadingOverlay /> : (
				<WizardProvider steps={steps}>
					<FormProvider {...formContext}>
						<CreateOrderWizard order={orderQuery.data}>
							<Outlet />
						</CreateOrderWizard>
					</FormProvider>
				</WizardProvider>
			)}
		</AnimatePresence>
	);
}


interface ICreateOrderWizardProps {
	children: ReactNode,
	order: Order,
}

function CreateOrderWizard({ children, order }: ICreateOrderWizardProps) {
	const { orderId } = useParams();
	invariant(orderId, "orderId must be defined");
	const navigate = useNavigate();

	const { activeStepIndex, steps } = useWizard();

	if (order.orderStatus !== "DRAFT" && (activeStepIndex !== steps.length - 1)) {
		const lastStepHref = steps[steps.length - 1]?.href;
		if (lastStepHref)
			navigate(lastStepHref);
	}

	const timelineSectionStyle = css`
		display: flex;
		justify-content: center;
	`;

	const timelineWrapperStyle = css`
		max-width: 1200px;
		flex: 1;
	`;

	const contentContainerStyle = css`
		margin-top: 8px;
		flex: 1 1 auto;
		overflow: hidden;
		height: 0px;
		padding: 0 16px;
		position: relative;
	`;

	const innerContainerStyle = css`
		display: flex;
		flex-direction: column;
		height: 100%;
	`;

	return (
		<MainContainer>
			<div className={innerContainerStyle}>
				<div className={timelineSectionStyle}>
					<div className={timelineWrapperStyle}>
						{/* <H1>Create a Photoshoot 📸</H1> */}
						<WizardComponent />
					</div>
				</div>
				<div className={contentContainerStyle}>
					{children}
				</div>
			</div>
		</MainContainer>
	);
}
