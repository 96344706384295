import { css } from "@linaria/core";

import { Button } from "components/Button";
import { Loader } from "components/Loader";
import { useWizard } from "components/providers/WizardProvider";


interface IWizardButtonsProps {
	isNexButtontDisabled?: boolean,
	onRequestNavigateNextStep?: (navigateNextStep?: () => void) => void,
	isNextButtonLoading?: boolean,
	previousButtonText?: string,
	nextButtonText?: string,
}

export function WizardButtons({
	isNexButtontDisabled, onRequestNavigateNextStep, isNextButtonLoading, previousButtonText = "Previous", nextButtonText = "Next",
}: IWizardButtonsProps) {
	const buttonContainerStyle = css`
		display: flex;
		justify-content: space-between;
		margin: 20px 0px 0px 0px;
	`;

	const { navigateNextStep, navigatePrevStep } = useWizard();

	return (
		<div className={buttonContainerStyle}>
			<Button variant="primary" onPress={navigatePrevStep} isDisabled={!navigatePrevStep} size="lg">{previousButtonText}</Button>
			{isNextButtonLoading ? <Loader /> : (
				<Button
					variant="primary"
					isDisabled={!!isNexButtontDisabled || !navigateNextStep}
					size="lg"
					onPress={() => {
						if (onRequestNavigateNextStep)
							onRequestNavigateNextStep(navigateNextStep);
						else
							navigateNextStep?.();
					}}
				>
					{nextButtonText}
				</Button>
			)}
		</div>
	);
}
