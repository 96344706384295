import { faX } from "@fortawesome/free-solid-svg-icons";
import { css } from "@linaria/core";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { ReactNode, useRef } from "react";
import { FocusScope, Overlay, useModalOverlay } from "react-aria";
import { useNavigate } from "react-router-dom";
import { useOverlayTriggerState } from "react-stately";

import { theme } from "theme";
import { assertUnreachable } from "utils/assertUnreachable";

import { Button } from "./Button";
import { ErrorBoundary } from "./ErrorBoundary";


type IPanelSize = "sm" | "md" | "lg" | "xl";

interface IPanelProps {
	children: ReactNode,
	headerText: string,
	size: IPanelSize,
	isOpen?: boolean,
	isDismissable?: boolean,
	onRequestClose?: () => void,
}

interface IPagePanelProps {
	headerText: string,
	size: IPanelSize,
	children: ReactNode,
}


export function PagePanel({ children, ...panelProps }: IPagePanelProps) {
	const navigate = useNavigate();

	return (
		<Panel {...panelProps} isOpen isDismissable onRequestClose={() => navigate("..")}>
			{children}
		</Panel>
	);
}

export function Panel({
	children, headerText, size, onRequestClose, isDismissable = true, isOpen = true,
}: IPanelProps) {
	const state = useOverlayTriggerState({ isOpen, onOpenChange: isOpenRequest => !isOpenRequest && state.isOpen && onRequestClose?.() });
	const ref = useRef<HTMLDivElement>(null);
	const { modalProps, underlayProps } = useModalOverlay({ isDismissable }, state, ref);

	const underlayStyle = css`
		position: fixed;
		z-index: 101;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	`;

	const visibleUnderlayStyle = css`
		position: fixed;
		z-index: 100;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: ${theme.semantic.underlay};
	`;

	const foregroundStyle = css`
		background: ${theme.semantic.card};
		display: flex;
		flex-direction: column-reverse;
		justify-content: flex-end;
		height: 100%;
		padding: 16px 24px;
		gap: 24px;

		max-width: 100vw;
	`;

	const foregroundWrapperStyle = css`
		height: 100%;
	`;


	const smStyle = css`width: 420px;`;
	const mdStyle = css`width: 580px;`;
	const lgStyle = css`width: 786px;`;
	const xlStyle = css`width: 1280px;`;

	const sizeStyle = size === "sm" ? smStyle
		: size === "md" ? mdStyle
		: size === "lg" ? lgStyle
		: size === "xl" ? xlStyle
		: assertUnreachable(size, "unknown style");

	const headerTextStyle = css`
		display: block;
		font-size: 22px;
		font-weight: 700;
		word-break: break-all;
		word-wrap: break-word;
	`;

	const topRowStyle = css`
		display: flex;
		align-items: center;
		justify-content: space-between;
	`;

	return (
		<>
			{state.isOpen && (
				<Overlay>
					<AnimatePresence mode="wait">
						<>
							<motion.div
								key="visibleUnderlay"
								className={visibleUnderlayStyle}
								initial={{
									opacity: 0,
								}}
								exit={{
									opacity: 0,
									transition: {
										type: "tween",
										duration: 0.15,
										ease: "circIn",
									},
								}}
								animate={{
									opacity: 1,
									transition: {
										type: "tween",
										duration: 0.15,
										ease: "circOut",
									},
								}}
							/>
							<div
								key="invisibleUnderlay"
								className={underlayStyle}
								{...underlayProps}
							>
								<FocusScope contain autoFocus>
									<div
										{...modalProps}
										ref={ref}
										className={foregroundWrapperStyle}
										onClick={e => e.stopPropagation()}
									>
										<motion.div
											className={classNames(foregroundStyle, sizeStyle)}
											initial={{
												transform: "translateX(100%)",
											}}
											animate={{
												transform: "translateX(0)",
												transition: {
													type: "tween",
													duration: size === "xl" ? 0.35 : size === "lg" ? 0.25 : size === "md" ? 0.25 : size === "sm" ? 0.15 : assertUnreachable(size, "Unknown size"),
													ease: "backIn",
												},
											}}
										>
											<ErrorBoundary>
												{children}
											</ErrorBoundary>
											<div className={topRowStyle}>
												<h1 className={headerTextStyle}>{headerText}</h1>
												<Button variant="flat" leftIconProps={{ icon: faX }} onPress={() => (isDismissable ? state.close() : undefined)} />
											</div>
										</motion.div>
									</div>
								</FocusScope>
							</div>
						</>
					</AnimatePresence>
				</Overlay>
			)}
		</>
	);
}
