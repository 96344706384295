// eslint-disable-next-line no-restricted-imports
import { css } from "@linaria/core";
import { StrictMode } from "react";
import { BrowserRouter } from "react-router-dom";

import { GlobalToastRegion } from "components/Toast";
import { AuthProvider } from "components/providers/AuthProvider";
import QueryClientProvider from "components/providers/QueryClientProvider";
import { ReactAriaSSRProvider } from "components/providers/ReactAriaSSRProvider";
import { lightThemeStyleFragment, theme } from "theme";

import { SiteRoutes } from "./Routes";


void css`
	:global() {
		html {
			@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;400;500;600;700&display=swap');
			font-family: Inter, sans-serif;
			font-size: 14px;
			font-weight: normal;

			height: 100%;
			min-height: 100% !important;
			overflow-x: hidden;

			box-sizing: border-box;
				
			color: ${theme.semantic.foreground};
			text-decoration: none;

			/* @media (min-width: ${theme.breakPoints.lgMin}px) {
				font-size: 0.875rem;
			} */

			@supports (scrollbar-gutter: stable) {
				overflow-y: auto;
				scrollbar-gutter: stable;
			}

			@supports (overflow-y: overlay) {
				overflow-y: overlay;
				scrollbar-gutter: auto;
			}
		}

		body {
			height: 100%;
			min-height: 100%;
		}

		#react-root {
			height: 100%;
			min-height: 100%;
			background-color: ${theme.semantic.background};
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			overflow: hidden;
			position: relative;
		}

		html, ::backdrop {
			${lightThemeStyleFragment}
		}

		a, input, textarea {
			color: ${theme.semantic.foreground};
			text-decoration: none;
		}

		h2 {
			font-size: 1.2rem;
			font-weight: 500;
		}

		* {
			box-sizing: border-box;
		}

		/* Works on Firefox */
		* {
			scrollbar-width: thin;
			scrollbar-color: ${theme.semantic.scrollbarThumb} ${theme.semantic.scrollbarTrack};
		}

		/* Works on Chrome, Edge, and Safari */
		*::-webkit-scrollbar {
			width: 8px;
			height: 8px;
		}

		*::-webkit-scrollbar-track {
			background: ${theme.semantic.scrollbarTrack};
		}

		*::-webkit-scrollbar-thumb {
			background-color: ${theme.semantic.scrollbarThumb};
			border-radius: 20px;
			border: 2px solid transparent;
		}

	}
`;


export function App() {
	return (
		<StrictMode>
			<BrowserRouter>
				<QueryClientProvider>
					<AuthProvider>
						{/* <DarkModeProvider> */}
						<ReactAriaSSRProvider>
							<SiteRoutes />
							<GlobalToastRegion />
						</ReactAriaSSRProvider>
						{/* </DarkModeProvider> */}
					</AuthProvider>
				</QueryClientProvider>
			</BrowserRouter>
		</StrictMode>
	);
}
