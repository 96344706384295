import { Navigate } from "react-router-dom";
// import speedDashSvgSrc from "../../public/speed-dash.svg"

export default function HomePage() {
	return <Navigate to="/auth/login" />;

	/*
	const contentStyle = css`
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		padding-top: 86px;
		min-height: 100vh;
		margin-bottom: 100px;
	`;

	const contactStyle = css`
		padding: 100px 0;
		margin-top: 180px;
		display: flex;
		aspect-ratio: 7 / 1;
		width: 100%;
		align-items: center;
		justify-content: center;
		position: relative;
		`;

	const contactBoxStyle = css`
		display: flex;
		flex-direction: column;
		background: rgba(255, 255, 255, 0.2);
		backdrop-filter: blur(5px);
		padding: 20px 50px;
		color: ${theme.palette.white};
		border-radius: 4px;
	`;

	return (
		<BaseLayout>
			<div className={contentStyle}>
				<PageContainer>
					<Hero />
				</PageContainer>
				<SellingPoints />
				<div className={contactStyle}>
					<div className={contactBoxStyle}>
						<h3>We are ready for innovation. Are you?</h3>
						<Button variant="primary" href="/auth/login">Contact us</Button>
					</div>
					<Image
						src={speedDashSvgSrc}
						alt="speed dash"
						fill
						// eslint-disable-next-line react/forbid-component-props
						style={{ zIndex: -1 }}
					/>
				</div>
			</div>
		</BaseLayout>
	);
	*/
}
