import { faChevronLeft, faChevronRight, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CalendarDate, createCalendar, getLocalTimeZone, getWeeksInMonth, parseDate } from "@internationalized/date";
import { css } from "@linaria/core";
import classNames from "classnames";
import { ReactNode, useMemo, useRef } from "react";
import {
	AriaDateFieldProps,
	AriaDialogProps,
	AriaButtonProps,
	AriaCalendarProps,
	DateValue,
	useButton,
	useDateField,
	useDateSegment,
	useLocale,
	useCalendar,
	useCalendarCell,
	useCalendarGrid,
	useDialog,
	useDatePicker,
} from "react-aria";
import { CalendarState, DateFieldState, DateSegment as IDateSegment, useDateFieldState, useCalendarState, useDatePickerState } from "react-stately";

import { theme } from "theme";

import { PopoverBase } from "./Popover";
import { IValidationState } from "./formComponents/types";

// TODO fix time zone issue. Client and server might not be in correct time zone

export interface IDatePickerProps {
	isRequired?: boolean,
	validationState?: IValidationState,
	isDisabled?: boolean,
	errorMessage?: string,
	name?: string,
	onChange?: (value: Date) => void,
	value?: Date,
	label?: string,
	minValue?: Date,
	maxValue?: Date,
}

export function DatePicker({
	value, onChange, minValue: minValueDate, maxValue: maxValueDate, ...props
}: IDatePickerProps) {
	const minValue = useMemo(() => (minValueDate ? parseDate(minValueDate.toLocaleDateString()) : undefined), [minValueDate]);
	const maxValue = useMemo(() => (maxValueDate ? parseDate(maxValueDate.toLocaleDateString()) : undefined), [maxValueDate]);

	const state = useDatePickerState({
		...props,
		onChange: val => onChange?.(val.toDate(getLocalTimeZone())),
		value: value ? parseDate(value.toLocaleDateString()) : undefined,
		minValue,
		maxValue,
	});

	const ref = useRef(null);

	const {
		groupProps,
		labelProps,
		fieldProps,
		buttonProps,
		dialogProps,
		calendarProps,
		errorMessageProps,
	} = useDatePicker(props, state, ref);

	const containerStyle = css`
		display: flex;
		flex-direction: column;
		width: 100%;
	`;

	const groupStyle = css`
		display: flex;
		width: 100%;
	`;

	const labelStyle = css`
		padding: 5px 0;
		font-size: 14px;
		font-weight: 600;
		display: inline-block;
	`;

	const labelIsRequiredStyle = css`
		&::after {
			content: " *";
			color: ${theme.semantic.requiredAsterisk};
			padding-right: 12px;
		}
	`;

	const errorMessageStyle = css`
		margin-top: 5px;
		color: ${theme.semantic.errorColor};
		font-size: 12px;
		word-break: break-all;
		word-wrap: break-word;
		min-width: 100%;
    	width: 0;

		height: 0;
		opacity: 0;

		@keyframes entry {

			to {
				height: auto;
				opacity: 1;
			}
		}

		animation: entry 100ms forwards;
	`;

	return (
		<div className={containerStyle}>
			<div {...labelProps} className={classNames(labelStyle, { [labelIsRequiredStyle]: props.isRequired })}>{props.label}</div>
			<div {...groupProps} ref={ref} className={groupStyle}>
				<DateField {...fieldProps} />
				<DatePickerButton {...buttonProps} validationState={props.validationState}><FontAwesomeIcon icon={faCalendarAlt} size="sm" /></DatePickerButton>
			</div>
			{props.errorMessage && (
				<div {...errorMessageProps} className={errorMessageStyle}>
					{props.errorMessage}
				</div>
			)}
			{state.isOpen && (
				<PopoverBase state={state} triggerRef={ref} placement="bottom start" offset={6}>
					<CalendarDialog {...dialogProps}>
						<CalendarComponent {...calendarProps} minValue={minValue} maxValue={maxValue} />
					</CalendarDialog>
				</PopoverBase>
			)}
		</div>
	);
}

interface IDatePickerButtonProps extends AriaButtonProps<"button"> {
	validationState?: IValidationState,
}

function DatePickerButton(props: IDatePickerButtonProps) {
	const ref = useRef(null);
	const { buttonProps } = useButton(props, ref);

	const buttonStyle = css`
		cursor: pointer;
		background-color: ${theme.semantic.accessoryButton};
		border: 1px solid ${theme.semantic.acessoryButtonBorder};
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;
		outline-color: ${theme.semantic.fieldOutline};
		&:hover {
			background-color: ${theme.semantic.accessoryButtonHover};
		}
		padding: 0 8px;

		border-left: none;
	`;

	const buttonIsInvalidStyle = css`
		&, &:hover, &:focus {
			border-color: ${theme.semantic.errorColor};
			outline-color: ${theme.semantic.errorColor};
		}
	`;

	return <button type="button" {...buttonProps} ref={ref} className={classNames(buttonStyle, { [buttonIsInvalidStyle]: props.validationState === "invalid" })}>{props.children}</button>;
}


function DateField(props: AriaDateFieldProps<DateValue>) {
	const { locale } = useLocale();

	const state = useDateFieldState({
		...props,
		locale,
		createCalendar,
	});

	const ref = useRef(null);
	const { labelProps, fieldProps } = useDateField(props, state, ref);

	const inputStyle = css`
		padding: 0px 8px;
		line-height: 16px;
		font-size: 14px;
		height: 32px;
		border-top-left-radius: 6px;
		border-bottom-left-radius: 6px;
		border: 1px solid ${theme.semantic.fieldBorder};
		background-color: ${theme.semantic.field};
		outline-color: ${theme.semantic.fieldOutline};
		transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;
		display: flex;
		align-items: center;
		cursor: pointer;

		&:hover {
			background-color: ${theme.semantic.fieldHover};
			border: 1px solid ${theme.semantic.fieldBorderHover};
		}
	`;

	const wrapperStyle = css`
		width: 100%;
	`;

	const inputIsInvalidStyle = css`
		&, &:hover, &:focus {
			border-color: ${theme.semantic.errorColor};
			outline-color: ${theme.semantic.errorColor};
		}
	`;

	return (
		<div className={wrapperStyle}>
			<span {...labelProps}>{props.label}</span>
			<div {...fieldProps} ref={ref} className={classNames(inputStyle, { [inputIsInvalidStyle]: props.validationState === "invalid" })}>
				{state.segments.map((segment, i) => (
					<DateSegment key={i} segment={segment} state={state} />
				))}
			</div>
		</div>
	);
}

interface IDateSegmentProps {
	segment: IDateSegment,
	state: DateFieldState,
}

function DateSegment({ segment, state }: IDateSegmentProps) {
	const ref = useRef(null);
	const { segmentProps } = useDateSegment(segment, state, ref);

	const segmentStyle = css`
		outline-color: ${theme.semantic.fieldOutline};
	`;

	return (
		<div
			{...segmentProps}
			ref={ref}
			className={segmentStyle}
		>
			{segment.text}
		</div>
	);
}


interface IDialogProps extends AriaDialogProps {
	title?: ReactNode,
	children: ReactNode,
}

function CalendarDialog({ title, children, ...props }: IDialogProps) {
	const ref = useRef(null);
	const { dialogProps, titleProps } = useDialog(props, ref);

	const divStyle = css`
		background-color: ${theme.semantic.background};
		padding: 30px;
		border: 1px solid ${theme.semantic.border};
		border-radius: 4px;
	`;

	return (
		<div {...dialogProps} ref={ref} className={divStyle}>
			{title && (
				<h3 {...titleProps} style={{ marginTop: 0 }}>
					{title}
				</h3>
			)}
			{children}
		</div>
	);
}


function CalendarComponent(props: AriaCalendarProps<DateValue>) {
	const { locale } = useLocale();

	const state = useCalendarState({
		...props,
		locale,
		createCalendar,
	});

	const { calendarProps, prevButtonProps, nextButtonProps, title } = useCalendar(
		props,
		state
	);

	const calendarStyle = css`
		
	`;

	const headerStyle = css`
		
	`;

	const buttonGroupStyle = css`
		display: flex;
		gap: 8px;
		justify-content: flex-end;
	`;

	const h2Style = css`
		margin-bottom: 5px;
		margin-top: 0;
	`;

	return (
		<div {...calendarProps} className={calendarStyle}>
			<div className={headerStyle}>
				<h2 className={h2Style}>{title}</h2>
				<div className={buttonGroupStyle}>
					<CalendarPaginationButton {...prevButtonProps}><FontAwesomeIcon icon={faChevronLeft} /></CalendarPaginationButton>
					<CalendarPaginationButton {...nextButtonProps}><FontAwesomeIcon icon={faChevronRight} /></CalendarPaginationButton>
				</div>
			</div>
			<CalendarGrid state={state} />
		</div>
	);
}

function CalendarPaginationButton(props: AriaButtonProps<"button">) {
	const buttonStyle = css`
		cursor: pointer;
		background-color: ${theme.semantic.accessoryButton};
		border: 1px solid ${theme.semantic.acessoryButtonBorder};
		outline-color: ${theme.semantic.fieldOutline};
		&:hover {
			background-color: ${theme.semantic.accessoryButtonHover};
		}
		padding: 4px 8px;
		margin-bottom: 12px;
	`;

	const ref = useRef(null);
	const { buttonProps } = useButton(props, ref);
	return <button type="button" {...buttonProps} ref={ref} className={buttonStyle}>{props.children}</button>;
}


interface ICalendarGridProps {
	state: CalendarState,
}

function CalendarGrid({ state, ...props }: ICalendarGridProps) {
	const { locale } = useLocale();
	const { gridProps, headerProps, weekDays } = useCalendarGrid(props, state);

	// Get the number of weeks in the month so we can render the proper number of rows.
	const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);
	const weeksInMonthArray: number[] = Array(weeksInMonth).keys() as unknown as number[];

	return (
		<table {...gridProps}>
			<thead {...headerProps}>
				<tr>
					{weekDays.map((day, index) => <th key={index}>{day}</th>)}
				</tr>
			</thead>
			<tbody>
				{[...weeksInMonthArray].map(weekIndex => (
					<tr key={weekIndex}>
						{state.getDatesInWeek(weekIndex).map((date, i) => (
							date
								? (
									<CalendarCell
										key={i}
										state={state}
										date={date}
									/>
								)
								: <td key={i} />
						))}
					</tr>
				))}
			</tbody>
		</table>
	);
}

interface ICalendarCellProps {
	state: CalendarState,
	date: CalendarDate,
}

function CalendarCell({ state, date }: ICalendarCellProps) {
	const ref = useRef(null);

	const {
		cellProps,
		buttonProps,
		isSelected,
		isOutsideVisibleRange,
		isDisabled,
		isUnavailable,
		formattedDate,
		isFocused,
		isInvalid,
	} = useCalendarCell({ date }, state, ref);

	const divStyle = css`
		padding: 8px 8px;
		text-align: center;
		cursor: pointer;
		&:hover{
			background-color: ${theme.semantic.accessoryButtonHover};
		}
	`;

	const divIsSelectedStyle = css`
		background-color: ${theme.palette.blue};
		color: ${theme.palette.white};

		&:hover{
			color: ${theme.semantic.foreground}
		}
	`;

	const divIsUnavailableStyle = css`
		opacity: 0.4;

		&:hover {
			background-color: transparent;
		}
	`;

	const divIsInvalidStyle = css`
		opacity: 0.4;

		&:hover {
			background-color: transparent;
		}
	`;

	const divIsDisabledStyle = css`
		opacity: 0.4;

		&:hover {
			background-color: transparent;
		}
	`;

	const divIsFocusedStyle = css`
		outline-color: ${theme.semantic.fieldOutline};
	`;

	return (
		<td {...cellProps}>
			<div
				{...buttonProps}
				ref={ref}
				hidden={isOutsideVisibleRange}
				className={classNames(divStyle, {
					[divIsSelectedStyle]: isSelected,
					[divIsUnavailableStyle]: isUnavailable,
					[divIsDisabledStyle]: isDisabled,
					[divIsFocusedStyle]: isFocused,
					[divIsInvalidStyle]: isInvalid,
				})}
			>
				{formattedDate}
			</div>
		</td>
	);
}
