import { faArrowRight, faCalendar, faTrash } from "@fortawesome/free-solid-svg-icons";
import { css } from "@linaria/core";
import classNames from "classnames";
import { useMemo } from "react";
import { useMutation } from "react-query";

import { Button } from "components/Button";
import { ErrorBoundary } from "components/ErrorBoundary";
import { Image } from "components/Image";
import { LabelValuePair } from "components/LabelValuePair";
import { Loader } from "components/Loader";
import { Separator } from "components/Separator";
import { toastQueue } from "components/Toast";
import { ApiError, DefaultService, Order, OrderRowGarmentImageMetadata, User, extractApiErrorDetail, isHandledApiError } from "httpClient";
import { theme } from "theme";
import { assertUnreachable } from "utils/assertUnreachable";
import { imageUrl } from "utils/resourcePaths";


interface IOrdersListProps {
	orders: Order[],
}

export function OrdersList({ orders }: IOrdersListProps) {
	const orderListContainerStyle = css`
		display: flex;
		flex-direction: column;
		align-items: center;
		flex: 1;
		height: 1px;
	`;

	const tableHeaderStyle = css`
		text-align: center;
		padding-bottom: 0.2rem;
	`;

	const tableSeparatorStyle = css`
		width: 70%;
	`;

	const orderListRowsContainerStyle = css`
		width: 100%;
		display: flex;
		flex-direction: column;
		overflow-y: auto;
	`;

	return (
		<div className={orderListContainerStyle}>
			<div className={tableLikeRowContainerStyle}>
				<div></div>
				<div className={tableHeaderStyle}>
					In Progress
				</div>
				<div className={tableHeaderStyle}>
					In Review
				</div>
				<div className={tableHeaderStyle}>
					Delivered
				</div>
				<div></div>
			</div>
			<div className={orderListRowsContainerStyle}>
				{orders.sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime())
					.map((x, i, { length }) => (
						<ErrorBoundary key={x.orderId}>
							<OrderItemRow
								order={x}
							// user={users.find(y => y.userId === x.createdByUserId)}
							/>
							{i < length - 1 && <div className={tableSeparatorStyle}><Separator /></div>}
						</ErrorBoundary>
					))}
			</div>
		</div>
	);
}


// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function OrderItemRow({ order, user }: { order: Order, user?: User }) {
	const deleteMutation = useMutation(() => DefaultService.deleteOrderApiOrdersOrderIdDeleteOrderDelete(order.orderId), {
		onError: (error: ApiError) => {
			toastQueue.add({ onRender: () => (extractApiErrorDetail(error) ?? error).message, type: "error" }, { timeout: 6000 });
		},
		useErrorBoundary: error => !isHandledApiError(error, 422),
	});

	const {
		numDeliveredOrderRows, numInProgressOrderRows, numPendingOrderRows,
	} = useMemo(() => {
		const orderRows = order.orderRows;
		let numDeliveredOrderRows = 0;
		let numInProgressOrderRows = 0;
		let numPendingOrderRows = 0;

		if (order.orderStatus === "DRAFT") return { numDeliveredOrderRows: "-", numInProgressOrderRows: "-", numPendingOrderRows: "-" };

		for (const orderRow of orderRows) {
			const orderRowResults = orderRow.orderRowResults;

			if (orderRow.isClosed || orderRowResults.find(x => x.isAccepted))
				numDeliveredOrderRows += 1;
			else if (orderRowResults.length === 0)
				numInProgressOrderRows += 1;
			else if (orderRowResults.find(x => x.isAccepted === null || x.isAccepted === false))
				numPendingOrderRows += 1;
		}

		return {
			numDeliveredOrderRows, numInProgressOrderRows, numPendingOrderRows,
		};
	}, [order]);

	const orderItemContainerStyle = css`
		display: flex;
		overflow: hidden;
		align-items: center;
	`;

	const orderItemInfoContainerStyle = css`
		padding: 16px;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		gap: 6px;
	`;

	const statusBadgeContainerStyle = css`
		padding: 4px 8px;
		border-radius: 4px;
		max-width: fit-content;
		font-size: 12px;
	`;

	const draftStatusStyle = css`
		background-color: ${theme.semantic.neutral};
		color: ${theme.semantic.neutralForeground};
	`;

	const inProgressStyle = css`
		background-color: ${theme.semantic.info};
		color: ${theme.semantic.infoForeground};
	`;

	const deliveredStyle = css`
		background-color: ${theme.semantic.success};
		color: ${theme.semantic.successForeground};
	`;

	const isImplicitDeliveredStatus = order.orderRows.every(x => x.isClosed || x.orderRowResults.some(y => y.isAccepted)) && order.orderStatus !== "DRAFT";

	const statusBadgeStyle = isImplicitDeliveredStatus ? deliveredStyle
		: order.orderStatus === "DELIVERED" ? deliveredStyle
		: order.orderStatus === "DRAFT" ? draftStatusStyle
		: order.orderStatus === "IN_PROGRESS" ? inProgressStyle
		: assertUnreachable(order.orderStatus, "unknown orderStatus");

	const labelValueContainerStyle = css`
		display: flex;
		flex-direction: column;
		gap: 8px;
		font-size: 12px;
		color: ${theme.semantic.foregroundMuted};
		padding-top: 0.5rem;
		max-width: 270px;
	`;

	const actionContainerStyle = css`
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		align-items: center;
		/* flex: 1 1 auto; */
		/* width: 0; */
		padding: 16px;
	`;


	const topButtonsStyle = css`
		display: flex;
		justify-content: flex-end;
	`;

	const headingStyle = css`
		display: flex;
		gap: 12px;
		align-items: center;
	`;

	const h3Style = css`
		margin: 0px;
		font-weight: normal;
	`;

	const orderItemIllustrationWrapper = css`
		/* background-color: ${theme.semantic.card}; */
		width: 100px;
		height: 100px;
		min-width: 100px;
		min-height: 100px;
		/* border-right: 1px solid ${theme.semantic.borderMuted}; */
		overflow: hidden;
		position: relative;
		padding: 20px;
	`;

	const bigNumberStyle = css`
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 1.7rem;
	`;

	return (
		<div className={tableLikeRowContainerStyle}>
			<div className={orderItemContainerStyle}>
				<div className={orderItemIllustrationWrapper}>
					<OrderHero order={order} />
				</div>
				<div className={orderItemInfoContainerStyle}>
					<div className={headingStyle}>
						<h3 className={h3Style}>{order.name}</h3>
						{order.orderStatus !== "IN_PROGRESS" && <div className={classNames(statusBadgeContainerStyle, statusBadgeStyle)}>{isImplicitDeliveredStatus ? "Complete" : order.orderStatus.replace("_", " ").toLocaleLowerCase()}</div>}
					</div>
					{/* {order.orderStatus !== "DRAFT" && (
							<OrderStatusIndicator order={order} />
						)} */}
					<div>
						<div className={labelValueContainerStyle}>
							{/* <LabelValuePair icon={faFingerprint} label="Order Id" value={order.orderId} /> */}
							{/* {user && <LabelValuePair icon={faUser} label="Created by" value={`${user.firstName} ${user.lastName}`} />}
								<LabelValuePair icon={faCalendar} label="Created at" value={new Date(order.createdDate).toDateString()} /> */}
							<LabelValuePair icon={faCalendar} label="Last modified at" value={new Date(order.modifiedDate).toDateString()} variant="split" />
						</div>
					</div>
				</div>

			</div>
			<div className={bigNumberStyle}>{numInProgressOrderRows}</div>
			<div className={bigNumberStyle}>{numPendingOrderRows}</div>
			<div className={bigNumberStyle}>{numDeliveredOrderRows}</div>
			<div className={actionContainerStyle}>
				<div className={topButtonsStyle}>
					{order.orderStatus === "DRAFT" && (
						deleteMutation.isLoading ? <Loader /> : (
							<Button
								variant="danger"
								leftIconProps={{ icon: faTrash }}
								onPress={() => deleteMutation.mutate()}
							/>
						)
					)}
				</div>
				<OrderActions order={order} alignEnd />
			</div>
		</div>
	);
}

const tableLikeRowContainerStyle = css`
	display: flex;
	width: 100%;

	> div:nth-child(1) {
		width: 45%;
	}
	> div:nth-child(2) {
		width: 12%;
	}
	> div:nth-child(3) {
		width: 12%;
	}
	> div:nth-child(4) {
		width: 12%;
	}
	> div:nth-child(5) {
		width: 19%;
	}
`;


// export function OrderCard({ order }: { order: Order }) {
// 	const orderCardContainerStyle = css`
// 		width: 16rem;
// 		background-color: ${theme.semantic.card};
// 		border-radius: 0.5rem;
// 		overflow: hidden;
// 		flex-shrink: 0;
// 	`;

// 	const orderNameStyle = css`
// 		font-weight: 700;
// 		font-size: 1.2rem;
// 		text-overflow: ellipsis;
// 		white-space: nowrap;
// 	`;

// 	const orderItemIllustrationWrapper = css`
// 		background-color: ${theme.semantic.card};
// 		aspect-ratio: 1 / 1;
// 		overflow: hidden;
// 		position: relative;
// 		padding: 26px;
// 		width: 100%;
// 	`;

// 	const infoContainerStyle = css`
// 		display: flex;
// 		flex-direction: column;
// 		gap: 1rem;
// 		padding: 1rem;
// 	`;

// 	return (
// 		<div className={orderCardContainerStyle}>
// 			<div className={orderItemIllustrationWrapper}>
// 				<OrderHero order={order} />
// 			</div>
// 			<div className={infoContainerStyle}>
// 				<div className={orderNameStyle}>{order.name}</div>
// 				<OrderStatusIndicator order={order} />
// 				<OrderActions order={order} />
// 			</div>
// 		</div>
// 	);
// }


// function OrderStatusIndicator({ order }: { order: Order }) {
// 	const {
// 		numClosed, numCompleted, numPending, numRejected, numInProgress,
// 	} = useMemo(() => {
// 		const orderRows = order.orderRows;
// 		let numClosed = 0;
// 		let numCompleted = 0;
// 		let numPending = 0;
// 		let numRejected = 0;
// 		let numInProgress = 0;

// 		for (const orderRow of orderRows) {
// 			const orderRowResults = orderRow.orderRowResults;

// 			if (orderRow.isClosed)
// 				numClosed += 1;
// 			else if (orderRowResults.length === 0)
// 				numInProgress += 1;
// 			else if (orderRowResults.find(x => x.isAccepted))
// 				numCompleted += 1;
// 			else if (orderRowResults.find(x => x.isAccepted === null))
// 				numPending += 1;
// 			else if (orderRowResults.find(x => x.isAccepted === false))
// 				numRejected += 1;
// 		}

// 		return {
// 			numClosed, numCompleted, numPending: numPending, numRejected, numInProgress,
// 		};
// 	}, [order]);

// 	const pieChartContainerStyle = css`
// 		margin: 6px 0 12px 0;
// 		display: grid;
// 	`;

// 	return (
// 		<div className={pieChartContainerStyle}>
// 			<StraightLinePieChart
// 				fragments={[
// 					{ color: theme.palette.green, name: "Completed", value: numCompleted },
// 					{ color: theme.palette.red, name: "Rejected", value: numRejected },
// 					{ color: theme.palette.blue, name: "Pending", value: numPending },
// 					{ color: theme.semantic.cardMuted, name: "In progress", value: numInProgress },
// 					{ color: theme.palette.black, name: "Closed", value: numClosed },
// 				]}
// 			/>
// 		</div>
// 	);
// }

function OrderHero({ order }: { order: Order }) {
	const orderGarmentImageMetadatas = order.orderRows
		.flatMap(x => ([x.outerGarmentImageMetadata, x.upperGarmentImageMetadata, x.lowerGarmentImageMetadata]))
		.filter((x): x is OrderRowGarmentImageMetadata => !!x);

	const displayGarmentImages = orderGarmentImageMetadatas.filter((_, i) => i < 3);

	const displayImageWrapperStyle = css`
		position: relative;
		width: 100%;
		height: 100%;
	`;

	const imgStyle = css`
		object-fit: fill;
		border-radius: 100%;
		box-shadow: ${theme.shadows.foregroundShadow};
	`;


	return (
		<>
			{/* <div className={backgroundGridWrapperStyle}>
				<BackgroundGrid />
			</div> */}
			<div className={displayImageWrapperStyle}>
				{displayGarmentImages.map((x, i) => (
					<Image
						key={`${x.garmentImageId}-${i}`}
						src={imageUrl(x.originalGarmentImageKey, "sm")}
						className={imgStyle}
						alt={x.garmentImageId}
						fill
						style={{ zIndex: 3 - i, top: 0, left: 10 + i * -10 }}
						draggable={false}
					/>
				))}
			</div>
		</>
	);
}

function OrderActions({ order, alignEnd = false }: { order: Order, alignEnd?: boolean }) {
	const orderActionsStyle = css`
		display: flex;
		gap: 20px;
	`;

	const orderActionsOrderItemVariantStyle = css`
		justify-content: flex-end;
	`;

	return (
		<div className={classNames(orderActionsStyle, { [orderActionsOrderItemVariantStyle]: alignEnd })}>
			{order.orderStatus === "DRAFT" ? (
				<Button
					variant="flat"
					href={`/app/photoshoots/create/${order.orderId}/${order.orderRows.length ? "design-outfits" : "upload-garments"}`}
					rightIconProps={{ icon: faArrowRight, marginLeft: 8 }}
				>
					Edit
				</Button>
			) : (
				<Button
					variant="primary"
					href={`/app/photoshoots/browse/${order.orderId}/details`}
				>
					Details
				</Button>
			)}
		</div>
	);
}
