import { css } from "@linaria/core";
import classNames from "classnames";
import { useRef } from "react";
import { useFocusRing, useSwitch, VisuallyHidden } from "react-aria";
import { useToggleState } from "react-stately";

import { theme } from "theme";


export interface ISwitchProps {
	label?: string,
	isDisabled?: boolean,
	isReadOnly?: boolean,
	isSelected?: boolean,
	name?: string,
	onChange?: (value: boolean) => void,
	validationState?: "valid" | "invalid",
}

export function Switch(props: ISwitchProps) {
	const state = useToggleState(props);
	const ref = useRef(null);
	const { inputProps } = useSwitch(props, state, ref);
	const { isFocusVisible, focusProps } = useFocusRing();

	const labelElementStyle = css`
		display: flex;
		align-items: center;
		user-select: none;
		font-weight: 600;
		cursor: pointer;
		padding: 5px 0;
	`;

	const labelElementDisabledStyle = css`
		opacity: 0.4;
	`;

	const switchContainerStyle = css`
		transition: fill 0.15s ease-in-out;
		fill: ${theme.semantic.switch};
	`;

	const switchContainerActiveStyle = css`
		fill: ${theme.semantic.swichSelected};
	`;

	const switchThumbStyle = css`
		transition: transform 0.1s ease-in-out;
		fill: ${theme.semantic.switchThumb};
	`;

	const switchThumbSelectedStyle = css`
		transform: translateX(16px);
	`;

	const outlineStyle = css`
		fill: transparent;
		stroke-width: 2px;
		visibility: hidden;
	`;

	const outlineErrorStyle = css`
		stroke: ${theme.semantic.errorColor};
		visibility: visible;
	`;

	const outlineFocusStyle = css`
		stroke: ${theme.semantic.fieldOutline};
		visibility: visible;
	`;

	const svgStyle = css`
		margin-right: 4px;
	`;

	return (
		<label
			className={classNames(labelElementStyle, { [labelElementDisabledStyle]: props.isDisabled })}
		>
			<VisuallyHidden>
				<input {...inputProps} {...focusProps} ref={ref} />
			</VisuallyHidden>
			<svg
				width={40}
				height={24}
				aria-hidden="true"
				className={svgStyle}
			>
				<rect
					x={4}
					y={4}
					width={32}
					height={16}
					rx={8}
					className={classNames(switchContainerStyle, { [switchContainerActiveStyle]: state.isSelected })}
				/>
				<circle
					cx={12}
					cy={12}
					r={5}
					className={classNames(switchThumbStyle, { [switchThumbSelectedStyle]: state.isSelected })}
				/>
				<rect
					x={1}
					y={1}
					width={38}
					height={22}
					rx={11}
					className={classNames(outlineStyle, { [outlineFocusStyle]: isFocusVisible, [outlineErrorStyle]: props.validationState === "invalid" })}
				/>
			</svg>
			{props.label}
		</label>
	);
}
