import { css } from "@linaria/core";
import { ReactNode } from "react";


interface IMainContainerProps {
	children: ReactNode,
}

export function MainContainer({ children }: IMainContainerProps) {
	const containerStyle = css`
		padding: 20px 40px 20px 40px;
		height: 100%;

		max-width: 1200px;
		margin: 0 auto;
		overflow: hidden;

		display: flex;
		flex-direction: column;
	`;

	return <div className={containerStyle}>{children}</div>;
}
