import { AnimationProps } from "framer-motion";


type IEntryAnimationType = "slideUp" | "fadeIn";

export const entryAnimations: { [key in IEntryAnimationType]: AnimationProps } = {
	slideUp: {
		initial: {
			opacity: 0,
			transform: "translateY(20px)",
		},
		animate: {
			opacity: 1,
			transform: "translateY(0px)",
		},
	},
	fadeIn: {
		initial: {
			opacity: 0,
		},
		animate: {
			opacity: 1,
		},
	},
};
