import { css } from "@linaria/core";
import { ReactNode } from "react";

import { ErrorBoundary } from "components/ErrorBoundary";
import { Image } from "components/Image";

import bgSrc from "../../../../public/bg.webp";


// import { AuthSectionHero } from "./AuthSectionHero";


export function AuthLayout({ children }: { children: ReactNode }) {
	const containerStyle = css`
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	`;

	const contentStyle = css`
		margin-top: 120px;
		position: relative;
	`;

	return (
		<div className={containerStyle}>
			<Image alt="background" src={bgSrc} fill hideShimmer />
			<div className={contentStyle}>
				{/* <AuthSectionHero /> */}
				<ErrorBoundary>
					{children}
				</ErrorBoundary>
			</div>
		</div>
	);
}
