import { DefaultService } from "httpClient";


export function generateGetOrderQueryOptions(orderId: string) {
	return { queryKey: ["get-order", orderId], queryFn: () => DefaultService.getOrderApiOrdersOrderIdGetOrderGet(orderId) };
}

export function generateGetOrderRowQueryOptions(orderRowId: string) {
	return { queryKey: ["get-order-row", orderRowId], queryFn: () => DefaultService.getOrderRowApiOrderRowsOrderRowIdGetOrderRowGet(orderRowId) };
}


export function generateGetOrganizationOrdersQueryOptions() {
	return { queryKey: ["get-organization-orders"], queryFn: () => DefaultService.getOrganizationOrdersApiOrdersGetOrganizationOrdersGet() };
}
