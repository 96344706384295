import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "@linaria/core";
// eslint-disable-next-line import/no-extraneous-dependencies
import { useObjectRef } from "@react-aria/utils";
import classNames from "classnames";
import { ForwardedRef, ReactNode, Ref, forwardRef, useMemo } from "react";
import { AriaButtonProps, useButton } from "react-aria";
import { Link } from "react-router-dom";

import { theme } from "theme";
import { assertUnreachable } from "utils/assertUnreachable";


export interface IPressEvent {
	type: "pressstart" | "pressend" | "pressup" | "press",
	pointerType: "mouse" | "pen" | "touch" | "keyboard" | "virtual",
	target: Element,
	shiftKey: boolean,
	ctrlKey: boolean,
	metaKey: boolean,
	altKey: boolean,
}

type IIconProps = {
	icon: IconProp,
	marginRight?: number,
	marginLeft?: number,
};

export type IButtonVariant = "primary" | "large" | "default" | "link" | "flat" | "outline" | "danger" | "success";

export type IButtonProps = Omit<AriaButtonProps<"button">, "label"> & (
	{
		leftIconProps?: IIconProps,
		rightIconProps?: IIconProps,
		variant: IButtonVariant,
		size?: "sm" | "md" | "lg",
		children?: ReactNode,
	} | {
		leftIconProps?: undefined,
		rightIconProps?: undefined,
		variant: "normalized",
		size?: undefined,
		children?: ReactNode,
	}
);


export const Button = forwardRef(function Button({ variant = "primary", size = "md", type = "button", ...props }: IButtonProps, forwardedRef: ForwardedRef<Element>) {
	const ref = useObjectRef(forwardedRef);

	const { buttonProps } = useButton({
		elementType: props.href ? "a" : "button",
		onPress: props.onPress,
		type,
		// @ts-expect-error: Undocumented but exists @see https://github.com/adobe/react-spectrum/blob/60e30029e5627ac514a354219a6f790f5fee45f7/packages/%40react-aria/button/src/useButton.ts#L58
		preventFocusOnPress: true,
		...props,
	}, ref);

	const normalizedSizeStyle = css`
		padding: 0;
	`;

	const smStyle = css`
		padding: 4px 8px;
	`;

	const mdStyle = css`
		padding: 8px 16px;
	`;

	const lgStyle = css`
		padding: 12px 18px;
	`;

	const sizeStyle = useMemo(() => (
		variant === "normalized" ? normalizedSizeStyle
		: size === "sm" ? smStyle
		: size === "md" ? mdStyle
		: size === "lg" ? lgStyle
		: assertUnreachable(size, "unknown button size"))
	, [lgStyle, mdStyle, normalizedSizeStyle, size, smStyle, variant]);

	const normalizeStyle = css`
		border: none;
		background-color: transparent;
		vertical-align: middle;
		text-align: unset;
		color: unset;
		outline: 0px;
		width: auto;

		&:focus-visible {
			outline: auto;
		}

		&.disabled {
			cursor: no-drop;
		}
	`;

	const className = useMemo(() => (
		variant === "primary" ? primaryButtonStyle
		: variant === "large" ? largeButtonStyle
		: variant === "default" ? defaultButtonStyle
		: variant === "link" ? linkStyle
		: variant === "flat" ? flatButtonStyle
		: variant === "outline" ? outlineButtonStyle
		: variant === "danger" ? dangerButtonStyle
		: variant === "success" ? successButtonStyle
		: variant === "normalized" ? undefined
		: assertUnreachable(variant, "Uknown button variant"))
	, [variant]);

	const innerElement = useMemo(() => (variant === "normalized" ? <>{props.children}</> : (
		<>
			{props.leftIconProps && (
				<FontAwesomeIcon
					icon={props.leftIconProps.icon}
					style={{ marginRight: props.leftIconProps.marginRight, marginLeft: props.leftIconProps.marginLeft }}
				/>
			)}
			{props.children}
			{props.rightIconProps && (
				<FontAwesomeIcon
					icon={props.rightIconProps.icon}
					style={{ marginRight: props.rightIconProps.marginRight, marginLeft: props.rightIconProps.marginLeft }}
				/>
			)}
		</>
	)), [props.children, props.leftIconProps, props.rightIconProps, variant]);

	return props.href !== undefined ? (
		<Link {...buttonProps} ref={ref as Ref<HTMLAnchorElement>} to={props.href} className={classNames(normalizeStyle, className, sizeStyle, { disabled: props.isDisabled })}>
			{innerElement}
		</Link>
	) : (
		// eslint-disable-next-line react/button-has-type
		<button {...buttonProps} ref={ref as Ref<HTMLButtonElement>} type={type} className={classNames(normalizeStyle, className, sizeStyle, { disabled: props.isDisabled })}>
			{innerElement}
		</button>
	);
});


const linkStyle = css`
	padding: 0;
	cursor: pointer;
	transition: color 0.2s ${theme.timingFunctions.default};
	&:hover {
		color: ${theme.semantic.foregroundMuted}
	}

	&.disabled {

	}
`;

const defaultButtonStyle = css`
	display: block;
	position: relative;
	border: none;
	cursor: pointer;
	color: ${theme.semantic.foreground};
	text-align: center;
	font-weight: 600;

	&::after {
		content: '';
		position: absolute;
		inset: 0;

		transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
		border-radius: 4px;
		z-index: -1;
	}

	&:hover::after {
		transform: scale(1.2);
		border: 1px solid ${theme.semantic.defaultButtonBorderHover};
	}

	&:focus::after {
		outline-width: 1px;
		outline-style: solid;
	}

	&.disabled {
		border-radius: 4px;
		background-color: ${theme.semantic.disabledButton};
		color: ${theme.semantic.disabledButtonForeground};
		cursor: no-drop;

		&::after, &:hover::after, &:focus::after {
			display: none;
		}
	}
`;

const largeButtonStyle = css`
	display: block;
	position: relative;
	background-color: transparent;
	border: none;
	cursor: pointer;
	color: ${theme.palette.white};
	text-align: center;
	z-index: 1;
	flex: 1;

	&::after {
		content: '';
		position: absolute;
		inset: 0;
		background-color: ${theme.palette.black};

		transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
		border-radius: 4px;
		z-index: -1;
	}

	&:hover::after {
		transform: scale(1.2);
	}

	&:focus {
		outline: none;
	}

	&:focus::after {
		outline-width: 1px;
		outline-style: solid;
	}

	&.disabled {
		border-radius: 4px;
		background-color: ${theme.semantic.disabledButton};
		color: ${theme.semantic.disabledButtonForeground};
		cursor: no-drop;

		&::after, &:hover::after, &:focus::after {
			display: none;
		}
	}
`;

const primaryButtonStyle = css`
	display: block;
	position: relative;
	background-color: transparent;
	border: none;
	cursor: pointer;
	color: ${theme.palette.white};
	text-align: center;
	z-index: 1;

	&::after {
		content: '';
		position: absolute;
		inset: 0;
		background-color: ${theme.palette.black};

		transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
		border-radius: 4px;
		z-index: -1;
	}

	&:hover::after {
		transform: scale(1.2);
	}

	&:focus {
		outline: none;
	}

	&:focus::after {
		outline-width: 1px;
		outline-style: solid;
	}

	&.disabled {
		border-radius: 4px;
		background-color: ${theme.semantic.disabledButton};
		color: ${theme.semantic.disabledButtonForeground};
		cursor: no-drop;

		&::after, &:hover::after, &:focus::after {
			display: none;
		}
	}
`;

const flatButtonStyle = css`
	display: block;
	position: relative;
	border: none;
	cursor: pointer;
	text-align: center;
	transition: background-color 0.2s ${theme.timingFunctions.default};
	background-color: transparent;
	border-radius: 4px;
	font-weight: 600;

	&:hover{
		background-color: ${theme.semantic.flatButtonHoverColor};
	}

	&.disabled {
		border-radius: 4px;
		background-color: ${theme.semantic.disabledButton};
		color: ${theme.semantic.disabledButtonForeground};
		cursor: no-drop;
	}
`;

const outlineButtonStyle = css`
	display: block;
	position: relative;
	border: none;
	cursor: pointer;
	text-align: center;
	transition: background-color 0.2s ${theme.timingFunctions.default};
	background-color: transparent;
	border-radius: 4px;
	border: 1px solid ${theme.semantic.outlineButtonBorder};
	font-weight: 600;

	&:hover{
		background-color: ${theme.semantic.flatButtonHoverColor};
	}

	&.disabled {
		border-radius: 4px;
		background-color: ${theme.semantic.disabledButton};
		color: ${theme.semantic.disabledButtonForeground};
		cursor: no-drop;
	}
`;

const dangerButtonStyle = css`
	cursor: pointer;
	display: block;
	background-color: ${theme.semantic.dangerButton};
	color: ${theme.semantic.dangerButtonForeground};
	border: 2px solid ${theme.semantic.dangerButtonBorder};
	text-align: center;
	transition: background-color 0.2s ${theme.timingFunctions.default};
	border-radius: 4px;

	&:hover{
		background-color: ${theme.semantic.dangerButtonHover};
		color: ${theme.semantic.dangerButtonForegroundHover};
		border: 2px solid ${theme.semantic.dangerButtonBorderHover};
	}

	&.disabled {
		border-radius: 4px;
		background-color: ${theme.semantic.disabledButton};
		color: ${theme.semantic.disabledButtonForeground};
		cursor: no-drop;
	}
`;


const successButtonStyle = css`
	cursor: pointer;
	display: block;
	background-color: ${theme.semantic.successButton};
	color: ${theme.semantic.successButtonForegroundColor};
	border: 2px solid ${theme.semantic.successButtonBorder};
	text-align: center;
	transition: background-color 0.2s ${theme.timingFunctions.default};
	border-radius: 4px;

	&:hover{
		background-color: ${theme.semantic.successButtonHover};
		color: ${theme.semantic.successButtonForegroundHover};
		border: 2px solid ${theme.semantic.successButtonBorderHover};
	}

	&.disabled {
		border-radius: 4px;
		background-color: ${theme.semantic.disabledButton};
		color: ${theme.semantic.disabledButtonForeground};
		cursor: no-drop;
	}
`;
