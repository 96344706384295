import { css } from "@linaria/core";
import { useQuery } from "react-query";
import { Navigate, Outlet, useLocation, useParams, matchPath } from "react-router-dom";

import { WeonLoadingOverlay } from "components/LoadingOverlay";
import { Separator } from "components/Separator";
import { H1 } from "pages/app/(components)/H1";
import { MainContainer } from "pages/app/(components)/MainContainer";
import { invariant } from "utils/invariant";
import { generateGetOrderQueryOptions } from "utils/react-query/data-fetching/order";
import { ensureIsNotError } from "utils/react-query/ensureIsNotError";
import { isNotLoadingOrIdle } from "utils/react-query/isNotLoadingOrIdle";


export default function PhotoshootPage() {
	const { orderId } = useParams();
	invariant(orderId, "orderId must be defined");

	const location = useLocation();
	// const navigate = useNavigate();

	const orderQuery = useQuery(generateGetOrderQueryOptions(orderId));
	ensureIsNotError(orderQuery);

	// const tabs: ITab[] = useMemo(() => [{
	// 	key: "details",
	// 	heading: <TabHeading icon={faFileLines} label="Details" />,
	// }, {
	// 	key: "review",
	// 	heading: <TabHeading icon={faSearch} label="Review" />,
	// }], []);

	// const selectedKey = useMemo(() => {
	// 	return tabs.find(x => matchPath({
	// 		path: `/app/photoshoots/browse/:orderId/${x.key}`,
	// 		caseSensitive: false,
	// 		end: false,
	// 	}, location.pathname))?.key ?? null;
	// }, [location.pathname, tabs]);

	if (!matchPath({ path: "/app/photoshoots/browse/:orderId/details", caseSensitive: false, end: false }, location.pathname)) // (!selectedKey)
		return <Navigate to="details" replace />;

	if (!isNotLoadingOrIdle(orderQuery))
		return <WeonLoadingOverlay />;

	const tabsWrapperStyle = css`
		margin-bottom: 20px;
	`;

	return (
		<MainContainer>
			<H1>{orderQuery.data.name}</H1>
			<Separator space={20} />
			<div className={tabsWrapperStyle}>
				{/* <Tabs
					selectedKey={selectedKey}
					onTabChange={key => navigate(key.toString(), { replace: true })}
					tabs={tabs}
				/> */}
			</div>
			<Outlet />
		</MainContainer>
	);
}

// interface ITabHeadingProps {
// 	icon?: IconProp,
// 	label: string,
// }

// function TabHeading({ icon, label }: ITabHeadingProps) {
// 	const tabheadingContainerStyle = css`
// 		display: flex;
// 		align-items: center;
// 		justify-content: flex-start;
// 	`;

// 	const iconStyle = css`
// 		margin-right: 8px;
// 	`;


// 	return (
// 		<div className={tabheadingContainerStyle}>
// 			{icon && <FontAwesomeIcon size="lg" icon={icon} className={iconStyle} />}
// 			{label}
// 		</div>
// 	);
// }
