import { useFormContext } from "react-hook-form";

import { Loader as LoaderComponent, ILoaderProps } from "components/Loader";


export function Loader(props: ILoaderProps) {
	const { formState: { isSubmitting } } = useFormContext();

	return isSubmitting ? <LoaderComponent {...props} /> : <></>;
}
