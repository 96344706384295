import { css } from "@linaria/core";

import Link from "components/Link";
import { theme } from "theme";

import weonLogoBlackSvgSrc from "../../../../public/icons/weon-logo-black.svg";


export default function Footer() {
	// const { logout } = useAuth();

	const footerStyle = css`
		display: flex;
		align-items: center;
	`;


	const navWrapperStyle = css`
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 16px;
		color: ${theme.palette.white};
	`;

	const weonLogoWrapperStyle = css`
		margin-bottom: 8px;
	`;

	// const innerButtonStyle = css`
	// 	display: flex;
	// 	align-items: center;
	// `;

	// const iconStyle = css`
	// 	margin-right: 16px;
	// `;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
	const WeonLogoBlackSvgSrc = weonLogoBlackSvgSrc as any;

	return (
		<header className={footerStyle}>
			<div className={navWrapperStyle}>
				<div className={weonLogoWrapperStyle}>
					<Link href="">
						<WeonLogoBlackSvgSrc width="102px" height="32px" />
					</Link>
					{/* <Image height={22} width={72} src={weonLogoSvgSrc} alt="logo" /> */}
				</div>

				{/* <MenuButton
					variant="flat"
					popoverPlacement="bottom end"
					leftIconProps={{ icon: faGear, marginRight: 5 }}
					rightIconProps={{ icon: faChevronDown, marginLeft: 5 }}
					items={[
						{
							key: "Report a problem",
							onRender: key => (
								<span className={innerButtonStyle}>
									<FontAwesomeIcon icon={faBug} className={iconStyle} />
									{key}
								</span>
							),
							onClick: () => {
								location.assign("mailto:info@weon.ai");
							},
						},

						{
							key: "Log out group",
							items: [{
								key: "Log out",
								onRender: key => (
									<span className={innerButtonStyle}>
										<FontAwesomeIcon icon={faArrowRightFromBracket} className={iconStyle} />
										{key}
									</span>
								),
								onClick: () => logout(),
							}],
						},
					]}
				>
					<></>
				</MenuButton> */}
			</div>
		</header>
	);
}
