/* eslint-disable import/no-restricted-paths */
import * as yup from "yup";

import { ApiError } from "../generated/index";


export * from "../generated/index";

export function isHandledApiError(apiError: ApiError, handledStatusCode: number | number[]) {
	return (typeof handledStatusCode === "number" ? apiError.status === handledStatusCode : handledStatusCode.some(x => apiError.status === x));
}

export function extractApiErrorDetail(error: ApiError) {
	return extractApiErrorDetailWithData(error, yup.mixed().nullable().optional());
}

export function extractApiErrorDetailWithData<T>(error: ApiError, dataSchema: yup.Schema<T>) {
	const schema = yup.object({
		detail: yup.object({
			message: yup.string().required(),
		}).required().defined(),
	}).required().defined();

	const detailSchema = yup.object({
		data: dataSchema,
	});

	if (schema.isValidSync(error.body) && detailSchema.isValidSync(error.body.detail))
		return error.body.detail;


	const detailStringSchema = yup.object({
		detail: yup.string().required(),
	});

	if (detailStringSchema.isValidSync(error.body))
		return { message: error.body.detail, data: undefined };

	return undefined;
}
