import { useController, useFormContext } from "react-hook-form";

import { IRadioGroupProps, RadioGroup as RadioGroupComponent } from "components/RadioGroup";

import { IFormFieldProps } from "./types";


export function RadioGroup<T extends string>(props: IFormFieldProps<IRadioGroupProps<T>>) {
	const {
		name,
		isDisabled = false,
		...rest
	} = props;

	const { control, formState: { isSubmitting } } = useFormContext();

	const {
		field: {
			// Legacy
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			onChange, name: fieldName, value, onBlur,
		},
		fieldState: { error },
	} = useController({
		name, control, defaultValue: false,
	});

	return (
		<RadioGroupComponent
			{...rest}
			name={fieldName}
			// Legacy
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			value={value}
			isDisabled={isDisabled || isSubmitting}
			onChange={onChange}
			errorMessage={error?.message}
			onBlur={onBlur}
			validationState={error ? "invalid" : "valid"}
		/>
	);
}
