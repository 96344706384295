import { AnimatePresence, motion } from "framer-motion";
import { ReactNode, useState } from "react";

import { Button } from "./Button";
import { Separator } from "./Separator";


interface IAccordionProps {
	children: ReactNode,
	accordionButton: (isOpen: boolean) => ReactNode,
	startExpanded?: boolean,
}

export function Accordion({ children, accordionButton, startExpanded = false }: IAccordionProps) {
	const [isOpen, setIsOpen] = useState(startExpanded);

	return (
		<AccordionBase
			accordionButtonElement={accordionButton(isOpen)}
			isOpen={isOpen}
			setIsOpen={setIsOpen}
		>
			{children}
		</AccordionBase>
	);
}


interface IAccordionBaseProps {
	accordionButtonElement: ReactNode,
	children: ReactNode,
	isOpen: boolean,
	setIsOpen: (callback: (prev: boolean) => boolean) => void,
}


export function AccordionBase({ accordionButtonElement, children, isOpen, setIsOpen }: IAccordionBaseProps) {
	return (
		<>
			<div>
				<Button
					variant="normalized"
					onPress={() => {
						setIsOpen(prev => !prev);
					}}
				>
					{accordionButtonElement}
				</Button>
				<Separator space={5} />
			</div>
			<AnimatePresence initial={false}>
				{isOpen && (
					<motion.section
						key="content"
						initial={{
							opacity: 0,
							height: 0,
						}}
						animate={{
							opacity: 1,
							height: "auto",
						}}
						exit={{
							opacity: 0,
							height: 0,
						}}
						transition={{
							duration: 0.3,
							ease: [0.19, 1, 0.22, 1],
						}}
					>
						{children}
					</motion.section>
				)}
			</AnimatePresence>
		</>
	);
}
