/* eslint-disable @typescript-eslint/naming-convention */
import { useMemo, useState, useEffect } from "react";


export type UseMeasureRect = Pick<
	DOMRectReadOnly,
	"x" | "y" | "top" | "left" | "right" | "bottom" | "height" | "width"
>;
export type UseMeasureRef<E extends Element = Element> = (element: E | null) => void;
export type UseMeasureResult<E extends Element = Element> = [UseMeasureRef<E>, UseMeasureRect];

const defaultState: UseMeasureRect = {
	x: 0,
	y: 0,
	width: 0,
	height: 0,
	top: 0,
	left: 0,
	bottom: 0,
	right: 0,
};

function _useMeasure<E extends Element = Element>(): UseMeasureResult<E> {
	const [ref, setRef] = useState<E | null>(null);
	const [rect, setRect] = useState<UseMeasureRect>(defaultState);

	const observer = useMemo(
		() => new window.ResizeObserver(entries => {
			if (entries[0]) {
				const {
					x, y, width, height, top, left, bottom, right,
				} = entries[0].contentRect;

				setRect({
					x, y, width, height, top, left, bottom, right,
				});
			}
		}),
		[]
	);

	useEffect(() => {
		if (!ref) return;
		observer.observe(ref);

		return () => {
			observer.disconnect();
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ref]);

	return [setRef, rect];
}

export const useMeasure = typeof window !== "undefined" && typeof window.ResizeObserver !== "undefined"
	? _useMeasure
	: ((() => [() => { /* noop */ }, defaultState]) as typeof _useMeasure);
