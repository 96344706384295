import { faCopy, faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { faChevronDown, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import { css } from "@linaria/core";
import classNames from "classnames";
import { LayoutGroup, motion } from "framer-motion";
import { useEffect, useMemo, useState } from "react";
import { useFieldArray, useFormContext, useWatch, useController, UseFieldArrayReturn, FormProvider, FieldError } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import * as yup from "yup";


import { Accordion } from "components/Accordion";
import { Button } from "components/Button";
import { Clickable } from "components/Clickable";
import { Image } from "components/Image";
import { Loader } from "components/Loader";
import { WeonLoadingOverlay } from "components/LoadingOverlay";
import { MessageBar } from "components/MessageBar";
import { Panel } from "components/Panel";
import { Popover } from "components/Popover";
import { Separator } from "components/Separator";
import { toastQueue } from "components/Toast";
import { useForm } from "components/formComponents/useForm";
import { useWizard } from "components/providers/WizardProvider";
import { ApiError, BodySection, Categories, DefaultService, Garment, GarmentImage, Order, OrderRowSave, Person, extractApiErrorDetailWithData, extractApiErrorDetail, isHandledApiError, Sex } from "httpClient";
import { theme } from "theme";
import { invariant } from "utils/invariant";
import { generateGetCategoriesQueryOptions } from "utils/react-query/data-fetching/categories";
import { generateGetOrganizationGarmentsQueryOptions } from "utils/react-query/data-fetching/garment";
import { generateGetOrderQueryOptions } from "utils/react-query/data-fetching/order";
import { generateGetOrganizationPersonsQueryOptions } from "utils/react-query/data-fetching/person";
import { ensureIsNotError } from "utils/react-query/ensureIsNotError";
import { isNotLoadingOrIdle } from "utils/react-query/isNotLoadingOrIdle";
import { imageUrl } from "utils/resourcePaths";

import { ISaveOrderRowSchema, ISaveOrderRowsSchema, useSaveOrderRowSchema } from "./(components)/orderRowSchemas";
import { WizardContentLayout } from "../(components)/WizardContentLayout";
import { WizardButtons } from "../../(components)/WizardButtons";
import { WizardDescription } from "../../(components)/WizardDescription";
import IconJacketMuted from "../../../../../../../public/icons/icon-jacket-muted.svg";
import IconPantsMuted from "../../../../../../../public/icons/icon-pants-muted.svg";
import IconPersonMuted from "../../../../../../../public/icons/icon-person-muted.svg";
import IconTshirtMuted from "../../../../../../../public/icons/icon-tshirt-muted.svg";
import undrawShoppingIllustrationSvgSrc from "../../../../../../../public/undraw_shopping_re_hdd9.svg";


export default function DesignOutfitsPage() {
	return (
		<WizardContentLayout>
			<PageContent />
		</WizardContentLayout>
	);
}

function PageContent() {
	const { orderId } = useParams();
	invariant(orderId, "orderId must be defined");

	const orderQuery = useQuery(generateGetOrderQueryOptions(orderId));
	const garmentsQuery = useQuery(generateGetOrganizationGarmentsQueryOptions());
	const personsQuery = useQuery(generateGetOrganizationPersonsQueryOptions());
	const categoriesQuery = useQuery(generateGetCategoriesQueryOptions());

	ensureIsNotError(garmentsQuery);
	ensureIsNotError(orderQuery);
	ensureIsNotError(personsQuery);
	ensureIsNotError(categoriesQuery);

	if (!isNotLoadingOrIdle(orderQuery) || !isNotLoadingOrIdle(garmentsQuery) || !isNotLoadingOrIdle(personsQuery) || !isNotLoadingOrIdle(categoriesQuery))
		return <WeonLoadingOverlay />;

	return (
		<DesignOutfitsSection order={orderQuery.data} garments={garmentsQuery.data} persons={personsQuery.data} categories={categoriesQuery.data} />
	);
}


interface IMainPageProps {
	order: Order,
	garments: Garment[],
	persons: Person[],
	categories: Categories,
}

function DesignOutfitsSection({ order, garments, persons, categories }: IMainPageProps) {
	const { orderId } = useParams();
	invariant(orderId, "orderId must be defined");
	const [isPanelOpen, setIsPanelOpen] = useState(false);
	const schema = useSaveOrderRowSchema();

	const { navigateNextStep } = useWizard();

	const saveOrderRowsMutation = useMutation((data: OrderRowSave[]) => DefaultService.saveOrderRowsApiOrdersOrderIdSaveOrderRowsPut(orderId, data), {
		onError: (error: ApiError) => {
			formContext.reset();
			toastQueue.add({ onRender: () => (extractApiErrorDetail(error) ?? error).message, type: "error" }, { timeout: 6000 });

			const orderRowsErrorDetails = extractApiErrorDetailWithData(error, yup.array(yup.object({
				tuck_upper: yup.string().required().nullable(),
				outer_garment_image_id: yup.string().required().nullable(),
				upper_garment_image_id: yup.string().required().nullable(),
				lower_garment_image_id: yup.string().required().nullable(),
				person_image_id: yup.string().required().nullable(),
			})).defined());

			if (orderRowsErrorDetails?.data) {
				const orderRowErrorMessagesArray = orderRowsErrorDetails.data;

				orderRowErrorMessagesArray.forEach((x, i) => {
					if (x.tuck_upper)
						formContext.setError(`orderRows.${i}.tuckUpper`, { message: x.tuck_upper, type: "value" });
					if (x.outer_garment_image_id)
						formContext.setError(`orderRows.${i}.outerGarmentImageId`, { message: x.outer_garment_image_id, type: "value" });
					if (x.upper_garment_image_id)
						formContext.setError(`orderRows.${i}.upperGarmentImageId`, { message: x.upper_garment_image_id, type: "value" });
					if (x.lower_garment_image_id)
						formContext.setError(`orderRows.${i}.lowerGarmentImageId`, { message: x.lower_garment_image_id, type: "value" });
					if (x.person_image_id)
						formContext.setError(`orderRows.${i}.personImageId`, { message: x.person_image_id, type: "value" });
				});
			}
		},
		useErrorBoundary: error => !isHandledApiError(error, [400, 422]),
		onSuccess: () => navigateNextStep?.(),
	});


	const formContext = useForm<ISaveOrderRowsSchema>({
		resolver: yupResolver(schema),
		defaultValues: {
			orderRows: order.orderRows.map(x => ({
				orderRowId: x.orderRowId,
				outerGarmentImageId: x.outerGarmentImageMetadata?.garmentImageId,
				upperGarmentImageId: x.upperGarmentImageMetadata.garmentImageId,
				lowerGarmentImageId: x.lowerGarmentImageMetadata?.garmentImageId,
				personImageId: x.personImageMetadata.personImageId,
				tuckUpper: x.tuckUpper,
				mainGarmentId: x.mainGarmentMetadata.garmentId,
			})),
		},
		onSubmit: data => saveOrderRowsMutation.mutateAsync(data.orderRows),
	});

	const orderRowsFieldArray = useFieldArray({ control: formContext.control, name: "orderRows" });

	const [sessionMainGarmentIds, setSessionMainGarmentIds] = useState<string[]>(
		orderRowsFieldArray.fields.map(x => x.mainGarmentId).filter((x, i, self) => self.indexOf(x) === i)
	);

	useEffect(() => {
		const orderRowsMainGarmentIds = orderRowsFieldArray.fields.map(x => x.mainGarmentId).filter((x, i, self) => self.indexOf(x) === i);
		setSessionMainGarmentIds(prev => prev.filter(x => orderRowsMainGarmentIds.includes(x)).concat(orderRowsMainGarmentIds.filter(x => !prev.includes(x))));
	}, [orderRowsFieldArray.fields]);

	// const addButtonContainerStyle = css`
	// 	margin-top: 16px;
	// `;

	const garmentsContainerStyle = css`
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		overflow-y: scroll;
		overflow-x: auto;
	`;

	const orderRowsContainerStyle = css`
		flex: 1 1 auto;
		height: 0;
		overflow-y: scroll;
	`;

	const separatorWrapperStyle = css`
		padding: 0 16px 16px 16px;
	`;

	const noOrderRowsContainerStyle = css`
		position: relative;
		height: 100%;
		width: 100%;
	`;

	const noOrderRowsInnerStyle = css`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 20px;
		height: 100%;
		opacity: 0.5;
	`;

	const messageWrapperStyle = css`
		margin-top: 12px;
	`;

	const helpTextStyle = css`
		font-weight: 500;
		color: ${theme.semantic.foregroundMuted};
		font-size: 1.2rem;
		user-select: none;
		margin-bottom: 2rem;
	`;

	const headerButtonWrapperStyle = css`
		display: flex;
		justify-content: start;
		margin-bottom: 1rem;
	`;

	return (
		<FormProvider {...formContext}>

			<div className={separatorWrapperStyle}>
				<Separator space={20} />

			</div>
			<div className={headerButtonWrapperStyle}>
				<Button size="lg" variant="outline" leftIconProps={{ icon: faPlus, marginRight: 12 }} onPress={() => setIsPanelOpen(true)}>Add Garment</Button>
			</div>
			<motion.div layout layoutRoot layoutScroll className={orderRowsContainerStyle}>
				{orderRowsFieldArray.fields.length ? (
					<>
						<LayoutGroup>
							{[...sessionMainGarmentIds].reverse().map((mainGarmentId, i, { length }) => (
								<OrderRowGroupSection
									key={mainGarmentId}
									garments={garments}
									mainGarmentId={mainGarmentId}
									persons={persons}
									categories={categories}
									isLast={i === length - 1}
									orderRowsFieldArray={orderRowsFieldArray}
								/>
							))}
						</LayoutGroup>

					</>
				) : (
					<>

						<div className={noOrderRowsContainerStyle}>
							{/* <div className={noOrderRowsAddGarmentButtonStyle}>
								<Button size="lg" variant="outline" leftIconProps={{ icon: faPlus, marginRight: 12 }} onPress={() => setIsPanelOpen(true)}>Add Garment</Button>
							</div> */}
							<div className={noOrderRowsInnerStyle}>
								<Image src={undrawShoppingIllustrationSvgSrc} height={320} width={320} alt="no orders illustration" />
								<div className={helpTextStyle}>No photos yet. Get started by adding a garment.</div>
							</div>
						</div>
					</>
				)}

			</motion.div>

			{saveOrderRowsMutation.isError && saveOrderRowsMutation.error instanceof ApiError
				&& (
					<div className={messageWrapperStyle}>
						<MessageBar
							type="error"
						>
							{(extractApiErrorDetail(saveOrderRowsMutation.error) ?? saveOrderRowsMutation.error).message}
						</MessageBar>
					</div>
				)}
			<WizardDescription
				descriptionIconProp={faQuestionCircle}
				descriptionText="Design your photoshoot by adding garments here. Each row will generate one photo showcasing the circular garment seen on the left."
			/>

			<WizardButtons
				isNexButtontDisabled={!formContext.formState.isValid || formContext.formState.isSubmitting}
				onRequestNavigateNextStep={() => {
					void formContext.onSubmit?.();
				}}
			/>
			<Panel headerText="Choose garment" size="xl" isOpen={isPanelOpen} onRequestClose={() => setIsPanelOpen(false)}>
				<div className={garmentsContainerStyle}>
					<GarmentSelectionSection
						garments={garments}
						onClickGarment={garment => {
							const garmentImage = Object.values(garment.garmentImages)[0];
							invariant(garmentImage, "garmentImage must be defined");

							orderRowsFieldArray.append({
								mainGarmentId: garment.garmentId,
								upperGarmentImageId: categories[garment.categoryName]?.bodySection === "WholeBody" || categories[garment.categoryName]?.bodySection === "UpperBody" ? garmentImage.garmentImageId : undefined,
								lowerGarmentImageId: categories[garment.categoryName]?.bodySection === "LowerBody" ? garmentImage.garmentImageId : undefined,
								outerGarmentImageId: undefined,
								personImageId: undefined,
								tuckUpper: false,
							} as unknown as ISaveOrderRowSchema);

							setIsPanelOpen(false);
						}}
					/>
				</div>
			</Panel>
		</FormProvider>
	);
}


interface IGarmentSelectionSectionProps {
	garments: Garment[],
	onClickGarment: (garment: Garment) => void,
}

function GarmentSelectionSection({ garments, onClickGarment }: IGarmentSelectionSectionProps) {
	const { orderId } = useParams();
	invariant(orderId, "orderId must be defined");

	const ordersQuery = useQuery("get-organization-orders", () => DefaultService.getOrganizationOrdersApiOrdersGetOrganizationOrdersGet());

	ensureIsNotError(ordersQuery);

	const garmentsContainerStyle = css`
		padding-top: 10px;
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
	`;

	const accordionButtonStyle = css`
		cursor: pointer;
		display: flex;
		align-items: center;
		gap: 20px;
		padding: 12px;
		border-radius: 6px;

		transition: background-color 0.5s ${theme.timingFunctions.default};
	`;

	const accordionWrapperStyle = css`
		margin-top: 20px;
	`;

	const accordionButtonChevronStyle = css`
		transition: transform 0.5s ${theme.timingFunctions.default};
	`;

	const accordionButtonChevronIsOpenStyle = css`
		transform: rotate(180deg);
	`;

	const accordionButtonTextStyle = css`
		margin: 0;
	`;

	return (
		<motion.div layout layoutRoot>
			<h2>Garments added in this photoshoot</h2>
			<motion.div className={garmentsContainerStyle}>
				{garments.filter(x => x.createdForOrderId === orderId).map(x => <GarmentButton key={x.garmentId} garment={x} onClick={() => onClickGarment(x)} />)}
			</motion.div>
			{isNotLoadingOrIdle(ordersQuery) ? ordersQuery.data.filter(x => x.orderId !== orderId).sort((a, b) => {
				return new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime();
			}).filter(order => garments.some(garment => garment.createdForOrderId === order.orderId))
				.map(x => ({ order: x, garments: garments.filter(garment => garment.createdForOrderId === x.orderId) })).map(x => {
					return (
						<motion.div
							key={x.order.orderId}
							layout
							className={accordionWrapperStyle}
						>
							<Accordion
								accordionButton={isOpen => (
									<div className={accordionButtonStyle}>
										<FontAwesomeIcon icon={faChevronDown} className={classNames(accordionButtonChevronStyle, { [accordionButtonChevronIsOpenStyle]: isOpen })} />
										<h2 className={accordionButtonTextStyle}>
											{x.order.name}
										</h2>
									</div>
								)}
							>
								<div className={garmentsContainerStyle}>
									{x.garments.map(garment => <GarmentButton key={garment.garmentId} garment={garment} onClick={() => onClickGarment(garment)} />)}
								</div>
							</Accordion>
						</motion.div>
					);
				}) : <Loader />}
		</motion.div>
	);
}

interface IGarmentButtonProps {
	garment: Garment,
	onClick: () => void,
}

function GarmentButton({ garment, onClick }: IGarmentButtonProps) {
	const garmentImage = Object.values(garment.garmentImages)[0];
	invariant(garmentImage, "garmentImage must be defined");

	const garmentImageContainerStyle = css`
		width: 200px;
		height: calc(200px * 4/3);
		position: relative;
		cursor: pointer;
		border: 1px solid ${theme.semantic.border};
		border-radius: 4px;
		overflow: hidden;

		transition: border 0.5s ${theme.timingFunctions.default};

		&:hover {
			border: 1px solid ${theme.semantic.borderActive};
		}
	`;

	return (
		<Clickable
			key={garment.garmentId}
			onPress={onClick}
		>
			<div className={garmentImageContainerStyle}>
				<Image src={imageUrl(garmentImage.originalGarmentImageKey, "sm")} alt={garment.garmentId} fill showImageOptions />
			</div>
		</Clickable>
	);
}


interface IOrderRowGroupSectionProps {
	garments: Garment[],
	persons: Person[],
	categories: Categories,
	mainGarmentId: string,
	isLast: boolean,
	orderRowsFieldArray: UseFieldArrayReturn<ISaveOrderRowsSchema, "orderRows">,
}


function OrderRowGroupSection({
	garments, mainGarmentId, persons, categories, isLast, orderRowsFieldArray,
}: IOrderRowGroupSectionProps) {
	const { orderId } = useParams();
	invariant(orderId, "orderId must be defined");

	const mainGarment = garments.find(x => x.garmentId === mainGarmentId);
	invariant(mainGarment, "mainGarment must be defined");
	const mainGarmentImage = Object.values(mainGarment.garmentImages)[0];
	invariant(mainGarmentImage, "mainGarmentImage must be defined");

	const orderRowGroupSectionContainerStyle = css`
		padding: 4px 4px;
		overflow: hidden;
	`;

	const imgWrapperStyle = css`
		position: relative;
		width: 80px;
		height: 80px;
		border-radius: 100%;
		overflow: hidden;
		margin-bottom: auto;
		padding: 20px;
		margin-right: 100px;
		margin-top: 1rem;
	`;

	const imgStyle = css`
		object-fit: cover;
	`;

	// const buttonContainerStyle = css`
	// 	grid-column: span 2;
	// 	display: flex;
	// 	justify-content: center;
	// 	align-items: center;
	// 	display: sticky;
	// 	margin: 10px 0;
	// `;


	const mainStyle = css`
		display: grid;
		grid-template-columns: auto 1fr;
	`;

	const orderRowsContainerStyle = css`
		display: flex;
		flex-direction: column;
		gap: 30px;
	`;

	const tableSeparatorStyle = css`
		width: 95%;
	`;

	return (
		<motion.div layout className={orderRowGroupSectionContainerStyle}>
			<motion.div layout className={mainStyle}>
				<motion.div layout className={imgWrapperStyle}>
					<Image src={imageUrl(Object.values(mainGarment.garmentImages)[0]?.originalGarmentImageKey ?? "Image error", "xs")} className={imgStyle} alt={mainGarment.garmentId} fill showImageOptions />
				</motion.div>
				<motion.div className={orderRowsContainerStyle} layout>
					{orderRowsFieldArray.fields.map((x, i) => ({ ...x, fieldIndex: i })).filter(x => x.mainGarmentId === mainGarmentId).map(x => (
						<OrderRowForm
							key={x.id}
							fieldIndex={x.fieldIndex}
							categories={categories}
							garments={garments}
							persons={persons}
							onClickRemoveButton={() => orderRowsFieldArray.remove(x.fieldIndex)}
							onClickCopyButtonAppend={(orderRow: ISaveOrderRowSchema) => {
								orderRowsFieldArray.append(orderRow);
							}}
						/>
					))}
				</motion.div>
			</motion.div>
			{!isLast && <div className={tableSeparatorStyle}><Separator space={40} /></div>}
			{/* <motion.div layout className={buttonContainerStyle}>
				<Button
					variant="default"
					leftIconProps={{ icon: faPlus, marginRight: 12 }}
					onPress={() => {
					// Very strange design choice of having to create a fully valid form component on append
						orderRowsFieldArray.append({
							mainGarmentId: mainGarmentId,
							upperGarmentImageId: categories[mainGarment.categoryName]?.bodySection === "WholeBody" ||
								categories[mainGarment.categoryName]?.bodySection === "UpperBody" ? mainGarmentImage.garmentImageId : undefined,
							lowerGarmentImageId: categories[mainGarment.categoryName]?.bodySection === "LowerBody" ? mainGarmentImage.garmentImageId : undefined,
							outerGarmentImageId: undefined,
							personImageId: undefined,
							tuckUpper: false,
						} as unknown as ISaveOrderRowSchema);
					}}
				>
					Add row
				</Button>
			</motion.div> */}
		</motion.div>
	);
}


interface IOrderRowFormProps {
	fieldIndex: number,
	garments: Garment[],
	persons: Person[],
	categories: Categories,
	onClickRemoveButton: () => void,
	onClickCopyButtonAppend: (orderRow: ISaveOrderRowSchema) => void,
}

function OrderRowForm({
	garments, persons, categories, fieldIndex, onClickRemoveButton, onClickCopyButtonAppend,
}: IOrderRowFormProps) {
	const formContext = useFormContext<ISaveOrderRowsSchema>();
	const orderRow = useController({ control: formContext.control, name: `orderRows.${fieldIndex}` });
	const errors = orderRow.fieldState.error as unknown as { [key in keyof ISaveOrderRowSchema]: FieldError };

	// const orderRowWatch = useWatch({ control: formContext.control, name: `orderRows.${fieldIndex}` });
	// const orderRowSex = useMemo(() => inferOrderRowSex(orderRowWatch, garments, persons), [orderRowWatch, garments, persons]);
	const orderRowSex = useMemo(() => garments.find(x => x.garmentId === formContext.getValues().orderRows[fieldIndex]?.mainGarmentId)?.sex ?? "Unisex", [formContext, garments, fieldIndex]);

	const orderRowFormContainerStyle = css`
		background-color: ${theme.semantic.card};
		border-radius: 6px;
		/* box-shadow: ${theme.shadows.foregroundShadow}; */
		padding: 0.5rem;
		margin-right: 10px;
	`;

	const formContainerStyle = css`
		display: flex;
		gap: 40px;
		align-items: center;
	`;

	const otherOptionsContainerStyle = css`
		display: flex;
		flex-direction: column;
	`;

	const buttonContainerStyle = css`
		height: 100%;
		margin-bottom: auto;
		margin-left: auto;
		margin-right: -0.5rem;
		margin-top: -0.5rem;
		display: flex;
		flex-direction: column;
		gap: 2rem;
	`;

	const errorListStyle = css`
		background-color: ${theme.semantic.cardMuted};
		border-radius: 4px;
		padding: 16px 16px 16px 32px;
		margin-top: 20px;
		margin-bottom: 0;
		display: flex;
		flex-direction: column;
		gap: 10px;
		vertical-align: middle;
	`;

	const liStyle = css`
		vertical-align: middle;
	`;

	const outerGarmentImageIdErrorStyle = css`
		/* list-style-type: "🧥 "; */
	`;

	const upperGarmentImageIdErrorStyle = css`
		/* list-style-type: "👕 "; */
	`;

	const lowerGarmentImageIdErrorStyle = css`
		/* list-style-type: "👖 "; */
	`;

	const personImageIdErrorStyle = css`
		/* list-style-type: "🧍 "; */
	`;

	const tuckUpperErrorStyle = css`
		/* list-style-type: "⚠️ "; */
	`;

	return (
		<motion.div layout className={orderRowFormContainerStyle}>
			<motion.div layout className={formContainerStyle}>
				<GarmentImageField
					fieldIndex={fieldIndex}
					categories={categories}
					garments={garments}
					orderRowSex={orderRowSex}
					name="outerGarmentImageId"
					bodySections={["UpperBody", "WholeBody"]}
					icon={IconJacketMuted}
					isError={!!errors?.outerGarmentImageId}
				/>
				<GarmentImageField
					fieldIndex={fieldIndex}
					categories={categories}
					garments={garments}
					orderRowSex={orderRowSex}
					name="upperGarmentImageId"
					bodySections={["UpperBody", "WholeBody"]}
					icon={IconTshirtMuted}
					isError={!!errors?.upperGarmentImageId}
				/>
				<GarmentImageField
					fieldIndex={fieldIndex}
					categories={categories}
					garments={garments}
					orderRowSex={orderRowSex}
					name="lowerGarmentImageId"
					bodySections={["LowerBody"]}
					icon={IconPantsMuted}
					isError={!!errors?.lowerGarmentImageId}
				/>
				<PersonImageField
					persons={persons}
					orderRowSex={orderRowSex}
					fieldIndex={fieldIndex}
					isError={!!errors?.personImageId}
				/>
				<div className={otherOptionsContainerStyle}>
					{/* <FormComponents.Switch name={`orderRows.${fieldIndex}.tuckUpper`} label="Tuck 👕" /> */}
				</div>
				<div className={buttonContainerStyle}>
					<Button
						variant="danger"
						leftIconProps={{ icon: faTrash }}
						size="md"
						onPress={onClickRemoveButton}
					/>
					<Button
						variant="outline"
						leftIconProps={{ icon: faCopy }}
						size="md"
						onPress={() => {
							const copyOfRow = formContext.getValues().orderRows[fieldIndex];
							onClickCopyButtonAppend({ ...copyOfRow, orderRowId: null } as unknown as ISaveOrderRowSchema);
						}}
					/>
				</div>
			</motion.div>
			{Object.values(errors ?? {}).length > 0 && (
				<motion.ul
					layout
					className={errorListStyle}
					initial={{
						opacity: 0,
					}}
					exit={{
						opacity: 0,
					}}
					animate={{
						opacity: 1,
					}}
					transition={{
						duration: 0.3,
					}}
				>
					{errors?.outerGarmentImageId && (
						<motion.li layout className={classNames(liStyle, outerGarmentImageIdErrorStyle)}>
							{errors.outerGarmentImageId.message}
						</motion.li>
					)}
					{errors?.upperGarmentImageId && (
						<motion.li layout className={classNames(liStyle, upperGarmentImageIdErrorStyle)}>
							{errors.upperGarmentImageId.message}
						</motion.li>
					)}
					{errors?.lowerGarmentImageId && (
						<motion.li layout className={classNames(liStyle, lowerGarmentImageIdErrorStyle)}>
							{errors.lowerGarmentImageId.message}
						</motion.li>
					)}
					{errors?.personImageId && (
						<motion.li layout className={classNames(liStyle, personImageIdErrorStyle)}>
							{errors.personImageId.message}
						</motion.li>
					)}
					{errors?.tuckUpper && (
						<motion.li layout className={classNames(liStyle, tuckUpperErrorStyle)}>
							{errors.tuckUpper.message}
						</motion.li>
					)}
				</motion.ul>
			)}
		</motion.div>
	);
}

interface IImageFieldProps {
	fieldIndex: number,
	garments: Garment[],
	orderRowSex: Sex,
	categories: Categories,
	bodySections: BodySection[],
	icon: string,
	name: "outerGarmentImageId" | "upperGarmentImageId" | "lowerGarmentImageId",
	isError?: boolean,
}

function GarmentImageField({
	name, garments, orderRowSex, categories, bodySections, icon, fieldIndex, isError,
}: IImageFieldProps) {
	const { orderId } = useParams();
	invariant(orderId, "orderId must be defined");
	const ordersQuery = useQuery("get-organization-orders", () => DefaultService.getOrganizationOrdersApiOrdersGetOrganizationOrdersGet());
	const garmentImages = useMemo(() => Object.fromEntries(garments.flatMap(x => Object.values(x.garmentImages)).map(x => ([x.garmentImageId, x]))), [garments]);
	const formContext = useFormContext<ISaveOrderRowsSchema>();
	const orderRow = useWatch({ control: formContext.control, name: `orderRows.${fieldIndex}` });
	const { outerGarmentImageId, upperGarmentImageId, lowerGarmentImageId, mainGarmentId } = orderRow;
	const imageId = orderRow[name];


	const mainGarment = garments.find(x => x.garmentId === mainGarmentId);
	invariant(mainGarment, "mainGarment must be defined");

	const mainGarmentBodySection = categories[mainGarment.categoryName]?.bodySection;

	const showOnlyMainGarmentImages = mainGarmentBodySection
		&& bodySections.includes(mainGarmentBodySection)
		&& !([outerGarmentImageId, upperGarmentImageId, lowerGarmentImageId].some(x => x && Object.values(mainGarment.garmentImages).map(x => x.garmentImageId).includes(x)));

	ensureIsNotError(ordersQuery);

	const onClickGarmentImage = (garmentImageId: string) => {
		formContext.setValue(`orderRows.${fieldIndex}.${name}`, garmentImageId);
		void formContext.trigger(`orderRows.${fieldIndex}`);
	};

	const garmentsContainerStyle = css`
		padding-top: 10px;
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		overflow: hidden;
	`;

	const accordionButtonStyle = css`
		cursor: pointer;
		display: flex;
		align-items: center;
		gap: 20px;
		padding: 12px;
		border-radius: 6px;

		transition: background-color 0.5s ${theme.timingFunctions.default};
	`;

	const accordionWrapperStyle = css`
		margin-top: 20px;
		overflow-x: hidden;
		overflow-y: hidden;
	`;

	const accordionButtonChevronStyle = css`
		transition: transform 0.5s ${theme.timingFunctions.default};
	`;

	const accordionButtonChevronIsOpenStyle = css`
		transform: rotate(180deg);
	`;

	const accordionButtonTextStyle = css`
		margin: 0;
	`;

	return (
		<Popover
			offset={8}
			buttonElement={(
				<div className={classNames(imageContainerStyle, { [imageContainerIndicateStyle]: isError })}>
					{imageId && (
						<div className={deleteButtonWrapperStyle}>
							<Button
								variant="danger"
								leftIconProps={{ icon: faTrash }}
								size="sm"
								onPress={() => {
									formContext.setValue(`orderRows.${fieldIndex}.${name}`, null);
									void formContext.trigger(`orderRows.${fieldIndex}`);
								}}
							/>
						</div>
					)}
					{imageId ? <Image className={imgStyle} src={imageUrl(garmentImages[imageId]?.originalGarmentImageKey ?? "Image error", "xs")} alt="" fill /> : <ButtonIcon IconComponent={icon} />}
				</div>
			)}
		>
			{popoverState => (
				<div className={popoverInnerStyle}>
					<div>
						<h3>Garments added in this photoshoot</h3>
						<div className={garmentsContainerStyle}>
							{garments.filter(x => {
								const bodySection = categories[x.categoryName]?.bodySection;
								return bodySection && bodySections.includes(bodySection) && x.createdForOrderId === orderId && compatibleSex(x.sex, orderRowSex);
							})
								.flatMap(x => Object.values(x.garmentImages)).map(x => (
									<GarmentImageButton
										key={x.garmentImageId}
										garmentImage={x}
										isDisabled={showOnlyMainGarmentImages && !Object.values(mainGarment.garmentImages).map(x => x.garmentImageId).includes(x.garmentImageId)}
										onClick={() => {
											onClickGarmentImage(x.garmentImageId);
											popoverState.close();
										}}
									/>
								))}
						</div>
						{isNotLoadingOrIdle(ordersQuery) ? ordersQuery.data.filter(x => x.orderId !== orderId).sort((a, b) => {
							return new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime();
						}).filter(order => garments.some(garment => garment.createdForOrderId === order.orderId))
							.map(x => ({
								order: x,
								garmentImages: garments.filter(garment => {
									const bodySection = categories[garment.categoryName]?.bodySection;
									return bodySection && bodySections.includes(bodySection) && garment.createdForOrderId === x.orderId;
								}).flatMap(x => Object.values(x.garmentImages)),
							})).map(x => {
								return (
									<div
										key={x.order.orderId}
										className={accordionWrapperStyle}
									>
										<Accordion
											accordionButton={isOpen => (
												<div className={accordionButtonStyle}>
													<FontAwesomeIcon icon={faChevronDown} className={classNames(accordionButtonChevronStyle, { [accordionButtonChevronIsOpenStyle]: isOpen })} />
													<h3 className={accordionButtonTextStyle}>
														{x.order.name}
													</h3>
												</div>
											)}
										>
											<div
												className={garmentsContainerStyle}
											>
												{x.garmentImages.map(garmentImage => (
													<GarmentImageButton
														key={garmentImage.garmentImageId}
														garmentImage={garmentImage}
														isDisabled={showOnlyMainGarmentImages && !Object.values(mainGarment.garmentImages)
															.map(x => x.garmentImageId).includes(garmentImage.garmentImageId)}
														onClick={() => {
															onClickGarmentImage(garmentImage.garmentImageId);
															popoverState.close();
														}}
													/>
												))}
											</div>
										</Accordion>
									</div>
								);
							}) : <Loader />}
					</div>
				</div>
			)}
		</Popover>
	);
}

interface IGarmentImageButtonProps {
	garmentImage: GarmentImage,
	onClick: () => void,
	isDisabled?: boolean,
}

function GarmentImageButton({ garmentImage, onClick, isDisabled }: IGarmentImageButtonProps) {
	return (
		<motion.div
			initial={{ width: 0, opacity: 0 }}
			animate={{ width: "auto", opacity: 1 }}
			exit={{ width: 0, opacity: 0 }}
		>
			<Clickable
				key={garmentImage.garmentImageId}
				isDisabled={isDisabled}
				onPress={onClick}
			>
				<div
					className={classNames(
						imageOptionContainerStyle,
						{ [imageOptionContainerIsDisabledStyle]: isDisabled }
					)}
				>
					<Image className={imgStyle} src={imageUrl(garmentImage.originalGarmentImageKey)} alt="" fill showImageOptions />
				</div>
			</Clickable>
		</motion.div>
	);
}

interface IPersonImageFieldProps {
	persons: Person[],
	orderRowSex: Sex,
	fieldIndex: number,
	isError?: boolean,
}

function PersonImageField({ persons, orderRowSex, fieldIndex, isError }: IPersonImageFieldProps) {
	const formContext = useFormContext<ISaveOrderRowsSchema>();
	const personImageIdField = useController({ control: formContext.control, name: `orderRows.${fieldIndex}.personImageId` });

	const personImages = useMemo(() => Object.fromEntries(persons.filter(x => compatibleSex(x.sex, orderRowSex)).flatMap(x => x.personImages)
		.map(x => ([x.personImageId, x]))), [persons, orderRowSex]);

	return (
		<Popover
			offset={8}
			buttonElement={(
				<div className={classNames(imageContainerStyle, { [imageContainerIndicateStyle]: isError })}>
					{personImageIdField.field.value && personImages[personImageIdField.field.value] && personImages[personImageIdField.field.value]?.clothedPersonImageKey ? <Image className={imgStyle} src={imageUrl(personImages[personImageIdField.field.value]?.clothedPersonImageKey ?? "Image error", "xs")} alt="" fill /> : <ButtonIcon IconComponent={IconPersonMuted} />}
				</div>
			)}
		>
			{popoverState => (
				<div className={popoverInnerStyle}>
					{Object.values(personImages).map(x => (
						<Clickable
							key={x.personImageId}
							isDisabled={formContext.formState.isSubmitting}
							onPress={() => {
								personImageIdField.field.onChange(x.personImageId);
								void formContext.trigger(`orderRows.${fieldIndex}`);
								popoverState.close();
							}}
						>
							<div className={imageOptionContainerStyle}>
								{x.clothedPersonImageKey ? <Image className={imgStyle} src={imageUrl(x.clothedPersonImageKey, "sm")} alt="" fill showImageOptions /> : <ButtonIcon IconComponent={IconPersonMuted} />}
							</div>
						</Clickable>
					))}
				</div>
			)}
		</Popover>
	);
}

const deleteButtonWrapperStyle = css`
	z-index: 1;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;

	opacity: 0;
	transition: opacity 0.5s ${theme.timingFunctions.default};

	&:hover, &:focus-within {
		opacity: 1;
	}
`;

const imageOptionContainerStyle = css`
	width: 100px;
	height: calc(100px * 4/3);
	position: relative;
	cursor: pointer;
	border: 1px solid ${theme.semantic.border};
	transition: border 0.5s ${theme.timingFunctions.default};
	border-radius: 4px;

	&:hover {
		border: 1px solid ${theme.semantic.borderActive};
	}
`;

const imageOptionContainerIsDisabledStyle = css`
	filter: grayscale(100%);
	opacity: 0.5;
	cursor: not-allowed;
`;

const popoverInnerStyle = css`
	border-radius: 4px;
	background-color: ${theme.semantic.card};
	padding: 20px;
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	overflow-x: hidden;
	overflow-y: auto;
`;

const imageContainerStyle = css`
	user-select: none;
	width: 80px;
	height: calc(80px * 4/3);
	border: 1px solid ${theme.semantic.border};
	padding: 1px;
	transition: border-color 0.5s ${theme.timingFunctions.default};
	border-radius: 4px;
	position: relative;
	overflow: hidden;
	cursor: pointer;

	&:hover {
		border: 1px solid ${theme.semantic.borderActive};
		background-color: ${theme.semantic.mutedHover};
	}
`;

const imageContainerIndicateStyle = css`
	border: 2px solid ${theme.palette.teriary};

	&:hover {
		border: 2px solid ${theme.palette.teriary};
	}
`;

const imgStyle = css`
	object-fit: contain;
	transition: transform 0.5s ${theme.timingFunctions.default};
`;

// function inferOrderRowSex(orderRow: ISaveOrderRowSchema, garments: Garment[], persons: Person[]) {
// 	const mainGarmentSex = garments.find(x => x.garmentId === orderRow.mainGarmentId)?.sex ?? "Unisex";
// 	const outerGarmentSex = garments.find(x => Object.keys(x.garmentImages).includes(orderRow.outerGarmentImageId ?? ""))?.sex ?? "Unisex";
// 	const upperGarmentSex = garments.find(x => Object.keys(x.garmentImages).includes(orderRow.upperGarmentImageId))?.sex ?? "Unisex";
// 	const lowerGarmentSex = garments.find(x => Object.keys(x.garmentImages).includes(orderRow.lowerGarmentImageId ?? ""))?.sex ?? "Unisex";
// 	const personSex = persons.find(x => Object.keys(x.personImages).includes(orderRow.personImageId ?? ""))?.sex ?? "Unisex";

// 	return mainGarmentSex !== "Unisex" ? mainGarmentSex
// 		: outerGarmentSex !== "Unisex" ? outerGarmentSex
// 		: upperGarmentSex !== "Unisex" ? upperGarmentSex
// 		: lowerGarmentSex !== "Unisex" ? lowerGarmentSex
// 		: personSex !== "Unisex" ? personSex : "Unisex";
// }

function compatibleSex(a: Sex, b: Sex) {
	return a === "Unisex" || b === "Unisex" || a === b;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function ButtonIcon({ IconComponent }: { IconComponent: any }) {
	// const emojiStyle = css`
	// 	height: 100%;
	// 	display: flex;
	// 	justify-content: center;
	// 	align-items: center;
	// 	vertical-align: middle;
	// 	text-align: center;
	// 	font-size: 48px;
	// 	filter: grayscale(100%);
	// `;

	return (
		<IconComponent width="100%" height="100%" />
	);
}
