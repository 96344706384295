import { useQuery } from "react-query";

import { IUseSelectOptions } from "components/Select";
import { DefaultService, Sex } from "httpClient";


export function generateGetSexesQueryOptions() {
	return {
		queryKey: ["get-sexes"],
		queryFn: () => DefaultService.getSexesApiConstantsGetSexesGet(),
	};
}


export const useSexOptions = (): IUseSelectOptions<Sex> => {
	const sexesQuery = useQuery(generateGetSexesQueryOptions());

	return {
		disabled: sexesQuery.isLoading || sexesQuery.isIdle,
		isError: sexesQuery.isError,
		options: (sexesQuery.data ?? []).map(x => ({ key: x, text: x, data: x })),
	};
};
