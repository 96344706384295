import { css } from "@linaria/core";
import classNames from "classnames";

import { theme } from "theme";


interface ISeparatorProps {
	vertical?: boolean,
	space?: number,
}


export function Separator({ vertical = false, space = 20 }: ISeparatorProps) {
	if (space < 1)
		throw Error("space must be more than 0");

	const separatorStyle = css`
		position: relative;

		&::before {
			position: absolute;
			content: "";
			display: block;
			background-color: ${theme.semantic.border};
		}
	`;

	const horizontalSeparatorStyle = css`
		&::before {
			inset: 50% 0px 0px 0px;
			height: 1px;
		}
	`;

	const verticalSeparatorStyle = css`
		&::before {
			inset: 0px 50% 0px 0px;
			width: 1px;
		}
	`;

	return <div className={classNames(separatorStyle, vertical ? verticalSeparatorStyle : horizontalSeparatorStyle)} style={vertical ? { minWidth: space } : { minHeight: space }} />;
}
