import { useQuery } from "react-query";

import { IUseSelectOptions } from "components/Select";
import { DefaultService, ImageOrientation } from "httpClient";


export function createGetImageOrientationsQuery() {
	return {
		queryKey: ["get-image-orientations"],
		queryFn: () => DefaultService.getImageOrientationsApiConstantsGetImageOrientationsGet(),
	};
}


export const useImageOrientationOptions = (): IUseSelectOptions<ImageOrientation> => {
	const imageOrientationsQuery = useQuery(createGetImageOrientationsQuery());

	return {
		disabled: imageOrientationsQuery.isLoading || imageOrientationsQuery.isIdle,
		isError: imageOrientationsQuery.isError,
		options: (imageOrientationsQuery.data ?? []).map(x => ({ key: x, text: x, data: x })),
	};
};
